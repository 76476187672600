import React, { useMemo } from 'react';
import { DollarSign } from 'lucide-react';
import type { Agent } from '../../types';
import { getCommissionTypes } from '../../utils/commission';

interface FinancialSectionProps {
  formData: {
    propertyPrice: number;
    developerCommissionPercentage: number;
    developerCommission: number;
    commissionType: string;
    referrerId?: string;
    manualCommissions?: {
      agent: number;
      agency: number;
      additionalAgents?: Array<{
        agentId: string;
        amount: number;
      }>;
    };
  };
  agents: Agent[];
  onChange: (data: Partial<FinancialSectionProps['formData']>) => void;
}

export default function FinancialSection({ formData, agents, onChange }: FinancialSectionProps) {
  const handlePriceChange = (price: number) => {
    const commission = (price * formData.developerCommissionPercentage) / 100;
    onChange({
      propertyPrice: price,
      developerCommission: commission
    });
  };

  const handlePercentageChange = (percentage: number) => {
    const commission = (formData.propertyPrice * percentage) / 100;
    onChange({
      developerCommissionPercentage: percentage,
      developerCommission: commission
    });
  };

  const commissionTypes = getCommissionTypes();

  // Filter out the current agent from referrer options
  const referrerOptions = useMemo(() => {
    return agents.filter(agent => !formData.agentId || agent.id !== formData.agentId);
  }, [agents, formData.agentId]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'AED',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Property Price */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Prix du bien
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="number"
              required
              min="0"
              value={formData.propertyPrice || ''}
              onChange={(e) => handlePriceChange(Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Prix du bien"
            />
          </div>
        </div>

        {/* Developer Commission Percentage */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Commission promoteur (%)
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="number"
            required
            min="0"
            max="100"
            value={formData.developerCommissionPercentage || ''}
            onChange={(e) => handlePercentageChange(Number(e.target.value))}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Pourcentage"
          />
        </div>

        {/* Commission Total */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Commission totale
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              value={formatCurrency(formData.developerCommission)}
              readOnly
              className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg bg-gray-50 text-gray-700"
            />
          </div>
        </div>

        {/* Primary Agent Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Agent principal
            <span className="text-red-500 ml-1">*</span>
          </label>
          <select
            required
            value={formData.agentId}
            onChange={(e) => onChange({ agentId: e.target.value })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
          >
            <option value="">Sélectionner un agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
          </select>
        </div>

        {/* Commission Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Type de commission
            <span className="text-red-500 ml-1">*</span>
          </label>
          <select
            required
            value={formData.commissionType}
            onChange={(e) => {
              const newType = e.target.value;
              onChange({ 
                commissionType: newType,
                referrerId: ['referral', 'direct_lead_referral', 'shared_three_way'].includes(newType) 
                  ? formData.referrerId 
                  : undefined,
                manualCommissions: newType === 'manual' ? { agent: 0, agency: 0 } : undefined
              });
            }}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
          >
            <option value="">Sélectionner un type</option>
            {commissionTypes.map(type => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
        </div>

        {/* Referrer Selection */}
        {['referral', 'direct_lead_referral', 'shared_three_way'].includes(formData.commissionType) && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Agent secondaire
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              required
              value={formData.referrerId || ''}
              onChange={(e) => onChange({ referrerId: e.target.value })}
              className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
            >
              <option value="">Sélectionner un agent</option>
              {referrerOptions.map(agent => (
                <option key={agent.id} value={agent.id}>
                  {agent.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Manual Commission Fields */}
        {formData.commissionType === 'manual' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Commission vendeur
                <span className="text-red-500 ml-1">*</span>
              </label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="number"
                  required
                  min="0"
                  value={formData.manualCommissions?.agent || ''}
                  onChange={(e) => onChange({
                    manualCommissions: {
                      ...formData.manualCommissions,
                      agent: Number(e.target.value)
                    }
                  })}
                  className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Commission vendeur"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Commission agence
                <span className="text-red-500 ml-1">*</span>
              </label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="number"
                  required
                  min="0"
                  value={formData.manualCommissions?.agency || ''}
                  onChange={(e) => onChange({
                    manualCommissions: {
                      ...formData.manualCommissions,
                      agency: Number(e.target.value)
                    }
                  })}
                  className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Commission agence"
                />
              </div>
            </div>

            {/* Additional Agents for Manual Commission */}
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Agents supplémentaires
              </label>
              <div className="space-y-4">
                {formData.manualCommissions?.additionalAgents?.map((agent, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-1">
                      <select
                        value={agent.agentId}
                        onChange={(e) => {
                          const newAgents = [...(formData.manualCommissions?.additionalAgents || [])];
                          newAgents[index].agentId = e.target.value;
                          onChange({
                            manualCommissions: {
                              ...formData.manualCommissions,
                              additionalAgents: newAgents
                            }
                          });
                        }}
                        className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      >
                        <option value="">Sélectionner un agent</option>
                        {agents.filter(a => a.id !== formData.agentId).map(agent => (
                          <option key={agent.id} value={agent.id}>{agent.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1">
                      <div className="relative">
                        <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                        <input
                          type="number"
                          value={agent.amount}
                          onChange={(e) => {
                            const newAgents = [...(formData.manualCommissions?.additionalAgents || [])];
                            newAgents[index].amount = Number(e.target.value);
                            onChange({
                              manualCommissions: {
                                ...formData.manualCommissions,
                                additionalAgents: newAgents
                              }
                            });
                          }}
                          className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Commission"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        const newAgents = formData.manualCommissions?.additionalAgents?.filter((_, i) => i !== index);
                        onChange({
                          manualCommissions: {
                            ...formData.manualCommissions,
                            additionalAgents: newAgents
                          }
                        });
                      }}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                    >
                      ×
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    const newAgents = [...(formData.manualCommissions?.additionalAgents || [])];
                    newAgents.push({ agentId: '', amount: 0 });
                    onChange({
                      manualCommissions: {
                        ...formData.manualCommissions,
                        additionalAgents: newAgents
                      }
                    });
                  }}
                  className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
                >
                  + Ajouter un agent
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}