import React from 'react';
import { User, Mail, Phone, MapPin, CreditCard, Clock, Check, Calendar, DollarSign } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatDateDisplay } from '../../utils/date';

interface ClientInfoCardProps {
  client: {
    name?: string;
    phone?: string;
    email?: string;
    payments?: {
      eoi?: {
        status: boolean;
        date?: string;
      };
      downPayment?: {
        status: boolean;
        date?: string;
      };
      spa?: {
        status: boolean;
        date?: string;
      };
    };
  };
  leadSource: string;
}

export default function ClientInfoCard({ client, leadSource }: ClientInfoCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute bottom-0 left-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <User className="w-6 h-6 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">Informations Client</h3>
              <p className="text-gray-400 mt-1">Détails et statut des paiements</p>
            </div>
          </div>
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Contact Info */}
          <div className="space-y-6">
            {/* Client Name */}
            <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <div className="flex items-center gap-2 mb-3">
                <User className="w-5 h-5 text-blue-400" />
                <span className="text-gray-300">Nom du client</span>
              </div>
              <p className="text-xl font-semibold text-white">{client.name || 'Non renseigné'}</p>
            </div>

            {/* Contact Info */}
            {client.email && (
              <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
                <div className="flex items-center gap-2 mb-3">
                  <Mail className="w-5 h-5 text-purple-400" />
                  <span className="text-gray-300">Email</span>
                </div>
                <a 
                  href={`mailto:${client.email}`} 
                  className="text-xl font-semibold text-white hover:text-blue-400 transition-colors"
                >
                  {client.email}
                </a>
              </div>
            )}

            {client.phone && (
              <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
                <div className="flex items-center gap-2 mb-3">
                  <Phone className="w-5 h-5 text-green-400" />
                  <span className="text-gray-300">Téléphone</span>
                </div>
                <a 
                  href={`tel:${client.phone}`} 
                  className="text-xl font-semibold text-white hover:text-green-400 transition-colors"
                >
                  {client.phone}
                </a>
              </div>
            )}
          </div>

          {/* Right Column - Payment Timeline */}
          <div>
            <div className="p-6 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <div className="flex items-center gap-3 mb-6">
                <DollarSign className="w-5 h-5 text-yellow-400" />
                <h4 className="font-medium text-white">Étapes de paiement</h4>
              </div>

              <div className="relative">
                {/* Vertical Timeline Line */}
                <div className="absolute left-6 top-8 bottom-8 w-0.5 bg-white/10" />

                {/* EOI Payment */}
                <TimelineItem
                  icon={CreditCard}
                  label="Expression of Interest"
                  status={client.payments?.eoi?.status || false}
                  date={client.payments?.eoi?.date}
                />

                {/* Down Payment */}
                <TimelineItem
                  icon={CreditCard}
                  label="Down Payment"
                  status={client.payments?.downPayment?.status || false}
                  date={client.payments?.downPayment?.date}
                />

                {/* SPA */}
                <TimelineItem
                  icon={Check}
                  label="SPA"
                  status={client.payments?.spa?.status || false}
                  date={client.payments?.spa?.date}
                  isLast
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

interface TimelineItemProps {
  icon: React.ElementType;
  label: string;
  status: boolean;
  date?: string;
  isLast?: boolean;
}

function TimelineItem({ icon: Icon, label, status, date, isLast }: TimelineItemProps) {
  return (
    <div className={`relative pl-14 ${!isLast ? 'pb-8' : ''}`}>
      {/* Icon Circle */}
      <div className={`absolute left-0 w-12 h-12 rounded-full border-2 flex items-center justify-center ${
        status 
          ? 'bg-green-500/20 border-green-500 text-green-400' 
          : 'bg-amber-500/20 border-amber-500 text-amber-400'
      }`}>
        <Icon className="w-5 h-5" />
      </div>

      {/* Content */}
      <div className="flex items-center justify-between">
        <div>
          <h4 className="text-white font-medium">{label}</h4>
          {date && (
            <div className="flex items-center gap-1 mt-1">
              <Calendar className="w-3 h-3 text-gray-500" />
              <p className="text-xs text-gray-400">
                {formatDateDisplay(date)}
              </p>
            </div>
          )}
        </div>

        <div className="flex items-center gap-4">
          <div className={`flex items-center gap-2 px-3 py-1 rounded-lg text-sm font-medium ${
            status
              ? 'bg-green-500/20 text-green-400'
              : 'bg-amber-500/20 text-amber-400'
          }`}>
            {status ? (
              <>
                <Check className="w-4 h-4" />
                <span>Payé</span>
              </>
            ) : (
              <>
                <Clock className="w-4 h-4" />
                <span>En attente</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}