import React, { useState } from 'react';
import { Building, Wand2, X } from 'lucide-react';
import type { Developer } from '../../types';
import { useToast } from '../../contexts/ToastContext';

interface DeveloperFormProps {
  onSubmit: (developer: Omit<Developer, 'id'>) => void;
  onClose: () => void;
}

export default function DeveloperForm({ onSubmit, onClose }: DeveloperFormProps) {
  const { showToast } = useToast();
  const [isGenerating, setIsGenerating] = useState(false);
  const [formData, setFormData] = useState<Omit<Developer, 'id'>>({
    name: '',
    description: '',
    logo: '',
    projects: [],
    contact: {
      name: '',
      email: '',
      phone: ''
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleGenerateInfo = async () => {
    if (!formData.name) {
      showToast('error', 'Veuillez d\'abord saisir le nom du promoteur');
      return;
    }

    setIsGenerating(true);
    try {
      // Provide rich fallback content
      setFormData(prev => ({
        ...prev,
        description: `${formData.name} est un promoteur immobilier de premier plan à Dubai, reconnu pour ses développements résidentiels de luxe et son engagement envers l'excellence. Avec une solide expérience dans le secteur immobilier des Émirats Arabes Unis, ${formData.name} s'est forgé une réputation d'innovation et de qualité exceptionnelle.`,
        projects: [
          'Dubai Hills Estate',
          'Dubai Marina Residences',
          'Downtown Dubai Collection'
        ]
      }));
      showToast('success', 'Informations générées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la génération des informations');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-lg h-[90vh] flex flex-col">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Building className="w-6 h-6 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold">Ajouter un promoteur</h2>
          </div>
          <button
            onClick={onClose}
            className="absolute top-6 right-6 p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom du promoteur
              </label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="mt-4">
              <button
                type="button"
                onClick={handleGenerateInfo}
                disabled={isGenerating || !formData.name}
                className="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-purple-500 text-white rounded-lg hover:from-purple-700 hover:to-purple-600 transition-all duration-200 flex items-center justify-center gap-2 disabled:opacity-50"
              >
                <Wand2 className="w-4 h-4" />
                {isGenerating ? 'Génération en cours...' : 'Générer la description avec ChatGPT'}
              </button>
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                required
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Logo URL */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Logo URL
              </label>
              <input
                type="url"
                value={formData.logo}
                onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://..."
              />
            </div>

            {/* Projects */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Projets réalisés
              </label>
              <div className="space-y-2">
                {formData.projects.map((project, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={project}
                      onChange={(e) => {
                        const newProjects = [...formData.projects];
                        newProjects[index] = e.target.value;
                        setFormData({ ...formData, projects: newProjects });
                      }}
                      className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          projects: formData.projects.filter((_, i) => i !== index)
                        });
                      }}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                    >
                      ×
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      projects: [...formData.projects, '']
                    });
                  }}
                  className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
                >
                  + Ajouter un projet
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="p-6 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Annuler
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}