import React, { useState } from 'react';
import { X, User, Mail, Phone, Save, Building, DollarSign, MessageSquare, Calendar, Link, AlertCircle } from 'lucide-react';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { formatDateDisplay } from '../utils/date';
import { useToast } from '../contexts/ToastContext';
import { useAgents } from '../hooks/useAgents';
import Avatar from './ui/Avatar';

interface LeadEditModalProps {
  lead: {
    id: string;
    name: string;
    email?: string;
    phone?: string;
    project?: string;
    budget?: number;
    agentId?: string;
    status: 'new' | 'contacted' | 'meeting' | 'negotiation' | 'won' | 'lost';
    notes?: string;
    lastContactDate?: string;
  };
  onClose: () => void;
}

const STATUSES = {
  new: { label: 'Nouveau', color: 'blue' },
  contacted: { label: 'Contacté', color: 'purple' },
  meeting: { label: 'RDV', color: 'indigo' },
  negotiation: { label: 'Négociation', color: 'amber' },
  won: { label: 'Gagné', color: 'green' },
  lost: { label: 'Perdu', color: 'red' }
} as const;

export default function LeadEditModal({ lead, onClose }: LeadEditModalProps) {
  const { showToast } = useToast();
  const { agents } = useAgents();
  const [formData, setFormData] = useState({
    name: lead.name,
    email: lead.email || '',
    phone: lead.phone || '',
    project: lead.project || '',
    budget: lead.budget || 0,
    agentId: lead.agentId || '',
    status: lead.status,
    notes: lead.notes || '',
    lastContactDate: lead.lastContactDate || new Date().toISOString(),
    source: lead.source || '',
    priority: lead.priority || 'medium'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const leadRef = doc(db, 'leads', lead.id);
      await updateDoc(leadRef, {
        ...formData,
        updatedAt: serverTimestamp()
      });

      // If an agent is assigned, send notifications
      if (formData.agentId) {
        const agent = agents.find(a => a.id === formData.agentId);
        if (agent) {
          // Open WhatsApp with lead details
          const message = `👋 Bonjour ${agent.name},\n\n` +
            `Un lead vous a été assigné :\n\n` +
            `👤 Nom : ${formData.name}\n` +
            `📞 Téléphone : ${formData.phone}\n` +
            `📧 Email : ${formData.email}\n` +
            `🏢 Projet : ${formData.project}\n` +
            `💰 Budget : ${formData.budget} AED\n\n` +
            `Lien : ${window.location.origin}/pipeline`;

          if (agent.phone) {
            window.open(`https://wa.me/${agent.phone.replace(/[^0-9]/g, '')}?text=${encodeURIComponent(message)}`, '_blank');
          }
        }
      }

      showToast('success', 'Lead mis à jour avec succès');
      onClose();
    } catch (error) {
      console.error('Error updating lead:', error);
      showToast('error', 'Erreur lors de la mise à jour du lead');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-xl font-semibold">Modifier le lead</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Status Banner */}
        <div className={`mb-6 p-4 rounded-xl bg-${STATUSES[formData.status].color}-50 border border-${STATUSES[formData.status].color}-200`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className={`p-2 bg-${STATUSES[formData.status].color}-100 rounded-lg`}>
                <AlertCircle className={`w-5 h-5 text-${STATUSES[formData.status].color}-600`} />
              </div>
              <div>
                <p className={`font-medium text-${STATUSES[formData.status].color}-900`}>
                  {STATUSES[formData.status].label}
                </p>
                <p className={`text-sm text-${STATUSES[formData.status].color}-600`}>
                  Dernière modification : {formatDateDisplay(lead.lastContactDate || lead.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Contact Info */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Téléphone
              </label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Projet
              </label>
              <div className="relative">
                <Building className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.project}
                  onChange={(e) => setFormData({ ...formData, project: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Nom du projet"
                />
              </div>
            </div>
          </div>

          {/* Source & Priority */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Source
              </label>
              <div className="relative">
                <Link className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.source}
                  onChange={(e) => setFormData({ ...formData, source: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ex: Site web, Instagram..."
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Priorité
              </label>
              <select
                value={formData.priority}
                onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="low">Basse</option>
                <option value="medium">Moyenne</option>
                <option value="high">Haute</option>
              </select>
            </div>
          </div>

          {/* Budget & Status */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Budget
              </label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="number"
                  value={formData.budget}
                  onChange={(e) => setFormData({ ...formData, budget: Number(e.target.value) })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Budget en AED"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Statut
              </label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as typeof formData.status })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                {Object.entries(STATUSES).map(([value, { label }]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Agent Assignment */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Agent assigné
            </label>
            <select
              value={formData.agentId}
              onChange={(e) => setFormData({ ...formData, agentId: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Sélectionner un agent</option>
              {agents.map(agent => (
                <option key={agent.id} value={agent.id}>{agent.name}</option>
              ))}
            </select>
          </div>

          {/* Notes */}
          <div className="bg-gray-50 p-4 rounded-xl">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <div className="relative">
              <MessageSquare className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                rows={4}
                className="w-full pl-10 pr-4 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ajouter des notes sur ce lead..."
              />
            </div>
            <div className="mt-2 text-sm text-gray-500">
              Dernière note : {formatDateDisplay(lead.lastContactDate || lead.createdAt)}
            </div>
          </div>

          {/* Preview of assigned agent */}
          {formData.agentId && (
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="flex items-center gap-3">
                <Avatar 
                  name={agents.find(a => a.id === formData.agentId)?.name || ''} 
                  size="sm" 
                />
                <div>
                  <p className="font-medium">
                    {agents.find(a => a.id === formData.agentId)?.name}
                  </p>
                  <p className="text-sm text-gray-600">
                    Une notification sera envoyée à l'agent
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              disabled={isSubmitting}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
            >
              <Save className="w-4 h-4" />
              {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}