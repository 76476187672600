import React, { useState, useEffect } from 'react';
import { useSettings } from '../hooks/useSettings';
import GoalsTab from '../components/settings/GoalsTab';
import GeneralTab from '../components/settings/GeneralTab';
import NotificationsTab from '../components/settings/NotificationsTab';
import PropertiesTab from '../components/settings/PropertiesTab';
import InstagramTab from '../components/settings/InstagramTab';
import PagesTab from '../components/settings/PagesTab';
import ApisTab from '../components/settings/ApisTab';
import DatabaseTab from '../components/settings/DatabaseTab';
import TemplatesTab from '../components/settings/TemplatesTab';
import { formatCurrency } from '../utils/format';
import { 
  Database, Shield, History, Save, Bell, Key, 
  Webhook, RefreshCw, Settings as SettingsIcon, 
  Download, HardDrive, Gauge, Mail, Send, Lock, ImageIcon, Target, Building, FileText, DollarSign, Gift, Trophy
} from 'lucide-react';
import { useAgents } from '../hooks/useAgents';
import { verifyCollections, getLoginLogs, getDatabaseStats } from '../services/firebaseService';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useLanguage } from '../contexts/LanguageContext';
import { sendTestEmail } from '../services/emailService';
import { resetAllAgentPasswords } from '../utils/resetPasswords';
import { useToast } from '../contexts/ToastContext';
import EmailTemplateEditor from '../components/EmailTemplateEditor';
import PdfTab from '../components/settings/PdfTab';

const EMAIL_TEMPLATES = {
  newSale: {
    name: 'Nouvelle vente',
    template: `
      <h2>Nouvelle vente enregistrée</h2>
      <p>Bonjour \${to_name},</p>
      <p>Une nouvelle vente a été enregistrée :</p>
      <ul>
        <li>Projet : \${project}</li>
        <li>Unité : \${unit}</li>
        <li>Prix : \${price}</li>
        <li>Date : \${date}</li>
      </ul>
      <p>Félicitations !</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      project: 'Nom du projet',
      unit: 'Numéro d\'unité',
      price: 'Prix de vente',
      date: 'Date de la vente'
    }
  },
  commissionReceived: {
    name: 'Commission reçue',
    template: `
      <h2>Commission reçue</h2>
      <p>Bonjour \${to_name},</p>
      <p>La commission pour votre vente a été reçue :</p>
      <ul>
        <li>Projet : \${project}</li>
        <li>Commission : \${commission}</li>
      </ul>
      <p>Le paiement sera traité dans les plus brefs délais.</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      project: 'Nom du projet',
      commission: 'Montant de la commission'
    }
  },
  newAgent: {
    name: 'Nouvel agent',
    template: `
      <h2>Bienvenue chez Palm Dubai Immo</h2>
      <p>Bonjour \${to_name},</p>
      <p>Voici vos identifiants de connexion :</p>
      <ul>
        <li>Email : \${email}</li>
        <li>Mot de passe : \${password}</li>
      </ul>
      <p>Connectez-vous sur : \${app_url}</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      email: 'Email de l\'agent',
      password: 'Mot de passe',
      app_url: 'URL de l\'application'
    }
  }
};

export default function Settings() {
  const { settings, updateSettings } = useSettings();
  const { agents } = useAgents();
  const { t } = useLanguage();
  const { showToast } = useToast();
  const [activeTab, setActiveTab] = useState<'general' | 'notifications' | 'database' | 'apis' | 'templates' | 'goals' | 'properties' | 'instagram' | 'pdf' | 'pages'>('general');
  const [isSaving, setIsSaving] = useState(false);
  const [isSendingTest, setIsSendingTest] = useState(false);
  const [isResettingPasswords, setIsResettingPasswords] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<string | null>(null);
  const [showAgentIds, setShowAgentIds] = useState(false);
  const [dbStats, setDbStats] = useState<{
    collections: Record<string, { count: number; size: number }>;
    totalSize: number;
    lastBackup?: string;
  } | null>(null);
  const [monthlyGoal, setMonthlyGoal] = useState({
    volumeTarget: settings?.monthlyGoal?.volumeTarget || 3500000,
    reward: settings?.monthlyGoal?.reward || {
      type: 'car_rental',
      description: '2 mois de location de voiture offerts 🚗'
    }
  });
  const [quarterlyGoals, setQuarterlyGoals] = useState<Record<string, QuarterlyGoal>>(
    settings?.quarterlyGoals || {}
  );

  const [formData, setFormData] = useState({
    name: settings?.name || 'PalmCRM',
    email: settings?.email || 'contact@palmdubaiimmo.com',
    notifications: settings?.notifications || {
      newSale: true,
      commissionReceived: true,
      agentCreated: true,
      loginAttempt: true
    },
    apis: {
      instagram: settings?.instagram || {
        accessToken: '',
        pageId: '',
        businessAccountId: '',
        autoPublish: false,
        includePrice: false,
        defaultHashtags: '#PalmDubai #Dubai #RealEstate'
      },
      emailjs: {
        serviceId: settings?.emailjs?.serviceId || '',
        templateId: settings?.emailjs?.templateId || '',
        publicKey: settings?.emailjs?.publicKey || '',
      },
      whatsapp: {
        apiKey: settings?.whatsapp?.apiKey || '',
        instanceId: settings?.whatsapp?.instanceId || '',
      }
    },
    templates: settings?.templates || EMAIL_TEMPLATES
  });

  useEffect(() => {
    if (settings) {
      setFormData({
        name: settings.name,
        email: settings.email,
        notifications: settings.notifications,
        apis: {
          emailjs: settings.emailjs || { serviceId: '', templateId: '', publicKey: '' },
          whatsapp: settings.whatsapp || { apiKey: '', instanceId: '' }
        },
        templates: settings.templates || EMAIL_TEMPLATES
      });
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    setSuccess(null);

    try {
      // Save quarterly goals with convention signed status
      const updatedSettings = {
        name: formData.name,
        email: formData.email,
        monthlyGoal: monthlyGoal,
        quarterlyGoals: quarterlyGoals, // Add this line
        notifications: formData.notifications,
        emailjs: formData.apis.emailjs,
        whatsapp: formData.apis.whatsapp,
        templates: formData.templates
      };

        quarterlyGoals: quarterlyGoals,
      await updateSettings(updatedSettings);
      
      showToast('success', 'Paramètres enregistrés avec succès');
      setSuccess('Paramètres enregistrés avec succès');
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Erreur lors de la sauvegarde des paramètres');
      showToast('error', 'Erreur lors de la sauvegarde');
    } finally {
      setIsSaving(false);
    }
  };

  const handleTestEmail = async () => {
    setIsSendingTest(true);
    try {
      await sendTestEmail(
        formData.apis.emailjs.serviceId,
        formData.apis.emailjs.templateId,
        formData.apis.emailjs.publicKey
      );
      showToast('success', 'Email de test envoyé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'envoi de l\'email de test');
    } finally {
      setIsSendingTest(false);
    }
  };

  const handleResetPasswords = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir réinitialiser tous les mots de passe des agents ?')) {
      return;
    }

    setIsResettingPasswords(true);
    setError(null);
    setSuccess(null);

    try {
      await resetAllAgentPasswords();
      showToast('success', 'Tous les mots de passe ont été réinitialisés avec succès');
      setSuccess('Les mots de passe ont été réinitialisés à : LovePalmImmo');
    } catch (error) {
      console.error('Error resetting passwords:', error);
      setError('Erreur lors de la réinitialisation des mots de passe');
      showToast('error', 'Erreur lors de la réinitialisation des mots de passe');
    } finally {
      setIsResettingPasswords(false);
    }
  };

  const handleVerifyDatabase = async () => {
    setIsVerifying(true);
    try {
      await verifyCollections();
      const stats = await getDatabaseStats();
      setDbStats(stats);
      showToast('success', 'Base de données vérifiée avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la vérification de la base de données');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleTemplateChange = (templateId: string, newContent: string) => {
    setFormData(prev => ({
      ...prev,
      templates: {
        ...prev.templates,
        [templateId]: {
          ...prev.templates[templateId],
          template: newContent
        }
      }
    }));
  };

  const tabs = [
    { id: 'general', label: 'Général', icon: SettingsIcon },
    { id: 'notifications', label: 'Notifications', icon: Bell },
    { id: 'properties', label: 'Biens', icon: Building },
    { id: 'goals', label: 'Objectifs', icon: Target },
    { id: 'templates', label: 'Templates', icon: FileText },
    { id: 'pages', label: 'Pages', icon: FileText },
    { id: 'instagram', label: 'Instagram', icon: ImageIcon },
    { id: 'pdf', label: 'PDF', icon: FileText },
    { id: 'apis', label: 'APIs', icon: Webhook },
    { id: 'database', label: 'Base de données', icon: Database }
  ] as const;

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <SettingsIcon className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Paramètres</h2>
          </div>
        </div>

        {error && (
          <div className="mx-6 mt-6 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        {success && (
          <div className="mx-6 mt-6 p-4 bg-green-50 text-green-600 rounded-lg">
            {success}
          </div>
        )}

        <div className="p-6">
          {/* Responsive Tab Navigation */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2 mb-6">
            {tabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveTab(id as typeof activeTab)}
                className={`flex items-center justify-center gap-2 px-4 py-3 rounded-lg text-sm font-medium transition-all ${
                  activeTab === id
                    ? 'bg-blue-50 text-blue-600 border border-blue-200'
                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                }`}
              >
                <Icon className="w-4 h-4" />
                <span>{label}</span>
              </button>
            ))}
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {activeTab === 'general' && (
              <GeneralTab
                formData={formData}
                setFormData={setFormData}
                handleResetPasswords={handleResetPasswords}
                isResettingPasswords={isResettingPasswords}
              />
            )}

            {activeTab === 'notifications' && (
              <NotificationsTab
                formData={formData}
                setFormData={setFormData}
              />
            )}

            {activeTab === 'properties' && (
              <PropertiesTab
                settings={settings}
                updateSettings={updateSettings}
                showToast={showToast}
              />
            )}

            {activeTab === 'goals' && (
              <GoalsTab
                monthlyGoal={monthlyGoal}
                setMonthlyGoal={setMonthlyGoal}
                quarterlyGoals={quarterlyGoals}
                setQuarterlyGoals={setQuarterlyGoals}
              />
            )}

            {activeTab === 'templates' && (
              <TemplatesTab
                formData={formData}
                handleTemplateChange={handleTemplateChange}
                editingTemplate={editingTemplate}
                setEditingTemplate={setEditingTemplate}
              />
            )}

            {activeTab === 'pages' && (
              <PagesTab
                settings={settings}
                updateSettings={updateSettings}
                showToast={showToast}
              />
            )}

            {activeTab === 'pdf' && (
              <PdfTab
                settings={settings}
                updateSettings={updateSettings}
                showToast={showToast}
              />
            )}

            {activeTab === 'instagram' && (
              <InstagramTab
                formData={formData}
                setFormData={setFormData}
              />
            )}

            {activeTab === 'apis' && (
              <ApisTab
                formData={formData}
                setFormData={setFormData}
                handleTestEmail={handleTestEmail}
                isSendingTest={isSendingTest}
              />
            )}

            {activeTab === 'database' && (
              <DatabaseTab
                handleVerifyDatabase={handleVerifyDatabase}
                isVerifying={isVerifying}
                dbStats={dbStats}
                showAgentIds={showAgentIds}
                setShowAgentIds={setShowAgentIds}
                agents={agents}
              />
            )}

            <div className="flex justify-end pt-6 border-t border-gray-200">
              <button
                type="submit"
                disabled={isSaving}
                className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
              >
                <Save className="w-4 h-4" />
                {isSaving ? 'Enregistrement...' : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}