import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { motion, AnimatePresence } from 'framer-motion';
import { Building, DollarSign, Shield, TrendingUp, MapPin, Phone, Mail, Globe, Home, Calendar, FileCheck, CreditCard, PhoneCall, AtSign, MapPinned, UserCheck, ChevronDown, ChevronUp } from 'lucide-react';
import { useProperties } from '../hooks/useProperties';
import { useAreas } from '../hooks/useAreas';
import { useDevelopers } from '../hooks/useDevelopers';
import { formatCurrency } from '../utils/format';

export default function OfferView() {
  const { id } = useParams<{ id: string }>();
  const { properties } = useProperties();
  const { areas } = useAreas();
  const { developers } = useDevelopers();
  const [offerData, setOfferData] = useState<{
    client: string;
    agent: string;
    properties: string[];
    language?: string;
    theme?: string;
  } | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        if (!id) return;
        
        const offerDoc = await getDoc(doc(db, 'offers', id));
        if (offerDoc.exists()) {
          const data = offerDoc.data();
          setOfferData({
            client: data.clientName,
            agent: data.agentName,
            properties: data.properties,
            language: data.language || 'fr',
            theme: data.theme || 'modern'
          });

          // Increment view count
          await updateDoc(doc(db, 'offers', id), {
            views: increment(1),
            lastViewed: serverTimestamp()
          });
        }
      } catch (error) {
        console.error('Error fetching offer:', error);
      }
    };

    fetchOffer();
  }, [id]);

  const selectedProperties = properties.filter(p => 
    offerData?.properties.includes(p.id)
  );

  if (!offerData) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="relative h-screen">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=2000&q=80)'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
        </div>

        {/* Content */}
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center text-white">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6"
          >
            Offre Exclusive pour<br />
            <span className="text-blue-400">{offerData.client}</span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl sm:text-2xl text-gray-200 mb-12 max-w-2xl"
          >
            Découvrez une sélection exclusive de biens immobiliers de luxe à Dubai, 
            spécialement choisis pour vous.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="flex flex-wrap gap-6"
          >
            <div className="flex items-center gap-3 px-6 py-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <DollarSign className="w-6 h-6 text-blue-400" />
              <div>
                <p className="text-sm text-gray-300">Rendement locatif</p>
                <p className="text-lg font-semibold">6-10% par an</p>
              </div>
            </div>

            <div className="flex items-center gap-3 px-6 py-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <Shield className="w-6 h-6 text-green-400" />
              <div>
                <p className="text-sm text-gray-300">Impôts</p>
                <p className="text-lg font-semibold">0% sur les revenus</p>
              </div>
            </div>

            <div className="flex items-center gap-3 px-6 py-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <TrendingUp className="w-6 h-6 text-purple-400" />
              <div>
                <p className="text-sm text-gray-300">Plus-value moyenne</p>
                <p className="text-lg font-semibold">30% en 3 ans</p>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
            className="absolute bottom-12 left-1/2 -translate-x-1/2"
          >
            <ChevronDown className="w-8 h-8 animate-bounce" />
          </motion.div>
        </div>
      </div>

      {/* Properties Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl sm:text-4xl font-bold text-center mb-16"
        >
          Biens Sélectionnés pour Vous
        </motion.h2>

        <div className="grid grid-cols-1 gap-12">
          {selectedProperties.map((property, index) => (
            <motion.div
              key={property.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-2xl shadow-xl overflow-hidden"
            >
              {/* Property Images */}
              <div className="relative aspect-[21/9]">
                {property.images?.[0] && (
                  <img
                    src={property.images[0]}
                    alt={property.name}
                    className="w-full h-full object-cover"
                  />
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-6 left-6 right-6">
                  <h3 className="text-3xl font-bold text-white mb-2">
                    {property.name}
                  </h3>
                  <div className="flex items-center gap-4 text-white/90">
                    <div className="flex items-center gap-2">
                      <Building className="w-5 h-5" />
                      <span>{property.developer}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MapPin className="w-5 h-5" />
                      <span>{property.area}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Property Content */}
              <div className="p-8">
                <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                  {property.description}
                </p>

                {/* Features Grid */}
                {property.features && (
                  <div className="mb-8">
                    <h4 className="text-xl font-semibold mb-4">Caractéristiques</h4>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                      {property.features.map((feature, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl"
                        >
                          <div className="p-2 bg-blue-100 rounded-lg">
                            <Home className="w-5 h-5 text-blue-600" />
                          </div>
                          <span className="text-gray-700">{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Payment Plan */}
                <div className="mb-8">
                  <button
                    onClick={() => setShowPaymentPlan(!showPaymentPlan)}
                    className="flex items-center justify-between w-full px-6 py-4 bg-blue-50 rounded-xl text-left"
                  >
                    <div className="flex items-center gap-3">
                      <Calendar className="w-5 h-5 text-blue-600" />
                      <span className="font-medium">Plan de paiement</span>
                    </div>
                    {showPaymentPlan ? (
                      <ChevronUp className="w-5 h-5 text-blue-600" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-blue-600" />
                    )}
                  </button>

                  <AnimatePresence>
                    {showPaymentPlan && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className="overflow-hidden"
                      >
                        <div className="pt-6 px-6">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                              <p className="text-sm text-gray-600 mb-1">Prix de départ</p>
                              <p className="text-2xl font-bold text-blue-900">
                                {formatCurrency(1500000)}
                              </p>
                            </div>
                            <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
                              <p className="text-sm text-gray-600 mb-1">Acompte</p>
                              <p className="text-2xl font-bold text-green-900">20%</p>
                            </div>
                          </div>

                          <div className="mt-6 space-y-4">
                            <div className="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                              <div>
                                <p className="font-medium">Réservation</p>
                                <p className="text-sm text-gray-500">À la signature</p>
                              </div>
                              <p className="font-medium">5%</p>
                            </div>
                            <div className="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                              <div>
                                <p className="font-medium">Premier versement</p>
                                <p className="text-sm text-gray-500">30 jours</p>
                              </div>
                              <p className="font-medium">15%</p>
                            </div>
                            <div className="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                              <div>
                                <p className="font-medium">Versements trimestriels</p>
                                <p className="text-sm text-gray-500">Pendant la construction</p>
                              </div>
                              <p className="font-medium">60%</p>
                            </div>
                            <div className="flex justify-between items-center p-4 bg-gray-50 rounded-xl">
                              <div>
                                <p className="font-medium">Versement final</p>
                                <p className="text-sm text-gray-500">À la livraison</p>
                              </div>
                              <p className="font-medium">20%</p>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                {/* Area Info */}
                {areas.filter(area => area.name === property.area).map(area => (
                  <div key={area.name} className="mb-8">
                    <h4 className="text-xl font-semibold mb-4">Le quartier</h4>
                    <p className="text-gray-600 mb-6 leading-relaxed">
                      {area.description}
                    </p>

                    <div className="grid grid-cols-2 gap-4 mb-6">
                      <div className="p-4 bg-gray-50 rounded-xl">
                        <h5 className="font-medium mb-2">Distances</h5>
                        <div className="space-y-2 text-sm text-gray-600">
                          <p>Dubai Mall: {area.distances.dubaiMall}</p>
                          <p>Dubai Marina: {area.distances.dubaiMarina}</p>
                          <p>DXB Airport: {area.distances.dxbAirport}</p>
                        </div>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-xl">
                        <h5 className="font-medium mb-2">Rendements locatifs</h5>
                        <div className="space-y-2 text-sm text-gray-600">
                          <p>Airbnb: {area.distances.airbnbYield}</p>
                          <p>Long terme: {area.distances.longTermYield}</p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h5 className="font-medium mb-2">Points d'intérêt</h5>
                      <div className="flex flex-wrap gap-2">
                        {area.pointsOfInterest.map((poi, index) => (
                          <span
                            key={index}
                            className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                          >
                            {poi}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Contact Section */}
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Prêt à Investir ?
            </h2>
            <p className="text-xl text-gray-300">
              Notre équipe d'experts est là pour vous accompagner
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <h3 className="text-2xl font-bold">
                Votre contact : {offerData.agent}
              </h3>
              <div className="space-y-4">
                <a
                  href="tel:+971501234567"
                  className="flex items-center gap-3 px-6 py-4 bg-white/10 backdrop-blur-sm rounded-xl hover:bg-white/20 transition-colors"
                >
                  <PhoneCall className="w-6 h-6" />
                  <span className="text-lg">+971 50 123 4567</span>
                </a>
                <a
                  href="mailto:contact@palmdubai.fr"
                  className="flex items-center gap-3 px-6 py-4 bg-white/10 backdrop-blur-sm rounded-xl hover:bg-white/20 transition-colors"
                >
                  <Mail className="w-6 h-6" />
                  <span className="text-lg">contact@palmdubai.fr</span>
                </a>
                <a
                  href="https://wa.me/971501234567"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 px-6 py-4 bg-[#25D366]/20 backdrop-blur-sm rounded-xl hover:bg-[#25D366]/30 transition-colors"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
                  </svg>
                  <span className="text-lg">WhatsApp</span>
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <h3 className="text-2xl font-bold">Palm Dubai Immo</h3>
              <p className="text-lg text-gray-300 leading-relaxed">
                Votre partenaire de confiance pour investir à Dubai depuis plus de 10 ans. 
                Notre équipe d'experts vous accompagne dans toutes les étapes de votre investissement.
              </p>
              <div className="flex items-center gap-4">
                <img 
                  src="https://palmdubai.fr/templates/Default/img/logo_black.png"
                  alt="Palm Dubai Logo"
                  className="h-12 invert"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}