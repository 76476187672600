import React from 'react';

interface NotificationsTabProps {
  formData: {
    notifications: {
      newSale: boolean;
      commissionReceived: boolean;
      agentCreated: boolean;
      loginAttempt: boolean;
    };
  };
  setFormData: (data: any) => void;
}

export default function NotificationsTab({ formData, setFormData }: NotificationsTabProps) {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {Object.entries(formData.notifications).map(([key, value]) => (
          <div key={key} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <span className="text-sm font-medium text-gray-700">
              {key === 'newSale' ? 'Nouvelle vente' :
               key === 'commissionReceived' ? 'Commission reçue' :
               key === 'agentCreated' ? 'Nouvel agent' :
               'Tentative de connexion'}
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={value}
                onChange={(e) => setFormData({
                  ...formData,
                  notifications: {
                    ...formData.notifications,
                    [key]: e.target.checked
                  }
                })}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}