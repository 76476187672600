import React from 'react';
import { motion } from 'framer-motion';
import { Building, MapPin } from 'lucide-react';
import type { Property } from '../../types';

interface PropertyCardProps {
  property: Property;
  onDelete: (id: string) => void;
}

export default function PropertyCard({ property, onDelete }: PropertyCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
    >
      <div className="p-6">
        <div className="flex items-center gap-4 mb-4">
          {property.images?.[0] && (
            <img
              src={property.images[0]}
              alt={property.name}
              className="w-24 h-24 object-cover rounded-lg"
            />
          )}
          <div>
            <h3 className="text-xl font-semibold">{property.name}</h3>
            <p className="text-sm text-gray-500">{property.shortDescription}</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Building className="w-4 h-4" />
            <span>{property.developer}</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <MapPin className="w-4 h-4" />
            <span>{property.area}</span>
          </div>
        </div>

        {property.features && property.features.length > 0 && (
          <div>
            <h4 className="font-medium mb-2">Caractéristiques</h4>
            <div className="flex flex-wrap gap-2">
              {property.features.map((feature, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="px-6 py-4 bg-gray-50 border-t border-gray-200/50">
        <div className="flex justify-end gap-2">
          <button
            onClick={() => onDelete(property.id)}
            className="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
          >
            Supprimer
          </button>
        </div>
      </div>
    </motion.div>
  );
}