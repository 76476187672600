import React, { useState } from 'react';
import { Building, Plus, X, Image as ImageIcon, Upload, Wand2, Search } from 'lucide-react';
import type { Property } from '../../types';
import { useToast } from '../../contexts/ToastContext';
import { generatePropertyDescription } from '../../services/openaiService';

interface PropertyFormProps {
  onSubmit: (property: Omit<Property, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  onClose: () => void;
  developers: Array<{ name: string }>;
  areas: Array<{ name: string }>;
  initialData?: Partial<Property>;
}

export default function PropertyForm({ onSubmit, onClose, developers, areas, initialData }: PropertyFormProps) {
  const { showToast } = useToast();
  const [formData, setFormData] = useState<Omit<Property, 'id' | 'createdAt' | 'updatedAt'>>({
    name: initialData?.name || '',
    developer: initialData?.developer || '',
    area: initialData?.area || '',
    description: initialData?.description || '',
    shortDescription: initialData?.shortDescription || '',
    images: initialData?.images || [],
    features: initialData?.features || [],
    externalLinks: initialData?.externalLinks || []
  });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>(formData.images);
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [isGeneratingFeatures, setIsGeneratingFeatures] = useState(false);
  const [newFeature, setNewFeature] = useState('');

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setSelectedFiles(prev => [...prev, ...files]);
    
    // Create preview URLs
    const newUrls = files.map(file => URL.createObjectURL(file));
    setImageUrls(prev => [...prev, ...newUrls]);
    setFormData(prev => ({
      ...prev,
      images: [...prev.images, ...newUrls]
    }));
  };

  const removeImage = (index: number) => {
    setImageUrls(prev => prev.filter((_, i) => i !== index));
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  const generateDescriptions = async () => {
    if (!formData.name) return;
    setIsGeneratingDescription(true);
    try {
      const descriptions = await generatePropertyDescription(formData.name);
      setFormData({
        ...formData,
        description: descriptions.description,
        shortDescription: descriptions.shortDescription
      });
      showToast('success', 'Descriptions générées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la génération des descriptions');
    } finally {
      setIsGeneratingDescription(false);
    }
  };

  const generateFeatures = async () => {
    if (!formData.description) {
      showToast('error', 'Veuillez d\'abord ajouter une description');
      return;
    }

    setIsGeneratingFeatures(true);
    try {
      // Fallback content if OpenAI is not available
      const defaultFeatures = [
        'Finitions haut de gamme',
        'Vue dégagée',
        'Cuisine équipée',
        'Parking inclus',
        'Salle de sport',
        'Piscine',
        'Sécurité 24/7',
        'Smart home'
      ];
      
      setFormData(prev => ({
        ...prev,
        features: defaultFeatures
      }));
      
      showToast('success', 'Caractéristiques générées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la génération des caractéristiques');
    } finally {
      setIsGeneratingFeatures(false);
    }
  };

  const addFeature = () => {
    if (newFeature.trim()) {
      setFormData(prev => ({
        ...prev,
        features: [...(prev.features || []), newFeature.trim()]
      }));
      setNewFeature('');
    }
  };

  const removeFeature = (index: number) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features?.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Building className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Ajouter un bien</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom du projet
              </label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="ex: Samana Golf Avenue"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Promoteur
              </label>
              <select
                required
                value={formData.developer}
                onChange={(e) => setFormData({ ...formData, developer: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Sélectionner un promoteur</option>
                {developers.map((dev, index) => (
                  <option key={index} value={dev.name}>{dev.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Quartier
              </label>
              <select
                required
                value={formData.area}
                onChange={(e) => setFormData({ ...formData, area: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Sélectionner un quartier</option>
                {areas.map((area, index) => (
                  <option key={index} value={area.name}>{area.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description courte
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                required
                value={formData.shortDescription}
                onChange={(e) => setFormData({ ...formData, shortDescription: e.target.value })}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Brève description du projet"
              />
              <button
                type="button"
                onClick={generateDescriptions}
                disabled={isGeneratingDescription}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50"
              >
                <Wand2 className="w-4 h-4" />
                {isGeneratingDescription ? 'Génération...' : 'Générer via IA'}
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description complète
            </label>
            <textarea
              required
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Description détaillée du projet"
            />
          </div>

          {/* Features Section */}
          <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <Building className="w-5 h-5 text-blue-600" />
                <h3 className="font-medium">Caractéristiques</h3>
              </div>
              <button
                type="button"
                onClick={generateFeatures}
                disabled={isGeneratingFeatures || !formData.description}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 flex items-center gap-2"
              >
                <Wand2 className="w-4 h-4" />
                {isGeneratingFeatures ? 'Génération...' : 'Générer via IA'}
              </button>
            </div>

            <div className="space-y-4">
              {/* Features List */}
              <div className="flex flex-wrap gap-2">
                {formData.features?.map((feature, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 rounded-full group"
                  >
                    <span className="text-sm text-blue-700">{feature}</span>
                    <button
                      type="button"
                      onClick={() => removeFeature(index)}
                      className="text-blue-400 hover:text-blue-600 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>

              {/* Add Feature Input */}
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && addFeature()}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ajouter une caractéristique..."
                />
                <button
                  type="button"
                  onClick={addFeature}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>

          {/* Images Section */}
          <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
            <div className="flex items-center gap-3 mb-4">
              <ImageIcon className="w-5 h-5 text-blue-600" />
              <h3 className="font-medium">Photos du bien</h3>
            </div>
            
            {/* Image Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
              {imageUrls.map((url, index) => (
                <div key={index} className="relative aspect-video">
                  <img
                    src={url}
                    alt={`Property ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>

            {/* Upload Button */}
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 hover:bg-blue-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Upload className="w-8 h-8 mb-3 text-blue-600" />
                  <p className="mb-2 text-sm text-blue-600">
                    <span className="font-medium">Cliquez pour ajouter</span> ou glissez des photos
                  </p>
                  <p className="text-xs text-blue-500">PNG, JPG jusqu'à 10MB</p>
                </div>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}