import React, { useState } from 'react';
import { FileText, X, Globe, Plus, Trash2 } from 'lucide-react';
import { useToast } from '../../contexts/ToastContext';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useProperties } from '../../hooks/useProperties';
import PropertySelector from '../property/PropertySelector';
import type { Property } from '../../types';

interface LandingPageFormProps {
  onClose: () => void;
}

export default function LandingPageForm({ onClose }: LandingPageFormProps) {
  const { showToast } = useToast();
  const { properties } = useProperties();
  const [showPropertySelector, setShowPropertySelector] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    project: '',
    languages: ['fr'],
    sections: {
      header: true,
      description: true,
      area: true,
      developer: true,
      prices: true,
      features: true,
      whyDubai: true,
      contact: true
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!selectedProperty) {
        showToast('error', 'Veuillez sélectionner un bien');
        return;
      }

      const pageData = {
        ...formData,
        project: selectedProperty.name,
        propertyId: selectedProperty.id,
        views: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      await addDoc(collection(db, 'landingPages'), pageData);
      showToast('success', 'Page créée avec succès');
      onClose();
    } catch (error) {
      console.error('Error creating landing page:', error);
      showToast('error', 'Erreur lors de la création de la page');
    }
  };

  const handleSlugChange = (value: string) => {
    // Convert to lowercase and replace spaces with dashes
    const slug = value.toLowerCase().replace(/\s+/g, '-');
    // Remove special characters
    const cleanSlug = slug.replace(/[^a-z0-9-]/g, '');
    setFormData({ ...formData, slug: cleanSlug });
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <FileText className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Nouvelle Landing Page</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Property Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Bien immobilier
            </label>
            {selectedProperty ? (
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div>
                  <h3 className="font-medium">{selectedProperty.name}</h3>
                  <p className="text-sm text-gray-500">{selectedProperty.area}</p>
                </div>
                <button
                  type="button"
                  onClick={() => setSelectedProperty(null)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            ) : (
              <button
                type="button"
                onClick={() => setShowPropertySelector(true)}
                className="w-full px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors flex items-center justify-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Sélectionner un bien
              </button>
            )}
          </div>

          {/* Title & Slug */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Titre de la page
              </label>
              <input
                type="text"
                required
                value={formData.title}
                onChange={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                  if (!formData.slug) {
                    handleSlugChange(e.target.value);
                  }
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                URL de la page
              </label>
              <div className="relative">
                <Globe className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  required
                  value={formData.slug}
                  onChange={(e) => handleSlugChange(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Languages */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Langues
            </label>
            <div className="flex gap-2">
              {['fr', 'en', 'ar'].map(lang => (
                <label
                  key={lang}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-50 rounded-lg cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={formData.languages.includes(lang)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFormData({
                          ...formData,
                          languages: [...formData.languages, lang]
                        });
                      } else {
                        setFormData({
                          ...formData,
                          languages: formData.languages.filter(l => l !== lang)
                        });
                      }
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">{lang.toUpperCase()}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Sections */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Sections de la page
            </label>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(formData.sections).map(([key, enabled]) => (
                <label
                  key={key}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-50 rounded-lg cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        sections: {
                          ...formData.sections,
                          [key]: e.target.checked
                        }
                      });
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm capitalize">
                    {key.replace(/([A-Z])/g, ' $1').trim()}
                  </span>
                </label>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Créer la page
            </button>
          </div>
        </form>

        {/* Property Selector Modal */}
        {showPropertySelector && (
          <PropertySelector
            onSelect={(property) => {
              setSelectedProperty(property);
              setShowPropertySelector(false);
              if (!formData.title) {
                setFormData({
                  ...formData,
                  title: property.name,
                  slug: property.name.toLowerCase().replace(/\s+/g, '-')
                });
              }
            }}
            onClose={() => setShowPropertySelector(false)}
            selectedIds={selectedProperty ? [selectedProperty.id] : []}
          />
        )}
      </div>
    </div>
  );
}