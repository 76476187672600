import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import OfferView from './pages/OfferView';
import AIWorkforce from './pages/AIWorkforce';
import LoginForm from './components/auth/LoginForm';
import PrivateRoute from './components/auth/PrivateRoute';
import CustomOfferView from './pages/CustomOfferView';
import SaleDetails from './pages/SaleDetails';
import AgentProfile from './pages/AgentProfile';
import ClientProfile from './pages/ClientProfile';
import MySales from './pages/MySales';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Agents from './pages/Agents';
import CustomOffer from './pages/CustomOffer';
import Marketing from './pages/Marketing';
import Sales from './pages/Sales';
import LandingPages from './pages/LandingPages';
import Settings from './pages/Settings';
import Areas from './pages/Areas';
import Install from './pages/Install';
import Properties from './pages/Properties';
import AgentDashboard from './pages/agent-dashboard/AgentDashboard';
import AgentSales from './pages/agent-dashboard/AgentSales';
import AgentCommissions from './pages/agent-dashboard/AgentCommissions';
import AgentSaleDetails from './pages/agent-dashboard/AgentSaleDetails';
import AgentInformation from './pages/agent-dashboard/AgentInformation';
import AgentTraining from './pages/agent-dashboard/AgentTraining';
import AgentMarketing from './pages/agent-dashboard/AgentMarketing';
import CreateCustomOffer from './pages/CreateCustomOffer';
import Pipeline from './pages/Pipeline';
import Developers from './pages/Developers';
import JoinPalm from './pages/JoinPalm';
import AgentWelcome from './pages/agent-dashboard/AgentWelcome';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          
          {/* Public Offer View */}
          <Route path="/ai" element={<AIWorkforce />} />
          <Route path="/offer/:id" element={<OfferView />} />
          <Route path="/web/:slug" element={<CustomOfferView />} />
          <Route path="/travailler-avec-palm.html" element={<JoinPalm />} />
          
          {/* Agent CRM Routes */}
          <Route path="/agentcrm/:agentSlug" element={<Layout><AgentDashboard /></Layout>} />
          <Route path="/agentcrm/:agentSlug/sales" element={<Layout><AgentSales /></Layout>} />
          <Route path="/agentcrm/:agentSlug/sales/:id" element={<Layout><AgentSaleDetails /></Layout>} />
          <Route path="/agentcrm/:agentSlug/pipeline" element={<Layout><Pipeline /></Layout>} />
          <Route path="/agentcrm/:agentSlug/marketing" element={<Layout><AgentMarketing /></Layout>} />
          <Route path="/agentcrm/:agentSlug/commissions" element={<Layout><AgentCommissions /></Layout>} />
          <Route path="/agentcrm/:agentSlug/information" element={<Layout><AgentInformation /></Layout>} />

          {/* Admin Routes */}
          <Route path="/" element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/pipeline" element={
            <PrivateRoute>
              <Layout>
                <Pipeline />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/agents" element={
            <PrivateRoute>
              <Layout>
                <Agents />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/agents/:id" element={
            <PrivateRoute>
              <Layout>
                <AgentProfile />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/clients/:id" element={
            <PrivateRoute>
              <Layout>
                <ClientProfile />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/sales" element={
            <PrivateRoute>
              <Layout>
                <Sales />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/custom-offer" element={
            <PrivateRoute>
              <Layout>
                <CustomOffer />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/properties/*" element={
            <PrivateRoute>
              <Layout>
                <Properties isAdmin={true} />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/custom-offer/new" element={
            <PrivateRoute>
              <Layout>
                <CreateCustomOffer />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/marketing" element={
            <PrivateRoute>
              <Layout>
                <Marketing />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/landing-pages" element={
            <PrivateRoute>
              <Layout>
                <LandingPages />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/areas" element={
            <PrivateRoute>
              <Layout>
                <Areas />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/sales/:id" element={
            <PrivateRoute>
              <Layout>
                <SaleDetails />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/my-sales" element={
            <PrivateRoute>
              <Layout>
                <MySales />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Layout>
                <Profile />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute requiredRole="admin">
              <Layout>
                <Settings />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/install" element={
            <PrivateRoute requiredRole="admin">
              <Layout>
                <Install />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/developers" element={
            <PrivateRoute>
              <Layout>
                <Developers />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;