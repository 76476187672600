import React from 'react';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { DollarSign, AlertTriangle, Video, Gift, ChevronRight, GraduationCap } from 'lucide-react';

export default function InformationCards() {
  const { agentSlug } = useParams<{ agentSlug: string }>();

  const cards = [
    {
      title: "Leads Chauds",
      description: "Gagnez 10.000€ à 50.000€ par mois avec nos leads qualifiés", 
      icon: DollarSign,
      color: "blue",
      highlights: ["Leads qualifiés", "Contacts sérieux", "Revenus élevés"],
      gradient: "from-blue-600 to-indigo-600"
    },
    {
      title: "Nouveautés à Venir",
      description: "Espace de travail et formation bientôt disponibles",
      icon: Gift,
      color: "green",
      highlights: [
        "Espace de travail dédié",
        "Accès 7j/7",
        "Formation vente et closing sur le CRM"
      ],
      gradient: "from-green-600 to-teal-600",
      soon: true
    },
    {
      title: "Règles Importantes",
      description: "Règles à respecter pour une collaboration réussie",
      icon: AlertTriangle,
      color: "amber",
      highlights: ["Horaires 12h-22h", "Réponse < 1h", "Préavis 24h"],
      gradient: "from-amber-500 to-orange-500"
    },
    {
      title: "Service Vidéaste",
      description: "Vidéaste professionnel disponible 7j/7",
      icon: Video,
      color: "purple",
      highlights: ["Gratuit", "Haute qualité", "Montage inclus"],
      gradient: "from-purple-600 to-pink-600"
    },
    {
      title: "Programme Parrainage",
      description: "Gagnez 10% à vie sur les ventes de vos filleuls",
      icon: Gift,
      color: "emerald",
      highlights: ["20% 1ère vente", "10% à vie", "Sans limite"],
      gradient: "from-emerald-500 to-teal-500"
    }
  ];

  return (
    <div className="relative">
      <div className="flex overflow-x-auto pb-6 gap-6 snap-x snap-mandatory scrollbar-hide">
        {cards.map((card, index) => (
          <motion.div
            key={card.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="min-w-[300px] sm:min-w-[350px] snap-start"
          >
            <Link
              to={`/agentcrm/${agentSlug}/information`}
              className="block h-full bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden group"
            >
              {/* Header with gradient */}
              <div className={`p-6 bg-gradient-to-br ${card.gradient}`}>
                <div className="flex items-center justify-between">
                  <div className="p-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
                    <card.icon className="w-6 h-6 text-white" />
                  </div>
                  <motion.div
                    initial={{ x: 0 }}
                    whileHover={{ x: 5 }}
                    className="p-2 bg-white/10 backdrop-blur-sm rounded-lg"
                  >
                    <ChevronRight className="w-5 h-5 text-white" />
                  </motion.div>
                </div>
                <h3 className="text-xl font-bold text-white mt-4">{card.title}</h3>
                <p className="text-white/80 mt-2 text-sm">{card.description}</p>
              </div>

              {/* Highlights */}
              <div className="p-6 space-y-3">
                {card.highlights.map((highlight, i) => (
                  <div 
                    key={i} 
                    className={`flex items-center gap-3 p-3 rounded-xl transition-colors ${
                      card.color === 'blue' ? 'bg-blue-50 text-blue-700' :
                      card.color === 'amber' ? 'bg-amber-50 text-amber-700' :
                      card.color === 'purple' ? 'bg-purple-50 text-purple-700' :
                      'bg-emerald-50 text-emerald-700'
                    }`}
                  >
                    <div className={`w-2 h-2 rounded-full ${
                      card.color === 'blue' ? 'bg-blue-500' :
                      card.color === 'amber' ? 'bg-amber-500' :
                      card.color === 'purple' ? 'bg-purple-500' :
                      'bg-emerald-500'
                    }`} />
                    <span className="font-medium">{highlight}</span>
                  </div>
                ))}
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}