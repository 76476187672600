import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Image, Type, Calendar, Download, Loader, Share2 } from 'lucide-react';
import html2canvas from 'html2canvas';
import { postToInstagram } from '../../services/instagramService';
import { useToast } from '../../contexts/ToastContext';

interface StoryFormData {
  title: string;
  text: string;
  backgroundImage: File | null;
  backgroundImageUrl: string;
  date: string;
}

export default function AdGenerator() {
  const { showToast } = useToast();
  const [formData, setFormData] = useState<StoryFormData>({
    title: '',
    text: '',
    backgroundImage: null,
    backgroundImageUrl: '',
    date: new Date().toLocaleDateString('fr-FR', { 
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const storyRef = React.useRef<HTMLDivElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({
        ...formData,
        backgroundImage: file,
        backgroundImageUrl: URL.createObjectURL(file)
      });
    }
  };

  const generateAndDownload = async () => {
    if (!storyRef.current) return;

    try {
      const canvas = await html2canvas(storyRef.current, {
        backgroundColor: null,
        logging: false,
        allowTaint: true,
        useCORS: true,
        scale: 4, // High quality for Instagram
        width: 1080, // Instagram recommended width
        height: 1920 // Story format height
      });
      
      const link = document.createElement('a');
      link.download = `palm-news-${formData.title.toLowerCase()}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const handleDownload = async () => {
    if (!storyRef.current) return;
    setIsGenerating(true);
    await generateAndDownload();
    setIsGenerating(false);
  };

  const handleInstagramShare = async () => {
    if (!storyRef.current) return;
    setIsGenerating(true);

    try {
      const canvas = await html2canvas(storyRef.current, {
        scale: 2,
        backgroundColor: null,
        logging: false,
        allowTaint: true,
        useCORS: true
      });

      // Convert canvas to buffer
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => resolve(blob!), 'image/jpeg', 1.0); // Maximum quality
      });

      // Generate caption
      const caption = `${formData.title}\n\n${formData.text}\n\n#PalmDubai #Dubai #RealEstate`;

      // Post to Instagram
      await postToInstagram(blob, caption);
      showToast('success', 'Publication Instagram réussie');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue';
      console.error('Error sharing to Instagram:', errorMessage);
      showToast('error', `Erreur lors de la publication Instagram: ${errorMessage}`);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      {/* Form */}
      <div className="space-y-6">
        <div className="bg-white rounded-xl border border-gray-200/50 overflow-hidden">
          <div className="p-6 border-b border-gray-200/50">
            <div className="flex items-center gap-3">
              <Image className="w-6 h-6 text-blue-600" />
              <h2 className="text-xl font-semibold">Générateur de Story News</h2>
            </div>
          </div>

          <div className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Image de fond
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors">
                <div className="space-y-1 text-center">
                  <Image className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>Télécharger une image</span>
                      <input
                        type="file"
                        accept="image/*"
                        className="sr-only"
                        onChange={handleImageUpload}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG jusqu'à 10MB</p>
                </div>
              </div>
              {formData.backgroundImageUrl && (
                <div className="mt-2">
                  <img
                    src={formData.backgroundImageUrl}
                    alt="Preview"
                    className="h-20 w-20 object-cover rounded-lg"
                  />
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titre de l'actualité
              </label>
              <div className="relative">
                <Type className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="ex: Dubai : 71% du projet Al Shindagha achevés"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Texte
              </label>
              <textarea
                value={formData.text}
                onChange={(e) => setFormData({ ...formData, text: e.target.value })}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Contenu de la news..."
              />
            </div>

            <div className="pt-4">
              <div className="flex gap-4">
                <button
                  onClick={handleDownload}
                  disabled={isGenerating}
                  className="flex-1 px-4 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
                >
                  {isGenerating ? (
                    <>
                      <Loader className="w-5 h-5 animate-spin" />
                      Génération...
                    </>
                  ) : (
                    <>
                      <Download className="w-5 h-5" />
                      Télécharger
                    </>
                  )}
                </button>
                <button
                  onClick={handleInstagramShare}
                  disabled={isGenerating}
                  className="flex-1 px-4 py-3 bg-gradient-to-r from-pink-600 to-purple-600 text-white rounded-lg hover:from-pink-700 hover:to-purple-700 transition-all duration-200 flex items-center justify-center gap-2"
                >
                  <Share2 className="w-5 h-5" />
                  Partager sur Instagram
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preview */}
      <div className="sticky top-8">
        <div 
          ref={storyRef}
          className="aspect-[9/16] overflow-hidden relative"
        >
          {/* Background Image */}
          {formData.backgroundImageUrl ? (
            <motion.div 
              className="absolute inset-0 z-0"
              initial={{ scale: 1 }}
              animate={{ scale: 1.1 }}
              transition={{ duration: 10, repeat: Infinity, repeatType: "reverse", ease: "linear" }}
              style={{
                backgroundImage: `url(${formData.backgroundImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
            </motion.div>
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-blue-900 to-purple-900" />
          )}

          {/* iOS Notch with Logo */}
          <div className="absolute top-0 left-0 right-0 h-8 z-10">
            <div className="absolute left-1/2 -translate-x-1/2 top-0 w-32 h-8 bg-black rounded-b-3xl flex items-center justify-center">
              <img 
                src="https://palmdubai.fr/templates/Default/img/logo_black.png"
                alt="Palm Logo"
                className="h-4 object-contain invert"
              />
            </div>
          </div>

          {/* Content */}
          <div className="relative h-full flex flex-col p-8">
            {/* Category Tag */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="absolute top-10 right-4 px-6 py-2 bg-gray-900/90 backdrop-blur-sm text-white text-sm font-medium rounded-lg uppercase tracking-wider"
            >
              Actualité
            </motion.div>

            {/* Main Content */}
            <div className="mt-auto space-y-6 bg-gradient-to-t from-black/80 via-black/60 to-transparent backdrop-blur-sm rounded-xl p-8">
              <motion.h1
                className="text-4xl font-bold text-white"
                initial={{ opacity: 0, scale: 0.9, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                {formData.title || "Titre de la news"}
              </motion.h1>

              <motion.div
                className="text-sm text-white/80"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                {formData.date}
              </motion.div>

              <motion.p
                className="text-lg text-white/90 leading-relaxed"
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5, ease: "easeOut" }}
              >
                {formData.text || "Contenu de la news..."}
              </motion.p>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="pt-4 mt-4 border-t border-white/20"
              >
                <div className="flex items-center justify-between">
                  <span className="text-white/60 text-sm">En savoir plus</span>
                  <span className="text-white font-medium">PalmDubai.fr</span>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}