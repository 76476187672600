import React, { useState } from 'react';
import { Target, Gift, Trophy, Save, AlertTriangle } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import type { QuarterlyGoal } from '../../types';
import { useToast } from '../../contexts/ToastContext';
import { useSettings } from '../../hooks/useSettings';

interface GoalsTabProps {
  monthlyGoal: {
    volumeTarget: number;
    reward: {
      type: string;
      description: string;
    };
  };
  setMonthlyGoal: (goal: any) => void;
  quarterlyGoals: Record<string, QuarterlyGoal>;
  setQuarterlyGoals: (goals: Record<string, QuarterlyGoal>) => void;
}

export default function GoalsTab({ 
  monthlyGoal, 
  setMonthlyGoal,
  quarterlyGoals,
  setQuarterlyGoals
}: GoalsTabProps) {
  const { agents } = useAgents();
  const { updateSettings } = useSettings();
  const { showToast } = useToast();
  const [savingAgents, setSavingAgents] = useState<Record<string, boolean>>({});

  const handleQuarterlyGoalChange = (agentId: string, updates: Partial<QuarterlyGoal>) => {
    setQuarterlyGoals({
      ...quarterlyGoals,
      [agentId]: {
        ...(quarterlyGoals[agentId] || {}),
        agentId,
        startDate: new Date().toISOString().split('T')[0],
        endDate: new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().split('T')[0],
        salesTarget: Number(updates.salesTarget) || 3,
        leadsTarget: Number(updates.leadsTarget) || 300,
        leadCost: Number(updates.leadCost) || 25,
        currentSales: 0,
        currentLeads: 0,
        conventionSigned: false,
        status: 'active',
        ...updates
      }
    });
  };

  const handleSaveAgent = async (agentId: string) => {
    setSavingAgents(prev => ({ ...prev, [agentId]: true }));
    try {
      await updateSettings({
        quarterlyGoals: {
          ...quarterlyGoals,
          [agentId]: quarterlyGoals[agentId]
        }
      });
      showToast('success', 'Objectifs de l\'agent enregistrés avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'enregistrement des objectifs de l\'agent');
    } finally {
      setSavingAgents(prev => ({ ...prev, [agentId]: false }));
    }
  };
  return (
    <div className="space-y-6">
      {/* Quarterly Goals */}
      <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <Target className="w-5 h-5 text-blue-600" />
            <h3 className="font-medium">Objectifs Trimestriels par Agent</h3>
          </div>
        </div>
        
        <div className="space-y-6">
          {agents.map(agent => {
            const goal = quarterlyGoals[agent.id] || {
              agentId: agent.id,
              startDate: '2025-01-01',
              endDate: '2025-03-31',
              salesTarget: 3,
              leadsTarget: 300,
              currentSales: 0,
              currentLeads: 0,
              status: 'active'
            };

            return (
              <div key={agent.id} className="p-4 bg-white rounded-lg border border-gray-200">
                <h4 className="font-medium mb-4">{agent.name}</h4>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Objectif de ventes
                    </label>
                    <input
                      type="number"
                      value={goal?.salesTarget ?? 3}
                      onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                        salesTarget: e.target.value ? parseInt(e.target.value) : 3
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Objectif de leads
                    </label>
                    <input
                      type="number"
                      value={goal?.leadsTarget ?? 300}
                      onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                        leadsTarget: e.target.value ? parseInt(e.target.value) : 300
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Coût par lead (€)
                    </label>
                    <input
                      type="number"
                      value={goal?.leadCost ?? 25}
                      onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                        leadCost: e.target.value ? parseInt(e.target.value) : 25
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Date de début
                    </label>
                    <input
                      type="date"
                      value={goal?.startDate ?? new Date().toISOString().split('T')[0]}
                      onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                        startDate: e.target.value
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Date de fin
                    </label>
                    <input
                      type="date"
                      value={goal?.endDate ?? new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().split('T')[0]}
                      onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                        endDate: e.target.value
                      })}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  {/* Lead Cost Info */}
                  {goal?.conventionSigned && !goal?.objectiveReached && (
                    <div className="col-span-2 p-4 bg-gradient-to-br from-red-50 to-red-100/50 rounded-xl border border-red-200/50">
                      <div className="flex items-center gap-2 mb-2">
                        <AlertTriangle className="w-5 h-5 text-red-600" />
                        <h4 className="font-medium text-red-900">Participation aux coûts des leads</h4>
                      </div>
                      <p className="text-red-700">
                        Si l'objectif n'est pas atteint, vous devrez participer à 50% du coût des leads ({goal.leadCost}€/lead).
                        <br />
                        Coût total estimé : {(goal.leadsTarget * goal.leadCost * 0.5).toFixed(2)}€
                      </p>
                    </div>
                  )}

                  {/* Bonus Description */}
                  <div className="col-span-2 mt-4 p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
                    <div className="flex items-center gap-2 mb-4">
                      <Gift className="w-5 h-5 text-purple-600" />
                      <h4 className="font-medium text-purple-900">BONUS après objectif atteint</h4>
                    </div>
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description du bonus
                      </label>
                      <textarea
                        value={goal?.bonusDescription ?? ''}
                        onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                          bonusDescription: e.target.value
                        })}
                        rows={3}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Ex: iPhone 16 Pro Max"
                      />
                    </div>
                  </div>

                  {/* Convention Signature */}
                  <div className="col-span-2 mt-4">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={goal.conventionSigned || false}
                        onChange={(e) => handleQuarterlyGoalChange(agent.id, {
                          ...goal,
                          conventionSigned: e.target.checked
                        })}
                        className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm font-medium text-gray-700">Convention signée</span>
                    </label>
                  </div>
                  
                  {/* Save Button */}
                  <div className="col-span-2 flex justify-end mt-4">
                    <button
                      type="button"
                      onClick={() => handleSaveAgent(agent.id)}
                      disabled={savingAgents[agent.id]}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2 disabled:opacity-50"
                    >
                      <Save className="w-4 h-4" />
                      {savingAgents[agent.id] ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}