import React, { useState } from 'react';
import { MapPin, Plus, Search } from 'lucide-react';
import { useAreas } from '../hooks/useAreas'; 
import { useToast } from '../contexts/ToastContext';
import AreaForm from '../components/property/AreaForm';
import { motion } from 'framer-motion';
import CustomOfferNavbar from '../components/property/CustomOfferNavbar';

export default function Areas() {
  const { areas, loading, error, deleteArea, addArea } = useAreas();
  const { showToast } = useToast();
  const [showAddArea, setShowAddArea] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredAreas = areas.filter(area => {
    if (!searchQuery) return true;
    return area.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDelete = async (id: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce quartier ?')) return;
    
    try {
      await deleteArea(id);
      showToast('success', 'Quartier supprimé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la suppression du quartier');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      <CustomOfferNavbar />
      <div className="mt-16">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <MapPin className="w-6 h-6 text-blue-600" />
                <div>
                  <h1 className="text-xl font-semibold">Quartiers</h1>
                  <p className="text-sm text-gray-500 mt-1">
                    {areas.length} quartiers enregistrés
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowAddArea(true)}
              className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouveau quartier
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher un quartier..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Areas Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredAreas.map((area) => (
          <motion.div
            key={area.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
          >
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{area.name}</h3>
              <p className="text-gray-600 mb-4">{area.description}</p>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="p-3 bg-blue-50 rounded-lg">
                  <p className="text-sm text-gray-600">Dubai Mall</p>
                  <p className="font-medium">{area.distances.dubaiMall}</p>
                </div>
                <div className="p-3 bg-blue-50 rounded-lg">
                  <p className="text-sm text-gray-600">Dubai Marina</p>
                  <p className="font-medium">{area.distances.dubaiMarina}</p>
                </div>
                <div className="p-3 bg-blue-50 rounded-lg">
                  <p className="text-sm text-gray-600">DXB Airport</p>
                  <p className="font-medium">{area.distances.dxbAirport}</p>
                </div>
                <div className="p-3 bg-blue-50 rounded-lg">
                  <p className="text-sm text-gray-600">Rendement Airbnb</p>
                  <p className="font-medium">{area.distances.airbnbYield}</p>
                </div>
              </div>

              <div className="space-y-2">
                <h4 className="font-medium">Points d'intérêt</h4>
                <div className="flex flex-wrap gap-2">
                  {area.pointsOfInterest.map((poi, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                    >
                      {poi}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200/50">
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => handleDelete(area.id)}
                  className="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Add Area Modal */}
      {showAddArea && (
        <AreaForm
          onSubmit={async (area) => {
            try {
              await addArea(area);
              showToast('success', 'Quartier ajouté avec succès');
              setShowAddArea(false);
            } catch (error) {
              showToast('error', 'Erreur lors de l\'ajout du quartier');
            }
          }}
          onClose={() => setShowAddArea(false)}
        />
      )}
      </div>
    </div>
  );
}