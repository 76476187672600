import React from 'react';
import { Calendar, PartyPopper } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../ui/BlurredNumber';

interface SalePriceCardProps {
  price: number;
  saleDate: string;
}

export default function SalePriceCard({ price, saleDate }: SalePriceCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="bg-gradient-to-br from-green-900 to-emerald-800 rounded-xl overflow-hidden shadow-xl relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-64 h-64 bg-green-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="p-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <div className="flex items-center gap-2 mb-3">
            <div className="p-2 bg-white/10 rounded-lg">
              <PartyPopper className="w-5 h-5 text-green-300" />
            </div>
            <h3 className="font-medium text-white">Prix du bien</h3>
          </div>
          <p className="text-3xl font-bold text-white">
            <BlurredNumber value={formatCurrency(price)} />
          </p>
          <p className="text-sm text-green-200 mt-1">
            <BlurredNumber value={formatEuro(price)} />
          </p>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-3">
            <div className="p-2 bg-white/10 rounded-lg">
              <Calendar className="w-5 h-5 text-green-300" />
            </div>
            <h3 className="font-medium text-white">Date de vente</h3>
          </div>
          <p className="text-xl font-medium text-white">{formatDateDisplay(saleDate)}</p>
        </div>
      </div>
    </motion.div>
  );
}