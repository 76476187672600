import { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Area } from '../types';

export function useAreas() {
  const [areas, setAreas] = useState<Area[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'areas'),
      (snapshot) => {
        const areaData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Area[];
        setAreas(areaData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching areas:', err);
        setError('Failed to fetch areas');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const addArea = async (areaData: Omit<Area, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'areas'), {
        ...areaData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding area:', error);
      throw new Error('Failed to add area');
    }
  };

  const updateArea = async (id: string, updates: Partial<Area>) => {
    try {
      const areaRef = doc(db, 'areas', id);
      await updateDoc(areaRef, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error updating area:', error);
      throw new Error('Failed to update area');
    }
  };

  const deleteArea = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'areas', id));
    } catch (error) {
      console.error('Error deleting area:', error);
      throw new Error('Failed to delete area');
    }
  };

  return {
    areas,
    loading,
    error,
    addArea,
    updateArea,
    deleteArea,
  };
}