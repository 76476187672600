import React from 'react';
import { motion } from 'framer-motion';
import { Info, Gift, Users, DollarSign, TrendingUp, ChevronRight, GraduationCap, Building } from 'lucide-react';
import AgentRules from '../../components/agent-dashboard/AgentRules';
import ReferralBanner from '../../components/agent-dashboard/ReferralBanner';
import VideographerService from '../../components/agent-dashboard/VideographerService';

export default function AgentInformation() {
  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6">
          <div className="flex items-center gap-3">
            <Info className="w-6 h-6 text-blue-600" />
            <h1 className="text-2xl font-bold">Information</h1>
          </div>
        </div>
      </div>

      {/* Upcoming Features Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-br from-green-900 to-emerald-900 rounded-xl overflow-hidden relative"
      >
        <motion.div
          className="absolute top-0 right-0 w-96 h-96 bg-green-500/10 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div className="relative p-8">
          <div className="flex items-start gap-6">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <div className="p-4 bg-gradient-to-br from-emerald-400 to-emerald-500 rounded-xl shadow-lg">
                <Gift className="w-10 h-10 text-yellow-500" />
              </div>
            </motion.div>

            <div>
              <motion.h2
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-2xl font-bold text-white mb-6"
              >
                Nouveautés à Venir
              </motion.h2>

              <div className="space-y-4">
                {/* Workspace */}
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-emerald-200/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Building className="w-5 h-5 text-emerald-300" />
                    <span className="text-emerald-100 font-medium">Espace de Travail</span>
                  </div>
                  <p className="text-white">
                    Un espace de travail dédié sera bientôt disponible avec un accès 7j/7 pour que vous puissiez travailler dans les meilleures conditions.
                  </p>
                </motion.div>

                {/* Training */}
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-emerald-200/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <GraduationCap className="w-5 h-5 text-emerald-300" />
                    <span className="text-emerald-100 font-medium">Formation</span>
                  </div>
                  <p className="text-white">
                    Un espace formation avec des techniques de vente et closing sera bientôt disponible directement sur votre CRM.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Hot Leads Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-br from-blue-900 to-blue-800 rounded-xl overflow-hidden relative"
      >
        {/* Animated background elements */}
        <motion.div
          className="absolute top-0 right-0 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <motion.div
          className="absolute bottom-0 left-0 w-96 h-96 bg-blue-400/10 rounded-full blur-3xl"
          animate={{
            scale: [1.2, 1, 1.2],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div className="relative p-8">
          <div className="flex flex-col md:flex-row items-start gap-8">
            {/* Content */}
            <div className="flex-1">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
                className="p-4 bg-gradient-to-br from-green-400 to-green-500 rounded-xl shadow-lg w-fit mb-6"
              >
                <DollarSign className="w-10 h-10 text-white" />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h2 className="text-3xl font-bold text-white mb-4">
                  Leads Chauds et Qualifiés en Exclusivité !
                </h2>
                <p className="text-2xl font-bold text-green-300 mb-6">
                  10.000€ à 50.000€ par mois et plus !
                </p>
                <p className="text-lg text-blue-100 mb-8">
                  Palm Dubai Immo est la seule agence à Dubai à offrir à ses agents des leads chauds et qualifiés. 
                  Avec notre système unique, nos agents motivés peuvent gagner entre 10.000€ et 50.000€ par mois, 
                  voire plus ! Votre succès est notre priorité.
                </p>
              </motion.div>

              {/* Features Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Users className="w-5 h-5 text-green-300" />
                    <span className="text-green-100">Leads Qualifiés</span>
                  </div>
                  <p className="text-white font-medium">Contacts sérieux et vérifiés</p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Gift className="w-5 h-5 text-yellow-300" />
                    <span className="text-yellow-100">Exclusivité</span>
                  </div>
                  <p className="text-white font-medium">Leads réservés uniquement à Palm</p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <DollarSign className="w-5 h-5 text-blue-300" />
                    <span className="text-blue-100">Revenus Élevés</span>
                  </div>
                  <p className="text-white font-medium">10.000€ à 50.000€ par mois</p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <TrendingUp className="w-5 h-5 text-pink-300" />
                    <span className="text-pink-100">Sans Limite</span>
                  </div>
                  <p className="text-white font-medium">Plus vous vendez, plus vous gagnez</p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Rules Section */}
      <AgentRules />
      {/* Referral Banner */}
      <ReferralBanner />

      {/* Videographer Service */}
      <VideographerService />

    </div>
  );
}