import React from 'react';
import RecruitmentStories from './RecruitmentStories';
import { motion } from 'framer-motion';
import { DollarSign, Gift, GraduationCap, Globe } from 'lucide-react';

interface StoryPreviewProps {
  mode?: 'recruitment' | 'default';
}

export default function StoryPreview({ mode = 'default' }: StoryPreviewProps) {
  const [activeTemplate, setActiveTemplate] = React.useState<string | null>(null);

  if (mode === 'recruitment') {
    return <RecruitmentStories />;
  }

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-center">Sélectionnez un template</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="p-6 bg-gradient-to-br from-yellow-50 to-yellow-100/50 rounded-xl border border-yellow-200/50 hover:border-yellow-300/50 transition-all duration-200"
          onClick={() => setActiveTemplate('earnings')}
        >
          <DollarSign className="w-8 h-8 text-yellow-600 mb-3" />
          <h4 className="font-medium">Revenus</h4>
          <p className="text-sm text-gray-600 mt-1">10-50k€/mois</p>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="p-6 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50 hover:border-blue-300/50 transition-all duration-200"
          onClick={() => setActiveTemplate('benefits')}
        >
          <Gift className="w-8 h-8 text-blue-600 mb-3" />
          <h4 className="font-medium">Avantages</h4>
          <p className="text-sm text-gray-600 mt-1">Exclusifs Palm</p>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="p-6 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50 hover:border-purple-300/50 transition-all duration-200"
          onClick={() => setActiveTemplate('training')}
        >
          <GraduationCap className="w-8 h-8 text-purple-600 mb-3" />
          <h4 className="font-medium">Formation</h4>
          <p className="text-sm text-gray-600 mt-1">& Mentorat</p>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="p-6 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50 hover:border-green-300/50 transition-all duration-200"
          onClick={() => setActiveTemplate('remote')}
        >
          <Globe className="w-8 h-8 text-green-600 mb-3" />
          <h4 className="font-medium">Remote</h4>
          <p className="text-sm text-gray-600 mt-1">100% à distance</p>
        </motion.button>
      </div>
    </div>
  );
}