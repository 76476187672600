import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Gift, GraduationCap, Globe, ChevronRight } from 'lucide-react';

type StoryTemplate = 'earnings' | 'benefits' | 'training' | 'remote';

export default function RecruitmentStories() {
  const [activeTemplate, setActiveTemplate] = useState<StoryTemplate>('earnings');
  const [autoSlideInterval, setAutoSlideInterval] = useState<NodeJS.Timeout | null>(null);

  // Auto-slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTemplate(current => {
        switch(current) {
          case 'earnings': return 'benefits';
          case 'benefits': return 'training';
          case 'training': return 'remote';
          case 'remote': return 'earnings';
          default: return 'earnings';
        }
      });
    }, 5000);

    setAutoSlideInterval(interval);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  // Clear auto-slide on manual interaction
  const handleTemplateChange = (template: StoryTemplate) => {
    if (autoSlideInterval) {
      clearInterval(autoSlideInterval);
      setAutoSlideInterval(null);
    }
    setActiveTemplate(template);
  };

  const templates = {
    earnings: (
      <div className="relative aspect-[9/16] bg-gradient-to-br from-blue-600 to-blue-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute inset-0 p-8 flex flex-col"
        >
          <div className="flex-1 flex flex-col justify-center items-center text-center">
            <DollarSign className="w-16 h-16 text-white mb-6" />
            <h2 className="text-4xl font-bold text-white mb-4">
              Gagnez 10.000€ à<br />50.000€ par mois
            </h2>
            <p className="text-xl text-blue-200">
              Avec nos leads qualifiés et<br />nos commissions attractives
            </p>
          </div>
          <div className="mt-auto">
            <button className="w-full px-6 py-4 bg-white text-blue-600 rounded-xl font-medium flex items-center justify-center gap-2 group">
              En savoir plus
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </motion.div>
      </div>
    ),
    benefits: (
      <div className="relative aspect-[9/16] bg-gradient-to-br from-purple-600 to-purple-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute inset-0 p-8 flex flex-col"
        >
          <div className="flex-1 flex flex-col justify-center items-center text-center">
            <Gift className="w-16 h-16 text-white mb-6" />
            <h2 className="text-4xl font-bold text-white mb-4">
              Avantages<br />Exclusifs
            </h2>
            <ul className="text-xl text-purple-200 space-y-4">
              <li>Leads qualifiés</li>
              <li>Support 24/7</li>
              <li>Outils premium</li>
              <li>Bonus mensuels</li>
            </ul>
          </div>
          <div className="mt-auto">
            <button className="w-full px-6 py-4 bg-white text-purple-600 rounded-xl font-medium flex items-center justify-center gap-2 group">
              Découvrir
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </motion.div>
      </div>
    ),
    training: (
      <div className="relative aspect-[9/16] bg-gradient-to-br from-green-600 to-green-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute inset-0 p-8 flex flex-col"
        >
          <div className="flex-1 flex flex-col justify-center items-center text-center">
            <GraduationCap className="w-16 h-16 text-white mb-6" />
            <h2 className="text-4xl font-bold text-white mb-4">
              Formation<br />Continue
            </h2>
            <ul className="text-xl text-green-200 space-y-4">
              <li>Mentorat individuel</li>
              <li>Formations en ligne</li>
              <li>Workshops mensuels</li>
              <li>Accompagnement</li>
            </ul>
          </div>
          <div className="mt-auto">
            <button className="w-full px-6 py-4 bg-white text-green-600 rounded-xl font-medium flex items-center justify-center gap-2 group">
              En savoir plus
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </motion.div>
      </div>
    ),
    remote: (
      <div className="relative aspect-[9/16] bg-gradient-to-br from-amber-600 to-amber-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute inset-0 p-8 flex flex-col"
        >
          <div className="flex-1 flex flex-col justify-center items-center text-center">
            <Globe className="w-16 h-16 text-white mb-6" />
            <h2 className="text-4xl font-bold text-white mb-4">
              Travaillez<br />à Distance
            </h2>
            <p className="text-xl text-amber-200">
              Gérez vos ventes depuis<br />la France ou la Belgique
            </p>
          </div>
          <div className="mt-auto">
            <button className="w-full px-6 py-4 bg-white text-amber-600 rounded-xl font-medium flex items-center justify-center gap-2 group">
              Postuler
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </motion.div>
      </div>
    )
  };

  return (
    <div className="w-[360px] mx-auto">
      {templates[activeTemplate]}
      
      {/* Story Progress Bars */}
      <div className="flex gap-2 mt-4">
        {(['earnings', 'benefits', 'training', 'remote'] as const).map((template) => (
          <button
            key={template}
            onClick={() => handleTemplateChange(template)}
            className="flex-1 h-1 rounded-full overflow-hidden bg-gray-200"
          >
            <motion.div
              initial={false}
              animate={{
                width: template === activeTemplate ? '100%' : '0%'
              }}
              className="h-full bg-blue-600"
            />
          </button>
        ))}
      </div>
    </div>
  );
}