const ZAPIER_WEBHOOK_URL = import.meta.env.VITE_ZAPIER_WEBHOOK_URL;

export async function postToInstagram(imageBlob: Blob, caption: string) {
  // Vérifier si l'URL du webhook est configurée
  if (!ZAPIER_WEBHOOK_URL) {
    console.warn('L\'URL du webhook Zapier n\'est pas configurée');
    return false;
  }

  try {
    // Convert blob to base64
    const base64Image = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]);
      };
      reader.readAsDataURL(imageBlob);
    });

    // Compress image before sending
    const compressedImage = await compressImage(base64Image);

    // Format data for Zapier
    const payload = {
      image: compressedImage,
      caption: caption,
      type: 'instagram_image',
      format: 'story'
    };

    // Send to Zapier webhook with timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10s timeout

    const response = await fetch(ZAPIER_WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
      signal: controller.signal
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(`Erreur Zapier (${response.status}): ${errorData}`);
    }

    return true;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Timeout lors de la connexion à Zapier');
      return false;
    }
    console.error('Erreur lors du partage sur Instagram:', error);
    return false;
  }
}

async function compressImage(base64: string): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = `data:image/jpeg;base64,${base64}`;
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      
      // Calculate new dimensions (max 1080px)
      const maxSize = 1080;
      let width = img.width;
      let height = img.height;
      
      if (width > height && width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
      
      canvas.width = width;
      canvas.height = height;
      
      // Draw and compress
      ctx.drawImage(img, 0, 0, width, height);
      const compressed = canvas.toDataURL('image/jpeg', 0.8);
      resolve(compressed.split(',')[1]);
    };
  });
}