import React from 'react';
import { Gift, User, DollarSign } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { formatCurrency } from '../../utils/format';

interface BonusSectionProps {
  formData: {
    bonus?: {
      amount: number;
      agencyAmount?: number;
      agents: Array<{
        agentId: string;
        amount: number;
      }>;
      isManual?: boolean;
      paid?: boolean;
    };
    agentId: string;
  };
  onChange: (data: { bonus?: { amount: number; agencyAmount?: number; agents: Array<{ agentId: string; amount: number }>; isManual?: boolean; paid?: boolean } }) => void;
}

export default function BonusSection({ formData, onChange }: BonusSectionProps) {
  const { agents } = useAgents();

  // Get the primary agent
  const primaryAgent = agents.find(agent => agent.id === formData.agentId);

  const calculateSplits = () => {
    if (!formData.bonus?.amount || formData.bonus.isManual) return null;

    const amount = formData.bonus.amount;
    if (formData.bonus.agents && formData.bonus.agents.length > 0) {
      // Equal split between all agents plus agency
      const agentCount = formData.bonus.agents.length + 1; // +1 for main agent
      const agentShare = 0.6 / agentCount;
      return {
        mainAgent: formatCurrency(amount * agentShare),
        otherAgents: formData.bonus.agents.map(agent => ({
          agentId: agent.agentId,
          amount: formatCurrency(amount * agentShare)
        })),
        agency: formatCurrency(amount * 0.4)
      };
    }
    return {
      agent: formatCurrency(amount * 0.6),
      agency: formatCurrency(amount * 0.4)
    };
  };

  const splits = calculateSplits();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Gift className="w-5 h-5 text-purple-600" />
          <h3 className="font-medium">Bonus</h3>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Montant total du bonus
          </label>
          <input
            type="number"
            value={formData.bonus?.amount || ''}
            onChange={(e) => onChange({
              bonus: {
                ...formData.bonus,
                amount: Number(e.target.value)
              }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Montant"
          />
        </div>

        <div>
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-1">
            <input
              type="checkbox"
              checked={formData.bonus?.isManual || false}
              onChange={(e) => onChange({
                bonus: {
                  ...formData.bonus,
                  isManual: e.target.checked,
                  agents: e.target.checked ? [] : undefined
                }
              })}
              className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span>Répartition manuelle</span>
          </label>
        </div>
      </div>

      {/* Manual Bonus Distribution */}
      {formData.bonus?.isManual && (
        <div className="mt-4 space-y-4">
          {/* Agency Commission */}
          <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
            <label className="block text-sm font-medium text-purple-900 mb-2">
              Commission agence
            </label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-purple-600" />
              <input
                type="number"
                value={formData.bonus?.agencyAmount || 0}
                onChange={(e) => onChange({
                  bonus: {
                    ...formData.bonus,
                    agencyAmount: Number(e.target.value)
                  }
                })}
                className="w-full pl-10 pr-4 py-2.5 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                placeholder="Commission agence"
              />
            </div>
          </div>

          {/* Agents Distribution */}
          <h4 className="font-medium text-gray-900 mt-6 mb-4">Distribution aux agents</h4>
          <div className="space-y-4">
            {/* Primary Agent */}
            {primaryAgent && (
              <div className="flex gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    value={primaryAgent.name}
                    readOnly
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg bg-gray-50"
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    value={formData.bonus?.agents?.find(a => a.agentId === primaryAgent.id)?.amount || 0}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      const primaryAgentIndex = newAgents.findIndex(a => a.agentId === primaryAgent.id);
                      if (primaryAgentIndex >= 0) {
                        newAgents[primaryAgentIndex].amount = Number(e.target.value);
                      } else {
                        newAgents.push({
                          agentId: primaryAgent.id,
                          amount: Number(e.target.value)
                        });
                      }
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Montant"
                  />
                </div>
              </div>
            )}

            {/* Additional Agents */}
            {formData.bonus.agents?.map((agent, index) => (
              agent.agentId !== formData.agentId && (
              <div key={index} className="flex gap-4">
                <div className="flex-1">
                  <select
                    value={agent.agentId}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      newAgents[index].agentId = e.target.value;
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                  >
                    <option value="">Sélectionner un agent</option>
                    {agents.filter(a => a.id !== formData.agentId).map(agent => (
                      <option key={agent.id} value={agent.id}>{agent.name}</option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    value={agent.amount}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      newAgents[index].amount = Number(e.target.value);
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Montant"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const newAgents = formData.bonus?.agents?.filter((_, i) => i !== index);
                    onChange({
                      bonus: {
                        ...formData.bonus,
                        agents: newAgents
                      }
                    });
                  }}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  ×
                </button>
              </div>
              )
            ))}
            <button
              type="button"
              onClick={() => {
                const newAgents = [...(formData.bonus?.agents || [])];
                // Add new agent only if it's not the primary agent
                newAgents.push({ agentId: '', amount: 0 });
                onChange({
                  bonus: {
                    ...formData.bonus,
                    agents: newAgents
                  }
                });
              }}
              className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
            >
              + Ajouter un agent
            </button>
          </div>
        </div>
      )}
      {/* Manual Bonus Distribution */}
      {formData.bonus?.isManual && (
        <div className="mt-4 space-y-4">
          {/* Agency Commission */}
          <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
            <label className="block text-sm font-medium text-purple-900 mb-2">
              Commission agence
            </label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-purple-600" />
              <input
                type="number"
                value={formData.bonus?.agencyAmount || 0}
                onChange={(e) => onChange({
                  bonus: {
                    ...formData.bonus,
                    agencyAmount: Number(e.target.value)
                  }
                })}
                className="w-full pl-10 pr-4 py-2.5 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                placeholder="Commission agence"
              />
            </div>
          </div>

          {/* Agents Distribution */}
          <h4 className="font-medium text-gray-900 mt-6 mb-4">Distribution aux agents</h4>
          <div className="space-y-4">
            {/* Primary Agent */}
            {primaryAgent && (
              <div className="flex gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    value={primaryAgent.name}
                    readOnly
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg bg-gray-50"
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    value={formData.bonus?.agents?.find(a => a.agentId === primaryAgent.id)?.amount || 0}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      const primaryAgentIndex = newAgents.findIndex(a => a.agentId === primaryAgent.id);
                      if (primaryAgentIndex >= 0) {
                        newAgents[primaryAgentIndex].amount = Number(e.target.value);
                      } else {
                        newAgents.push({
                          agentId: primaryAgent.id,
                          amount: Number(e.target.value)
                        });
                      }
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Montant"
                  />
                </div>
              </div>
            )}

            {/* Additional Agents */}
            {formData.bonus.agents?.map((agent, index) => (
              agent.agentId !== formData.agentId && (
              <div key={index} className="flex gap-4">
                <div className="flex-1">
                  <select
                    value={agent.agentId}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      newAgents[index].agentId = e.target.value;
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                  >
                    <option value="">Sélectionner un agent</option>
                    {agents.filter(a => a.id !== formData.agentId).map(agent => (
                      <option key={agent.id} value={agent.id}>{agent.name}</option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    value={agent.amount}
                    onChange={(e) => {
                      const newAgents = [...(formData.bonus?.agents || [])];
                      newAgents[index].amount = Number(e.target.value);
                      onChange({
                        bonus: {
                          ...formData.bonus,
                          agents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Montant"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const newAgents = formData.bonus?.agents?.filter((_, i) => i !== index);
                    onChange({
                      bonus: {
                        ...formData.bonus,
                        agents: newAgents
                      }
                    });
                  }}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  ×
                </button>
              </div>
              )
            ))}
            <button
              type="button"
              onClick={() => {
                const newAgents = [...(formData.bonus?.agents || [])];
                // Add new agent only if it's not the primary agent
                newAgents.push({ agentId: '', amount: 0 });
                onChange({
                  bonus: {
                    ...formData.bonus,
                    agents: newAgents
                  }
                });
              }}
              className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
            >
              + Ajouter un agent
            </button>
          </div>
        </div>
      )}

      {splits && (
        <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
          <h4 className="text-sm font-medium text-purple-900 mb-3">Répartition du bonus</h4>
          <div className="space-y-2">
            {!formData.bonus?.isManual && splits.otherAgents ? (
              <>
                <div className="flex justify-between text-sm">
                  <span>Agent principal ({Math.round(60 / (splits.otherAgents.length + 1))}%)</span>
                  <span className="font-medium">{splits.mainAgent}</span>
                </div>
                {splits.otherAgents.map((agent, index) => (
                  <div key={index} className="flex justify-between text-sm">
                    <span>Agent {index + 2} ({Math.round(60 / (splits.otherAgents.length + 1))}%)</span>
                    <span className="font-medium">{agent.amount}</span>
                  </div>
                ))}
                <div className="flex justify-between text-sm">
                  <span>Agence (40%)</span>
                  <span className="font-medium">{splits.agency}</span>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between text-sm">
                  <span>Agent (60%)</span>
                  <span className="font-medium">{splits.agent}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Agence (40%)</span>
                  <span className="font-medium">{splits.agency}</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}