import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { DndContext, DragOverlay, closestCorners, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { Baseline as PipelineFunnel, Plus, Search, Calendar, ChevronRight, AlertCircle, Users, Edit, X } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { formatDateDisplay } from '../utils/date';
import { collection, query, where, onSnapshot, doc, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAgents } from '../hooks/useAgents';
import { useToast } from '../contexts/ToastContext';
import Avatar from '../components/ui/Avatar';
import LeadEditModal from '../components/LeadEditModal';

interface Lead {
  id: string;
  name: string;
  createdAt: string;
  status: 'new' | 'contacted' | 'meeting' | 'negotiation' | 'won' | 'lost';
  agentId?: string;
  lastContactDate?: string;
}

const STATUSES = {
  new: { label: 'Nouveau', color: 'blue' },
  contacted: { label: 'Contacté', color: 'purple' },
  meeting: { label: 'RDV', color: 'indigo' },
  negotiation: { label: 'Négociation', color: 'amber' },
  won: { label: 'Gagné', color: 'green' },
  lost: { label: 'Perdu', color: 'red' }
} as const;

export default function Pipeline() {
  const { user } = useAuth();
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  useEffect(() => {
    // Get leads for current agent or all leads for admin
    const leadsRef = collection(db, 'leads');
    const q = user?.role === 'admin' 
      ? query(leadsRef)
      : query(leadsRef, where('agentId', '==', user?.agentId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const leadsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString()
      })) as Lead[];
      setLeads(leadsData);
    });

    return () => unsubscribe();
  }, [user]);

  const handleDragStart = (event: { active: { id: string } }) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setActiveId(null);
    
    const { active, over } = event;
    if (!over) return;

    // Get the lead being dragged
    const draggedLead = leads.find(lead => lead.id === active.id);
    if (!draggedLead) return;

    // Get the new status from the container ID
    const newStatus = over.id;
    if (draggedLead.status === newStatus || typeof newStatus !== 'string') return;

    try {
      // Update local state first for optimistic update
      setLeads(leads.map(lead => 
        lead.id === active.id 
          ? { ...lead, status: newStatus as keyof typeof STATUSES }
          : lead
      ));

      // Update lead status in database
      const leadRef = doc(db, 'leads', active.id);
      await updateDoc(leadRef, {
        status: newStatus,
        updatedAt: serverTimestamp(),
        lastContactDate: new Date().toISOString()
      });

      showToast('success', 'Lead mis à jour avec succès');
    } catch (error) {
      console.error('Error updating lead:', error);
      showToast('error', 'Erreur lors de la mise à jour du lead');
    }
  };

  const handleAssignAgent = async (agentId: string) => {
    if (!selectedLeadId) return;

    const lead = leads.find(l => l.id === selectedLeadId);
    if (!lead) return;

    try {
      const leadRef = doc(db, 'leads', selectedLeadId);
      await updateDoc(leadRef, {
        agentId,
        updatedAt: serverTimestamp(),
        lastContactDate: new Date().toISOString()
      });
      
      // Update local state
      setLeads(leads.map(l => 
        l.id === selectedLeadId 
          ? { ...l, agentId }
          : l
      ));

      showToast('success', 'Agent assigné avec succès');
      setShowAssignModal(false);
      setSelectedLeadId(null);
    } catch (error) {
      console.error('Error assigning agent:', error);
      showToast('error', 'Erreur lors de l\'assignation de l\'agent');
    }
  };

  // Filter leads based on search query
  const filteredLeads = leads.filter(lead => {
    if (!searchQuery) return true;
    return lead.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Group leads by status
  const leadsByStatus = Object.keys(STATUSES).reduce((acc, status) => {
    acc[status] = filteredLeads.filter(lead => lead.status === status);
    return acc;
  }, {} as Record<string, Lead[]>);

  return (
    <div className="max-w-full mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <PipelineFunnel className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-xl font-semibold">Pipeline</h1>
                <p className="text-sm text-gray-500 mt-1">
                  {leads.length} leads • {leads.filter(l => !l.agentId).length} non assignés
                </p>
              </div>
            </div>
            <button
              onClick={() => setShowEditModal(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouveau lead
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher un lead..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Pipeline Grid */}
      <div className="grid grid-cols-6 gap-6">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {Object.entries(STATUSES).map(([status, { label, color }]) => (
            <div key={status} className="col-span-1">
              <div className={`p-4 bg-${color}-50 rounded-t-xl border border-${color}-200 border-b-0`}>
                <div className="flex items-center justify-between">
                  <h3 className="font-medium">{label}</h3>
                  <span className="px-2 py-1 text-sm bg-white rounded-full">
                    {leadsByStatus[status]?.length || 0}
                  </span>
                </div>
              </div>

              <div className={`p-4 bg-white rounded-b-xl border border-${color}-200 min-h-[500px]`}>
                <SortableContext items={leadsByStatus[status]?.map(l => l.id) || []} strategy={horizontalListSortingStrategy}>
                  <div className="space-y-4">
                    {leadsByStatus[status]?.map(lead => (
                      <LeadCard
                        key={lead.id}
                        lead={lead}
                        onEdit={() => {
                          setSelectedLeadId(lead.id);
                          setShowEditModal(true);
                        }}
                        onAssign={() => {
                          setSelectedLeadId(lead.id);
                          setShowAssignModal(true);
                        }}
                      />
                    ))}
                  </div>
                </SortableContext>
              </div>
            </div>
          ))}

          <DragOverlay>
            {activeId ? (
              <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-4">
                {leads.find(l => l.id === activeId)?.name}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>

      {/* Assign Agent Modal */}
      {showAssignModal && selectedLeadId && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-md p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-medium">Assigner un agent</h3>
              <button
                onClick={() => {
                  setShowAssignModal(false);
                  setSelectedLeadId(null);
                }}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              {agents.map(agent => (
                <button
                  key={agent.id}
                  onClick={() => handleAssignAgent(agent.id)}
                  className="w-full flex items-center gap-4 p-4 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  <Avatar name={agent.name} size="md" />
                  <div className="text-left">
                    <p className="font-medium">{agent.name}</p>
                    <p className="text-sm text-gray-500">{agent.email}</p>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400 ml-auto" />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Edit Lead Modal */}
      {showEditModal && (
        <LeadEditModal
          lead={selectedLeadId ? leads.find(l => l.id === selectedLeadId)! : {
            id: '',
            name: '',
            status: 'new',
            createdAt: new Date().toISOString()
          }}
          onClose={() => {
            setShowEditModal(false);
            setSelectedLeadId(null);
          }}
        />
      )}
    </div>
  );
}

interface LeadCardProps {
  lead: Lead;
  onEdit: () => void;
  onAssign: () => void;
}

function LeadCard({ lead, onEdit, onAssign }: LeadCardProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: lead.id
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition
  };

  const agent = useAgents().agents.find(a => a.id === lead.agentId);

  return (
    <motion.div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 cursor-move"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="flex items-start justify-between mb-3">
        <h4 className="font-medium">{lead.name}</h4>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className="p-1 hover:bg-gray-100 rounded-lg"
        >
          <Edit className="w-4 h-4 text-gray-500" />
        </button>
      </div>

      <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
        <Calendar className="w-4 h-4" />
        <span>{formatDateDisplay(lead.lastContactDate || lead.createdAt)}</span>
      </div>

      {agent ? (
        <div className="flex items-center gap-2">
          <Avatar name={agent.name} size="sm" />
          <div>
            <p className="text-sm font-medium">{agent.name}</p>
            <p className="text-xs text-gray-500">Agent assigné</p>
          </div>
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onAssign();
          }}
          className="w-full px-3 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors flex items-center justify-center gap-2 text-sm"
        >
          <Users className="w-4 h-4" />
          Assigner un agent
        </button>
      )}
    </motion.div>
  );
}