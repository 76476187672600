import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FileText, Building, Users, MapPin, ArrowLeft, Cog, Globe } from 'lucide-react';

export default function CustomOfferNavbar() {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="fixed top-16 lg:top-0 left-0 lg:left-72 right-0 h-16 bg-white/95 backdrop-blur-xl border-b border-gray-200/50 z-30">
      <div className="h-full px-4 flex items-center gap-4">
        <Link
          to="/custom-offer"
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
        </Link>
        <div className="flex items-center gap-2">
          <Link
            to="/custom-offer"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/custom-offer')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <FileText className="w-4 h-4" />
              <span>Offres</span>
            </div>
          </Link>

          <Link
            to="/properties"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/properties')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <Building className="w-4 h-4" />
              <span>Biens</span>
            </div>
          </Link>

          <Link
            to="/developers"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/developers')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <Users className="w-4 h-4" />
              <span>Promoteurs</span>
            </div>
          </Link>

          <Link
            to="/areas"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/areas')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <MapPin className="w-4 h-4" />
              <span>Quartiers</span>
            </div>
          </Link>

          <Link
            to="/landing-pages"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/landing-pages')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <Globe className="w-4 h-4" />
              <span>Landing Pages</span>
            </div>
          </Link>

          <Link
            to="/settings"
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              isActive('/settings')
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-2">
              <Cog className="w-4 h-4" />
              <span>Configuration</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}