import React from 'react';
import { Mail, Send } from 'lucide-react';

interface ApisTabProps {
  formData: {
    apis: {
      emailjs: {
        serviceId: string;
        templateId: string;
        publicKey: string;
      };
    };
  };
  setFormData: (data: any) => void;
  handleTestEmail: () => void;
  isSendingTest: boolean;
}

export default function ApisTab({
  formData,
  setFormData,
  handleTestEmail,
  isSendingTest
}: ApisTabProps) {
  return (
    <div className="space-y-6">
      <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
        <div className="flex items-center gap-3 mb-4">
          <Mail className="w-5 h-5 text-blue-600" />
          <h3 className="font-medium">Configuration EmailJS</h3>
        </div>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Service ID
            </label>
            <input
              type="text"
              value={formData.apis.emailjs.serviceId}
              onChange={(e) => setFormData({
                ...formData,
                apis: {
                  ...formData.apis,
                  emailjs: {
                    ...formData.apis.emailjs,
                    serviceId: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Template ID
            </label>
            <input
              type="text"
              value={formData.apis.emailjs.templateId}
              onChange={(e) => setFormData({
                ...formData,
                apis: {
                  ...formData.apis,
                  emailjs: {
                    ...formData.apis.emailjs,
                    templateId: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Public Key
            </label>
            <input
              type="text"
              value={formData.apis.emailjs.publicKey}
              onChange={(e) => setFormData({
                ...formData,
                apis: {
                  ...formData.apis,
                  emailjs: {
                    ...formData.apis.emailjs,
                    publicKey: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <button
            type="button"
            onClick={handleTestEmail}
            disabled={isSendingTest}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            <Send className="w-4 h-4" />
            {isSendingTest ? 'Envoi...' : 'Envoyer un email test'}
          </button>
        </div>
      </div>
    </div>
  );
}