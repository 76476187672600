import React, { useState } from 'react';
import { FileText, Building, Users, Globe, DollarSign, Shield, TrendingUp, MapPin, Edit } from 'lucide-react';
import { useAgents } from '../hooks/useAgents';
import { useProperties } from '../hooks/useProperties';
import { useAreas } from '../hooks/useAreas';
import { useNavigate } from 'react-router-dom';
import { useDevelopers } from '../hooks/useDevelopers';
import { collection, addDoc, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { generateCustomOffer } from '../services/pdfService';
import { useToast } from '../contexts/ToastContext';
import PropertySelector from '../components/property/PropertySelector';
import PaymentPlanSection from '../components/forms/PaymentPlanSection';
import { motion } from 'framer-motion';
import type { Property } from '../types';

export default function CreateCustomOffer() {
  const { showToast } = useToast();
  const { agents } = useAgents();
  const navigate = useNavigate();
  const { areas } = useAreas();
  const { developers } = useDevelopers();
  const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
  const [showPropertySelector, setShowPropertySelector] = useState(false);
  const [showPropertyForm, setShowPropertyForm] = useState(false);
  const [editingProperty, setEditingProperty] = useState<Property | null>(null);
  const [showDeveloperForm, setShowDeveloperForm] = useState(false);
  const [showAreaForm, setShowAreaForm] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    agentId: '',
    propertyType: '',
    discount: 0,
    paymentPlan: [],
    language: 'fr',
    includePrice: true,
    includePaymentPlan: true,
    includeArea: true,
    includeDeveloper: true
  });

  const generateOfferLink = () => {
    const agent = agents.find(a => a.id === formData.agentId);
    if (!agent || !formData.clientName || selectedProperties.length === 0) return '';
    
    const offerId = Math.random().toString(36).substring(2, 10);
    return `${window.location.origin}/offer/${offerId}`;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedProperties.length === 0) {
      showToast('error', 'Veuillez sélectionner au moins un bien');
      return;
    }

    if (!formData.agentId) {
      showToast('error', 'Veuillez sélectionner un agent');
      return;
    }


    if (!formData.agentId) {
      showToast('error', 'Veuillez sélectionner un agent');
      return;
    }

    setIsGenerating(true);
    try {
      // Save offer to database
      const agent = agents.find(a => a.id === formData.agentId);
      const offerData = {
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        agentId: formData.agentId,
        agentName: agent?.name || '',
        properties: selectedProperties.map(p => p.id),
        paymentPlan: formData.paymentPlan,
        discount: formData.discount,
        propertyType: formData.propertyType,
        views: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 'published'
      };

      const docRef = await addDoc(collection(db, 'offers'), offerData);
      const offerUrl = `${window.location.origin}/offer/${docRef.id}`;

      // Update offer with URL
      await updateDoc(doc(db, 'offers', docRef.id), { url: offerUrl });
      
      showToast('success', 'Offre générée avec succès');
      
      // Show success message with link
      setOfferUrl(offerUrl);
    } catch (error) {
      console.error('Error generating offer:', error);
      showToast('error', 'Erreur lors de la génération de l\'offre');
    } finally {
      setIsGenerating(false);
    }
  };

  const [offerUrl, setOfferUrl] = useState<string | null>(null);

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Main Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
        <div className="flex items-center gap-3">
          <FileText className="w-6 h-6 text-blue-600" />
          <div>
            <h1 className="text-xl font-semibold">Créer une offre</h1>
            <p className="text-sm text-gray-500 mt-1">
              Sélectionnez les biens et personnalisez votre offre
            </p>
          </div>
        </div>
      </div>
      {/* Standard Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50 p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <Globe className="w-6 h-6 text-blue-600" />
            <h2 className="text-lg font-semibold">Pourquoi Dubai ?</h2>
          </div>
          <ul className="space-y-3 text-gray-600">
            <li className="flex items-center gap-2">
              <DollarSign className="w-4 h-4 text-green-600" />
              0% d'impôts sur les revenus locatifs
            </li>
            <li className="flex items-center gap-2">
              <TrendingUp className="w-4 h-4 text-blue-600" />
              Rendements locatifs parmi les plus élevés au monde
            </li>
            <li className="flex items-center gap-2">
              <Shield className="w-4 h-4 text-purple-600" />
              Économie stable et diversifiée
            </li>
          </ul>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50 p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <Building className="w-6 h-6 text-purple-600" />
            <h2 className="text-lg font-semibold">Palm Dubai Immo</h2>
          </div>
          <ul className="space-y-3 text-gray-600">
            <li>Plus de 10 ans d'expérience</li>
            <li>Une équipe franco-émiratie</li>
            <li>Un accompagnement personnalisé</li>
          </ul>
        </motion.div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Client Info */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <h2 className="text-lg font-semibold mb-4">Informations client</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom du client
              </label>
              <input
                type="text"
                required
                value={formData.clientName}
                onChange={(e) => setFormData({ ...formData, clientName: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email du client
              </label>
              <input
                type="email"
                value={formData.clientEmail}
                onChange={(e) => setFormData({ ...formData, clientEmail: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Agent Selection */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <Users className="w-5 h-5 text-blue-600" />
            <h2 className="text-lg font-semibold">Agent responsable</h2>
          </div>
          <select
            required
            value={formData.agentId}
            onChange={(e) => setFormData({ ...formData, agentId: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Sélectionner un agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>{agent.name}</option>
            ))}
          </select>
        </div>

        {/* Properties */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-3">
              <Building className="w-5 h-5 text-blue-600" />
              <h2 className="text-lg font-semibold">Biens sélectionnés</h2>
            </div>
            <button
              type="button"
              onClick={() => setShowPropertySelector(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ajouter un bien
            </button>
          </div>

          {selectedProperties.length > 0 ? (
            <div className="space-y-4">
              {selectedProperties.map(property => (
                <div key={property.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-1">
                    <h3 className="font-medium">{property.name}</h3>
                    <p className="text-sm text-gray-500">{property.shortDescription}</p>
                    <div className="mt-2 flex items-center gap-4 text-sm text-gray-500">
                      <span className="flex items-center gap-1">
                        <Building className="w-4 h-4" />
                        {property.developer}
                      </span>
                      <span className="flex items-center gap-1">
                        <MapPin className="w-4 h-4" />
                        {property.area}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setEditingProperty(property);
                        setShowPropertyForm(true);
                      }}
                      className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                    >
                      <Edit className="w-5 h-5" />
                    </button>
                    <button
                      type="button"
                      onClick={() => setSelectedProperties(selectedProperties.filter(p => p.id !== property.id))}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                    >
                      ×
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">Aucun bien sélectionné</p>
            </div>
          )}
        </div>

        {/* Payment Plan */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <h2 className="text-lg font-semibold mb-4">Plan de paiement</h2>
          <PaymentPlanSection
            formData={formData}
            onChange={(updates) => setFormData(prev => ({ ...prev, ...updates }))}
          />
        </div>

        {/* Options */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <h2 className="text-lg font-semibold mb-4">Options de l'offre</h2>
          <div className="space-y-4">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.includePrice}
                onChange={(e) => setFormData({ ...formData, includePrice: e.target.checked })}
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">Inclure les prix</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.includePaymentPlan}
                onChange={(e) => setFormData({ ...formData, includePaymentPlan: e.target.checked })}
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">Inclure les plans de paiement</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.includeArea}
                onChange={(e) => setFormData({ ...formData, includeArea: e.target.checked })}
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">Inclure les informations sur le quartier</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.includeDeveloper}
                onChange={(e) => setFormData({ ...formData, includeDeveloper: e.target.checked })}
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">Inclure les informations sur le promoteur</span>
            </label>
          </div>
        </div>

        {/* Success Message with Link */}
        {offerUrl && (
          <div className="mt-6 p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
            <p className="text-sm text-gray-600 mb-2">Lien de l'offre personnalisée :</p>
            <div className="flex gap-2">
              <input
                type="text"
                value={offerUrl}
                readOnly
                className="flex-1 px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm"
              />
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(offerUrl);
                  showToast('success', 'Lien copié dans le presse-papier');
                }}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
              >
                Copier
              </button>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className="flex flex-col gap-4">
          {formData.clientName && formData.agentId && selectedProperties.length > 0 && (
            <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
              <p className="text-sm text-gray-600 mb-2">Lien de l'offre personnalisée :</p>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={generateOfferLink()}
                  readOnly
                  className="flex-1 px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm"
                />
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(generateOfferLink());
                    showToast('success', 'Lien copié dans le presse-papier');
                  }}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                >
                  Copier
                </button>
              </div>
            </div>
          )}

          <button
            type="submit"
            disabled={isGenerating}
            className="px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
          >
            {isGenerating ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                Enregistrement en cours...
              </>
            ) : (
              <>
                <FileText className="w-5 h-5" />
                Enregistrer l'offre
              </>
            )}
          </button>
        </div>
      </form>

      {/* Property Selector Modal */}
      {showPropertySelector && (
        <PropertySelector
          onSelect={(property) => {
            setSelectedProperties([...selectedProperties, property]);
            setShowPropertySelector(false);
          }}
          onClose={() => setShowPropertySelector(false)}
          selectedIds={selectedProperties.map(p => p.id)}
        />
      )}

      {/* Property Form Modal */}
      {showPropertyForm && (
        <PropertyForm
          onSubmit={async (propertyData) => {
            try {
              if (editingProperty) {
                // Update existing property
                const updatedProperties = selectedProperties.map(p =>
                  p.id === editingProperty.id ? { ...p, ...propertyData } : p
                );
                setSelectedProperties(updatedProperties);
                showToast('success', 'Bien modifié avec succès');
              } else {
                // Add new property
                const newProperty = {
                  id: `temp_${Date.now()}`,
                  ...propertyData,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString()
                };
                setSelectedProperties([...selectedProperties, newProperty]);
                showToast('success', 'Bien ajouté avec succès');
              }
              setShowPropertyForm(false);
              setEditingProperty(null);
            } catch (error) {
              showToast('error', editingProperty 
                ? 'Erreur lors de la modification du bien'
                : 'Erreur lors de l\'ajout du bien'
              );
            }
          }}
          onClose={() => {
            setShowPropertyForm(false);
            setEditingProperty(null);
          }}
          developers={developers}
          areas={areas}
          initialData={editingProperty}
        />
      )}
    </div>
  );
}