import React, { useState, useEffect } from 'react';
import { Building, FileText, Plus, X, DollarSign, MapPin, Send, Edit, Trash2, Calendar, Eye } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAgents } from '../hooks/useAgents';
import { useNavigate, Link } from 'react-router-dom';
import { useAreas } from '../hooks/useAreas';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc, serverTimestamp, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useDevelopers } from '../hooks/useDevelopers';
import { useToast } from '../contexts/ToastContext';
import { formatDateDisplay } from '../utils/date';
import PropertySelector from '../components/property/PropertySelector';
import PropertyForm from '../components/property/PropertyForm';
import DeveloperForm from '../components/property/DeveloperForm';
import AreaForm from '../components/property/AreaForm';
import type { Property, CustomOffer } from '../types';
import CustomOfferNavbar from '../components/property/CustomOfferNavbar';

export default function CustomOffer() {
  const { showToast } = useToast();
  const { agents } = useAgents();
  const navigate = useNavigate();
  const [offers, setOffers] = useState<CustomOffer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const q = query(collection(db, 'offers'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const offerData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
        lastViewed: doc.data().lastViewed?.toDate?.()?.toISOString()
      })) as CustomOffer[];
      
      setOffers(offerData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      <CustomOfferNavbar />
      <div className="mt-16">
        {/* Header */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <FileText className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-xl font-semibold">Offre personnalisée</h1>
                <p className="text-sm text-gray-500 mt-1">
                  {offers.length} offres créées
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <Link
                to="/custom-offer/new"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
              >
                <Plus className="w-4 h-4" />
                Créer une offre
              </Link>
            </div>
          </div>
        </div>

        {/* Offers List */}
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {offers.map((offer) => (
            <motion.div
              key={offer.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6 hover:shadow-lg transition-all duration-200"
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-lg font-medium">{offer.clientName}</h3>
                  <p className="text-sm text-gray-500">
                    Par {agents.find(a => a.id === offer.agentId)?.name || offer.agentName}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => window.open(`/offer/${offer.id}`, '_blank')}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm('Êtes-vous sûr de vouloir supprimer cette offre ?')) {
                        deleteDoc(doc(db, 'offers', offer.id));
                      }
                    }}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
              
              <div className="flex items-center gap-4 text-sm text-gray-500">
                <div className="flex items-center gap-1">
                  <Calendar className="w-4 h-4" />
                  <span>{formatDateDisplay(offer.createdAt)}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Eye className="w-4 h-4" />
                  <span>{offer.views} vues</span>
                </div>
              </div>

              {offer.lastViewed && (
                <p className="text-xs text-gray-400 mt-2">
                  Dernière consultation : {formatDateDisplay(offer.lastViewed)}
                </p>
              )}
            </motion.div>
          ))}

          {offers.length === 0 && (
            <div className="col-span-2 text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
              <FileText className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">Aucune offre trouvée</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}