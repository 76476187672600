import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Building, DollarSign, Shield, TrendingUp, MapPin, Phone, Mail, Globe, Home, Calendar, FileCheck, CreditCard, Phone as PhoneIcon, AtSign, MapPinned, UserCheck } from 'lucide-react';
import { doc, getDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { formatCurrency } from '../utils/format';

export default function CustomOfferView() {
  const { slug } = useParams<{ slug: string }>();
  const [offerData, setOfferData] = useState<{
    title: string;
    description: string;
    images: string[];
    price?: number;
    features?: string[];
    area?: string;
    developer?: string;
    paymentPlan?: {
      downPayment: number;
      installments: Array<{
        percentage: number;
        description: string;
      }>;
    };
    loading: boolean;
    error: string | null;
  } | null>(null);

  useEffect(() => {
    if (slug) {
      setOfferData(prev => prev ? { ...prev, loading: true, error: null } : null);
      try {
        // Get offer data from Firestore
        const getOffer = async () => {
          // Query by slug instead of using slug as ID
          const q = query(collection(db, 'landingPages'), where('slug', '==', slug));
          const snapshot = await getDocs(q);
          
          if (snapshot.empty) {
            setOfferData({
              title: 'Page non trouvée',
              description: 'Cette page n\'existe pas ou a été supprimée.',
              images: [],
              loading: false,
              error: '404'
            });
            return;
          }
          const data = snapshot.docs[0].data();
          setOfferData({
            title: data.title || '',
            description: data.description || '',
            images: data.images || [],
            price: data.price,
            features: data.features,
            area: data.area,
            developer: data.developer,
            paymentPlan: data.paymentPlan,
            loading: false,
            error: null
          });

            // Increment view count
            await updateDoc(snapshot.docs[0].ref, {
              views: increment(1),
              lastViewed: serverTimestamp()
            });
        };
        getOffer();
      } catch (error) {
        console.error('Error loading landing page:', error);
        setOfferData(prev => prev ? {
          ...prev,
          loading: false,
          error: 'Une erreur est survenue lors du chargement de la page.'
        } : null);
      }
    }
  }, [slug]);

  if (!offerData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (offerData.error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">{offerData.title}</h1>
          <p className="text-gray-600">{offerData.description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="relative h-screen">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={offerData.images?.[0] ? {
            backgroundImage: `url(${offerData.images[0]})`
          } : {
            backgroundImage: 'url(https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=2000&q=80)'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
        </div>

        {/* Content */}
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center text-white">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6"
          >
            {offerData.title}
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl sm:text-2xl text-gray-200 mb-12 max-w-2xl"
          >
            {offerData.content}
          </motion.p>

          {offerData.price && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="flex flex-wrap gap-6"
            >
              <div className="flex items-center gap-3 px-6 py-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
                <DollarSign className="w-6 h-6 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-300">À partir de</p>
                  <p className="text-lg font-semibold">{formatCurrency(offerData.price)}</p>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>

      {/* Features Section */}
      {offerData.features && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl font-bold text-center mb-12"
          >
            Caractéristiques
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {offerData.features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl p-6 shadow-sm"
              >
                <div className="p-2 bg-blue-50 rounded-lg w-fit mb-4">
                  <Home className="w-5 h-5 text-blue-600" />
                </div>
                <p className="text-gray-700">{feature}</p>
              </motion.div>
            ))}
          </div>
        </div>
      )}

      {/* Payment Plan Section */}
      {offerData.paymentPlan && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl font-bold text-center mb-12"
          >
            Plan de Paiement
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl p-6 shadow-sm"
            >
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <DollarSign className="w-5 h-5 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold">Acompte</h3>
              </div>
              <p className="text-3xl font-bold text-blue-600">
                {offerData.paymentPlan.downPayment}%
              </p>
            </motion.div>

            {offerData.paymentPlan.installments.map((installment, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl p-6 shadow-sm"
              >
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-2 bg-blue-50 rounded-lg">
                    <Calendar className="w-5 h-5 text-blue-600" />
                  </div>
                  <h3 className="text-lg font-semibold">{installment.description}</h3>
                </div>
                <p className="text-3xl font-bold text-blue-600">
                  {installment.percentage}%
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      )}

      {/* Contact Section */}
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Prêt à Investir ?
            </h2>
            <p className="text-xl text-gray-300">
              Notre équipe d'experts est là pour vous accompagner
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <h3 className="text-2xl font-bold">
                Contactez-nous
              </h3>
              <div className="space-y-4">
                <a
                  href="tel:+971501234567"
                  className="flex items-center gap-3 px-6 py-4 bg-white/10 backdrop-blur-sm rounded-xl hover:bg-white/20 transition-colors"
                >
                  <Phone className="w-6 h-6" />
                  <span className="text-lg">+971 50 123 4567</span>
                </a>
                <a
                  href="mailto:contact@palmdubai.fr"
                  className="flex items-center gap-3 px-6 py-4 bg-white/10 backdrop-blur-sm rounded-xl hover:bg-white/20 transition-colors"
                >
                  <Mail className="w-6 h-6" />
                  <span className="text-lg">contact@palmdubai.fr</span>
                </a>
                <a
                  href="https://wa.me/971501234567"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 px-6 py-4 bg-[#25D366]/20 backdrop-blur-sm rounded-xl hover:bg-[#25D366]/30 transition-colors"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
                  </svg>
                  <span className="text-lg">WhatsApp</span>
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <h3 className="text-2xl font-bold">Palm Dubai Immo</h3>
              <p className="text-lg text-gray-300 leading-relaxed">
                Votre partenaire de confiance pour investir à Dubai depuis plus de 10 ans. 
                Notre équipe d'experts vous accompagne dans toutes les étapes de votre investissement.
              </p>
              <div className="flex items-center gap-4">
                <img 
                  src="https://palmdubai.fr/templates/Default/img/logo_black.png"
                  alt="Palm Dubai Logo"
                  className="h-12 invert"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}