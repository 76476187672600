import React, { useState, useEffect } from 'react';
import { FileText, Plus, Search, Globe, Eye, Trash2, Settings } from 'lucide-react';
import { motion } from 'framer-motion';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useToast } from '../contexts/ToastContext';
import LandingPageForm from '../components/landingPage/LandingPageForm';
import LandingPageSettings from '../components/landingPage/LandingPageSettings';
import { formatDateDisplay } from '../utils/date';

interface LandingPage {
  id: string;
  title: string;
  slug: string;
  project: string;
  views: number;
  languages: string[];
  createdAt: string;
  lastViewed?: string;
}

export default function LandingPages() {
  const { showToast } = useToast();
  const [pages, setPages] = useState<LandingPage[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddPage, setShowAddPage] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'landingPages'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const pageData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
        lastViewed: doc.data().lastViewed?.toDate?.()?.toISOString()
      })) as LandingPage[];
      
      setPages(pageData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const filteredPages = pages.filter(page => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      page.title.toLowerCase().includes(query) ||
      page.project.toLowerCase().includes(query)
    );
  });

  const handleDelete = async (id: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette page ?')) return;
    
    try {
      await deleteDoc(doc(db, 'landingPages', id));
      showToast('success', 'Page supprimée avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la suppression de la page');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <FileText className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-xl font-semibold">Landing Pages</h1>
                <p className="text-sm text-gray-500 mt-1">
                  {pages.length} pages créées
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setShowSettings(true)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <Settings className="w-5 h-5" />
              </button>
              <button
                onClick={() => setShowAddPage(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Nouvelle page
              </button>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher une page..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Pages Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredPages.map((page) => (
          <motion.div
            key={page.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl p-6 hover:shadow-lg transition-all duration-200"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-medium">{page.title}</h3>
                <p className="text-sm text-gray-500">{page.project}</p>
                <div className="flex items-center gap-2 mt-2">
                  <Globe className="w-4 h-4 text-gray-400" />
                  <p className="text-sm text-blue-600">
                    {window.location.origin}/web/{page.slug}
                  </p>
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => window.open(`/web/${page.slug}`, '_blank')}
                  className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                >
                  <Eye className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(page.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
            
            <div className="flex items-center gap-4 text-sm text-gray-500">
              <div className="flex items-center gap-1">
                <Eye className="w-4 h-4" />
                <span>{page.views} vues</span>
              </div>
              <div className="flex items-center gap-2">
                {page.languages.map(lang => (
                  <span
                    key={lang}
                    className="px-2 py-0.5 bg-gray-100 rounded-full text-xs"
                  >
                    {lang.toUpperCase()}
                  </span>
                ))}
              </div>
            </div>

            {page.lastViewed && (
              <p className="text-xs text-gray-400 mt-2">
                Dernière consultation : {formatDateDisplay(page.lastViewed)}
              </p>
            )}
          </motion.div>
        ))}
      </div>

      {/* Add Page Modal */}
      {showAddPage && (
        <LandingPageForm onClose={() => setShowAddPage(false)} />
      )}

      {/* Settings Modal */}
      {showSettings && (
        <LandingPageSettings onClose={() => setShowSettings(false)} />
      )}
    </div>
  );
}