import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Agent, Sale, Property } from '../types';
import { formatCurrency, formatEuro } from '../utils/format';
import { calculateCommission } from '../utils/commission';
import { formatDateDisplay } from '../utils/date';

export function generateSaleReport(sale: Sale, agent?: Agent, referrer?: Agent) {
  const doc = new jsPDF();
  const commission = calculateCommission(sale);

  // Add header
  doc.setFontSize(20);
  doc.text('Rapport de Vente', 105, 20, { align: 'center' });
  
  // Add sale info
  doc.setFontSize(12);
  doc.text('Détails de la vente:', 20, 40);
  
  const saleInfo = [
    ['Projet', sale.project],
    ['Unité', sale.unitNumber],
    ['Prix', `${formatCurrency(sale.propertyPrice)} (${formatEuro(sale.propertyPrice)})`],
    ['Date', formatDateDisplay(sale.saleDate)],
    ['Agent', agent?.name || 'N/A'],
    ['Parrain', referrer?.name || 'N/A'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: saleInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add commission info
  doc.text('Commissions:', 20, doc.lastAutoTable.finalY + 20);

  const commissionInfo = [
    ['Commission totale', `${formatCurrency(sale.developerCommission)} (${formatEuro(sale.developerCommission)})`],
    ['Commission agent', `${formatCurrency(commission.agent)} (${formatEuro(commission.agent)})`],
    ['Commission parrain', referrer ? `${formatCurrency(commission.referrer || 0)} (${formatEuro(commission.referrer || 0)})` : 'N/A'],
    ['Commission agence', `${formatCurrency(commission.agency)} (${formatEuro(commission.agency)})`]
  ];

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [],
    body: commissionInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}

export function generateClientReport(
  client: { name: string; email?: string; phone?: string },
  sales: Sale[],
  agents: Agent[],
  stats: {
    totalVolume: number;
    totalUnits: number;
    totalAgencyCommission: number;
    totalProjects: Set<string>;
  }
) {
  const doc = new jsPDF();

  // Add header with logo
  doc.setFontSize(20);
  doc.text('Rapport Client', 105, 20, { align: 'center' });

  // Add client info
  doc.setFontSize(12);
  doc.text('Informations client:', 20, 40);

  const clientInfo = [
    ['Nom', client.name],
    ['Email', client.email || 'Non renseigné'],
    ['Téléphone', client.phone || 'Non renseigné'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: clientInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add stats
  doc.text('Statistiques:', 20, doc.lastAutoTable.finalY + 20);

  const statsInfo = [
    ['Volume total', `${formatCurrency(stats.totalVolume)} (${formatEuro(stats.totalVolume)})`],
    ['Unités achetées', `${stats.totalUnits} dans ${stats.totalProjects.size} projet(s)`],
    ['Commission agence', `${formatCurrency(stats.totalAgencyCommission)} (${formatEuro(stats.totalAgencyCommission)})`],
    ['Moyenne par unité', `${formatCurrency(stats.totalVolume / stats.totalUnits)} (${formatEuro(stats.totalVolume / stats.totalUnits)})`]
  ];

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [],
    body: statsInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add sales table
  doc.text('Historique des achats:', 20, doc.lastAutoTable.finalY + 20);

  const salesData = sales.map(sale => {
    const commission = calculateCommission(sale);
    const agent = agents.find(a => a.id === sale.agentId);
    const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;
    
    return [
      formatDateDisplay(sale.saleDate),
      sale.project,
      sale.unitNumber,
      formatCurrency(sale.propertyPrice),
      agent?.name || 'N/A',
      formatCurrency(commission.agent),
      referrer?.name || '-',
      referrer ? formatCurrency(commission.referrer || 0) : '-',
      formatCurrency(commission.agency),
      sale.paymentStatus.developer ? 'Reçue' : 'En attente'
    ];
  });

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [['Date', 'Projet', 'Unité', 'Prix', 'Agent', 'Comm. Agent', 'Parrain', 'Comm. Parrain', 'Comm. Agence', 'Statut']],
    body: salesData,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: [59, 130, 246] }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}

export function generateAgentReport(agent: Agent, sales: Sale[]) {
  const doc = new jsPDF();

  // Add header
  doc.setFontSize(20);
  doc.text('Rapport Agent', 105, 20, { align: 'center' });
  
  // Add agent info
  doc.setFontSize(12);
  doc.text('Informations agent:', 20, 40);
  
  const agentInfo = [
    ['Nom', agent.name],
    ['Email', agent.email],
    ['Téléphone', agent.phone || 'Non renseigné'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: agentInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add sales table
  doc.text('Ventes:', 20, doc.lastAutoTable.finalY + 20);

  const salesData = sales.map(sale => {
    const commission = calculateCommission(sale);
    return [
      formatDateDisplay(sale.saleDate),
      sale.project,
      sale.unitNumber,
      formatCurrency(sale.propertyPrice),
      formatCurrency(commission.agent),
      formatCurrency(commission.agency),
      sale.paymentStatus.developer ? 'Reçue' : 'En attente'
    ];
  });

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [['Date', 'Projet', 'Unité', 'Prix', 'Commission', 'Commission Agence', 'Statut']],
    body: salesData,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: [59, 130, 246] }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}

interface CustomOfferOptions {
  properties: Property[];
  clientName: string;
  clientEmail?: string;
  language: string;
  includePrice: boolean;
  includePaymentPlan: boolean;
  includeArea: boolean;
  includeDeveloper: boolean;
}

export async function generateCustomOffer(options: CustomOfferOptions) {
  const doc = new jsPDF();
  try {
    const settings = await getSettings();
    const templates = settings?.pdfTemplates || {
      dubai_advantages: {
        template: `
          <h2>Pourquoi investir à Dubai ?</h2>
          <ul>
            <li>0% d'impôts sur les revenus locatifs</li>
            <li>Rendements locatifs parmi les plus élevés au monde</li>
            <li>Marché immobilier en pleine croissance</li>
            <li>Économie diversifiée et stable</li>
            <li>Qualité de vie exceptionnelle</li>
          </ul>
        `
      },
      returns: {
        template: `
          <h2>Des rendements exceptionnels</h2>
          <p>Le marché immobilier de Dubai offre des rendements locatifs parmi les plus élevés au monde :</p>
          <ul>
            <li>Studios : 7-9% de rendement annuel</li>
            <li>Appartements : 6-8% de rendement annuel</li>
            <li>Villas : 5-7% de rendement annuel</li>
          </ul>
        `
      },
      agency: {
        template: `
          <h2>Palm Dubai Immo</h2>
          <p>Votre partenaire de confiance pour investir à Dubai</p>
          <ul>
            <li>Plus de 10 ans d'expérience</li>
            <li>Une équipe franco-émiratie</li>
            <li>Un accompagnement personnalisé</li>
            <li>Les meilleurs projets sélectionnés pour vous</li>
          </ul>
        `
      },
      closing: {
        template: `
          <div style="text-align: center;">
            <h2>Contactez-nous</h2>
            <p>Email: contact@palmdubaiimmo.com</p>
            <p>Téléphone: +971 XX XXX XXXX</p>
            <p>www.palmdubai.fr</p>
          </div>
        `
      }
    };

    // Add header with logo
    try {
      doc.addImage('https://palmdubai.fr/templates/Default/img/logo_black.png', 'PNG', 20, 10, 40, 20);
    } catch (error) {
      console.warn('Failed to add logo to PDF:', error);
      // Continue without logo
    }
    
    doc.setFontSize(20);
    doc.text('Offre personnalisée', 105, 40, { align: 'center' });

    // Add client info if provided
    if (options.clientName) {
      doc.setFontSize(12);
      doc.text(`Préparé pour : ${options.clientName}`, 20, 60);
      if (options.clientEmail) {
        doc.text(`Email : ${options.clientEmail}`, 20, 70);
      }
    }

    // Add Dubai advantages page
    if (templates.dubai_advantages) {
      doc.addPage();
      try {
        doc.html(templates.dubai_advantages.template, {
          x: 20,
          y: 20,
          width: 170,
          callback: function() {
            // Continue with next section
          }
        });
      } catch (error) {
        console.warn('Failed to add Dubai advantages section:', error);
      }
    }

    // Add properties
    options.properties.forEach((property, index) => {
      doc.addPage();
    
      // Property header
      doc.setFontSize(16);
      doc.text(property.name, 20, 20);
    
      // Property images
      let y = 30;
      
      // Add property image with developer logo overlay
      if (property.images?.[0]) {
        try {
          doc.addImage(property.images[0], 'JPEG', 20, y, 170, 100);
          
          // Add developer logo if available
          const developer = developers.find(d => d.name === property.developer);
          if (developer?.logo) {
            doc.addImage(developer.logo, 'PNG', 150, y + 10, 30, 30);
          }
          
          y += 110;
        } catch (error) {
          console.warn(`Failed to add image for property ${property.name}:`, error);
        }
      }
    
      // Property details
      doc.setFontSize(12);
      
      // Add developer and area info in a stylish box
      if (options.includeDeveloper || options.includeArea) {
        doc.setFillColor(240, 249, 255); // Light blue background
        doc.rect(20, y, 170, 40, 'F');
        doc.setTextColor(30, 64, 175); // Blue text
        
        if (options.includeDeveloper) {
          doc.setFontSize(10);
          doc.text('Promoteur', 30, y + 15);
          doc.setFontSize(12);
          doc.text(property.developer, 30, y + 30);
        }
        
        if (options.includeArea) {
          doc.setFontSize(10);
          doc.text('Quartier', 120, y + 15);
          doc.setFontSize(12);
          doc.text(property.area, 120, y + 30);
        }
        
        y += 50;
      }
      
      // Reset text color
      doc.setTextColor(0, 0, 0);
    
      doc.text('Description :', 20, y);
      y += 10;
      const descriptionLines = doc.splitTextToSize(property.description, 170);
      doc.text(descriptionLines, 20, y);
      y += descriptionLines.length * 7;

      // Add area details if enabled
      if (options.includeArea) {
        const area = areas.find(a => a.name === property.area);
        if (area) {
          y += 10;
          doc.setFontSize(14);
          doc.text('À propos du quartier', 20, y);
          y += 10;
          doc.setFontSize(12);
          
          const areaLines = doc.splitTextToSize(area.description, 170);
          doc.text(areaLines, 20, y);
          y += areaLines.length * 7;
          
          // Add distances in a grid
          y += 10;
          doc.setFillColor(249, 250, 251); // Light gray background
          doc.rect(20, y, 170, 50, 'F');
          
          doc.setFontSize(10);
          doc.text('Dubai Mall', 30, y + 15);
          doc.text('Dubai Marina', 90, y + 15);
          doc.text('DXB Airport', 150, y + 15);
          
          doc.setFontSize(12);
          doc.text(area.distances.dubaiMall, 30, y + 35);
          doc.text(area.distances.dubaiMarina, 90, y + 35);
          doc.text(area.distances.dxbAirport, 150, y + 35);
          
          y += 60;
        }
      }

      // Add developer details if enabled
      if (options.includeDeveloper) {
        const developer = developers.find(d => d.name === property.developer);
        if (developer) {
          y += 10;
          doc.setFontSize(14);
          doc.text('À propos du promoteur', 20, y);
          y += 10;
          doc.setFontSize(12);
          
          const devLines = doc.splitTextToSize(developer.description, 170);
          doc.text(devLines, 20, y);
          y += devLines.length * 7;
          
          if (developer.projects?.length) {
            y += 10;
            doc.text('Projets réalisés :', 20, y);
            y += 10;
            developer.projects.forEach(project => {
              doc.circle(25, y, 2, 'F');
              doc.text(project, 35, y);
              y += 7;
            });
          }
        }
      }


    // Payment plan
    if (options.includePaymentPlan && property.paymentPlan) {
      y += 10;
      doc.text('Plan de paiement :', 20, y);
      y += 10;
      
      const paymentPlanData = [
        ['Échéance', 'Pourcentage', 'Description'],
        ['Acompte', `${property.paymentPlan.downPayment}%`, ''],
        ...property.paymentPlan.installments.map(inst => [
          inst.dueDate,
          `${inst.percentage}%`,
          inst.description
        ])
      ];

      autoTable(doc, {
        startY: y,
        head: [paymentPlanData[0]],
        body: paymentPlanData.slice(1),
        theme: 'striped',
        styles: { fontSize: 10 },
        headStyles: { fillColor: [59, 130, 246] }
      });
      
      y = doc.lastAutoTable.finalY + 10;
    }
      if (options.includePrice && property.price) {
        doc.text(`Prix : ${formatCurrency(property.price)}`, 20, y);
        doc.text(`(${formatEuro(property.price)})`, 100, y);
        y += 10;
      }
    });

  // Add returns page
  if (templates.returns) {
    doc.addPage();
    doc.fromHTML(templates.returns.template, 20, 20, {
      width: 170
    });
  }

  // Add agency presentation
  if (templates.agency) {
    doc.addPage();
    doc.fromHTML(templates.agency.template, 20, 20, {
      width: 170
    });
  }

  // Add closing page
  if (templates.closing) {
    doc.addPage();
    doc.fromHTML(templates.closing.template, 20, 20, {
      width: 170
    });
  }

    // Add footer on each page
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8);
      doc.text(
        `Généré le ${formatDateDisplay(new Date().toISOString())}`,
        20,
        pageHeight - 20
      );
      doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });
      doc.text(`Page ${i} sur ${pageCount}`, 190, pageHeight - 20, { align: 'right' });
    }

    return doc;
  } catch (error) {
    console.error('Error generating custom offer:', error);
    throw new Error('Erreur lors de la génération de l\'offre personnalisée');
  }
}

async function getSettings() {
  // Get settings from Firestore
  const settingsRef = doc(db, 'settings', 'general');
  const settingsDoc = await getDoc(settingsRef);
  return settingsDoc.data();
}