import React from 'react';
import { DollarSign, Clock, Gift, TrendingUp } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { formatCurrency, formatEuro } from '../../utils/format';
import StatCard from '../ui/StatCard';
import BlurredNumber from '../ui/BlurredNumber';

export default function KPICards() {
  const { sales, calculateCommission } = useSales();
  
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const startDate = new Date(2024, 6); // July 2024
  const monthsSinceStart = Math.max(
    1,
    (currentYear - 2024) * 12 + (currentMonth - 6) + 1
  );

  const kpis = sales.reduce(
    (acc, sale) => {
      const commission = calculateCommission(sale);
      const saleDate = new Date(sale.createdAt);
      const isCurrentMonth =
        saleDate.getMonth() === currentMonth &&
        saleDate.getFullYear() === currentYear;

      acc.totalAgencyCommission += commission.agency;
      if (!sale.paymentStatus.developer) {
        acc.pendingCommission += commission.agency;
      }

      if (isCurrentMonth) {
        acc.monthlyVolume += sale.propertyPrice;
        acc.monthlyCommission += commission.agency;
        acc.monthlySales += 1;
      }

      if (saleDate >= startDate) {
        acc.totalSalesCount += 1;
      }

      if (sale.bonus) {
        acc.totalBonus += commission.bonus.agency;
        if (!sale.paymentStatus.bonus) {
          acc.pendingBonus += commission.bonus.agency;
        }
      }

      return acc;
    },
    {
      totalAgencyCommission: 0,
      pendingCommission: 0,
      monthlyVolume: 0,
      monthlyCommission: 0,
      monthlySales: 0,
      totalSalesCount: 0,
      totalBonus: 0,
      pendingBonus: 0,
    }
  );

  const averageMonthlySales = Math.round(kpis.totalSalesCount / monthsSinceStart);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      <StatCard
        title="Commission perçue"
        value={<BlurredNumber value={formatCurrency(kpis.totalAgencyCommission)} />}
        euroValue={<BlurredNumber value={formatEuro(kpis.totalAgencyCommission)} />}
        subtitle="Depuis juillet 2024"
        icon={DollarSign}
        color="primary"
        delay={0}
      />

      <StatCard
        title="Commission en attente"
        value={<BlurredNumber value={formatCurrency(kpis.pendingCommission)} />}
        euroValue={<BlurredNumber value={formatEuro(kpis.pendingCommission)} />}
        subtitle="À recevoir"
        icon={Clock}
        color="warning"
        delay={0.1}
      />

      <StatCard
        title="Bonus total"
        value={<BlurredNumber value={formatCurrency(kpis.totalBonus)} />}
        euroValue={<BlurredNumber value={formatEuro(kpis.totalBonus)} />}
        subtitle="Depuis juillet 2024"
        icon={Gift}
        color="success"
        delay={0.2}
      />

      <StatCard
        title="Moyenne mensuelle"
        value={`${averageMonthlySales} ventes`}
        subtitle="Depuis juillet 2024"
        icon={TrendingUp}
        color="info"
        delay={0.3}
      />
    </div>
  );
}