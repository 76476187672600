import React, { useState } from 'react';
import { Settings, Target, FileText } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { useSettings } from '../../hooks/useSettings';
import { useToast } from '../../contexts/ToastContext';
import { createPortal } from 'react-dom';

interface AdminGoalsButtonProps {
  agentId: string;
  quarterlyGoal?: {
    salesTarget: number;
    conventionSigned: boolean;
    bonusDescription?: string;
  };
}

export default function AdminGoalsButton({ agentId, quarterlyGoal }: AdminGoalsButtonProps) {
  const { user } = useAuth();
  const { settings, updateSettings } = useSettings();
  const { showToast } = useToast();
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    salesTarget: quarterlyGoal?.salesTarget || 3,
    conventionSigned: quarterlyGoal?.conventionSigned || false,
    bonusDescription: quarterlyGoal?.bonusDescription || ''
  });

  if (user?.role !== 'admin') {
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const currentDate = new Date();
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 3);

    try {
      // Create the quarterly goal object with all required fields
      const updatedGoal = {
        agentId,
        salesTarget: formData.salesTarget,
        conventionSigned: formData.conventionSigned,
        bonusDescription: formData.bonusDescription,
        startDate: currentDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        currentSales: quarterlyGoal?.currentSales || 0,
        currentLeads: quarterlyGoal?.currentLeads || 0,
        leadsTarget: 300,
        leadCost: 25,
        status: 'active'
      };

      // Update settings with the new quarterly goals
      await updateSettings({
        quarterlyGoals: {
          ...settings?.quarterlyGoals,
          [agentId]: updatedGoal
        }
      });

      showToast('success', 'Objectifs mis à jour avec succès');
      setShowPopup(false);
    } catch (error) {
      console.error('Error updating goals:', error);
      showToast('error', 'Erreur lors de la mise à jour des objectifs');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPopup = () => {
    if (!showPopup) return null;

    return createPortal(
      <div className="fixed inset-0 flex items-center justify-center z-[9999]">
        <div 
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={() => setShowPopup(false)}
        />
        <div className="relative bg-white rounded-xl w-full max-w-lg m-4">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Target className="w-6 h-6 text-purple-600" />
                <h2 className="text-xl font-semibold">Modifier les objectifs</h2>
              </div>
              <button
                onClick={() => setShowPopup(false)}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Objectif de ventes (3 mois)
              </label>
              <input
                type="number"
                min="1"
                value={formData.salesTarget}
                onChange={(e) => setFormData({ ...formData, salesTarget: parseInt(e.target.value) })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description du bonus
              </label>
              <textarea
                value={formData.bonusDescription}
                onChange={(e) => setFormData({ ...formData, bonusDescription: e.target.value })}
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                placeholder="Ex: iPhone 16 Pro Max"
              />
            </div>

            <div className="flex items-center gap-3 p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <FileText className="w-5 h-5 text-blue-600" />
              <div className="flex-1">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={formData.conventionSigned}
                    onChange={(e) => setFormData({ ...formData, conventionSigned: e.target.checked })}
                    className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm font-medium text-gray-700">Convention signée</span>
                </label>
              </div>
            </div>

            <div className="flex justify-end gap-3 pt-6">
              <button
                type="button"
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                disabled={isSubmitting}
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>,
      document.body
    );
  };

  return (
    <>
      <button
        onClick={() => setShowPopup(true)}
        className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center gap-2"
      >
        <Settings className="w-4 h-4" />
        Modifier les objectifs
      </button>

      {renderPopup()}
    </>
  );
}