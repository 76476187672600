import React from 'react';
import { Database, RefreshCw } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Agent } from '../../types';

interface DatabaseTabProps {
  handleVerifyDatabase: () => void;
  isVerifying: boolean;
  dbStats: {
    collections: Record<string, { count: number; size: number }>;
    totalSize: number;
    lastBackup?: string;
  } | null;
  showAgentIds: boolean;
  setShowAgentIds: (show: boolean) => void;
  agents: Agent[];
}

export default function DatabaseTab({
  handleVerifyDatabase,
  isVerifying,
  dbStats,
  showAgentIds,
  setShowAgentIds,
  agents
}: DatabaseTabProps) {
  return (
    <div className="space-y-6">
      <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <Database className="w-5 h-5 text-blue-600" />
            <h3 className="font-medium">État de la base de données</h3>
          </div>
          <button
            type="button"
            onClick={handleVerifyDatabase}
            disabled={isVerifying}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            <RefreshCw className={`w-4 h-4 ${isVerifying ? 'animate-spin' : ''}`} />
            {isVerifying ? 'Vérification...' : 'Vérifier'}
          </button>
        </div>

        {dbStats && (
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {Object.entries(dbStats.collections).map(([name, stats]) => (
                <div key={name} className="p-4 bg-white rounded-lg border border-gray-200">
                  <h4 className="font-medium mb-2">{name}</h4>
                  <div className="space-y-1 text-sm text-gray-600">
                    <p>Documents : {stats.count}</p>
                    <p>Taille : {(stats.size / 1024).toFixed(2)} KB</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200">
              <div>
                <h4 className="font-medium">Taille totale</h4>
                <p className="text-sm text-gray-600">
                  {(dbStats.totalSize / (1024 * 1024)).toFixed(2)} MB
                </p>
              </div>
              {dbStats.lastBackup && (
                <div className="text-right">
                  <h4 className="font-medium">Dernière sauvegarde</h4>
                  <p className="text-sm text-gray-600">
                    {formatDistanceToNow(new Date(dbStats.lastBackup), {
                      locale: fr,
                      addSuffix: true
                    })}
                  </p>
                </div>
              )}
            </div>

            <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium">Liste des agents et leurs IDs</h3>
                <button
                  onClick={() => setShowAgentIds(!showAgentIds)}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  {showAgentIds ? 'Masquer' : 'Afficher'} les IDs
                </button>
              </div>
              {showAgentIds && (
                <div className="space-y-2">
                  {agents.map(agent => (
                    <div key={agent.id} className="p-2 bg-white rounded-lg border border-gray-200">
                      <p className="font-medium">{agent.name}</p>
                      <p className="text-sm text-gray-500">ID: {agent.id}</p>
                      <p className="text-xs text-gray-400">URL: /agentcrm/{agent.name.toLowerCase().replace(/\s+/g, '-')}-{agent.id}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}