import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, Clock, Calendar, DollarSign, FileText, AlertCircle, Shield, Instagram } from 'lucide-react';

export default function AgentRules() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-red-900 to-red-800 rounded-2xl overflow-hidden relative shadow-xl"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-red-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut",
          delay: 1
        }}
      />

      <div className="relative p-8">
        <div className="flex items-start gap-6">
          {/* Icon */}
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }}
          >
            <div className="p-4 bg-gradient-to-br from-yellow-400 to-yellow-500 rounded-xl shadow-lg">
              <Shield className="w-10 h-10 text-white" />
            </div>
          </motion.div>

          {/* Content */}
          <div className="flex-1">
            <motion.h2
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-2xl font-bold text-white mb-6"
            >
              Règles Importantes à Respecter
            </motion.h2>

            <div className="space-y-4">
              {/* Working Hours */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <Clock className="w-5 h-5 text-yellow-300" />
                  <span className="text-yellow-100 font-medium">Heures de travail</span>
                </div>
                <p className="text-white/90 leading-relaxed">
                  Disponibilité requise de 12h à 22h
                </p>
              </motion.div>

              {/* Lead Response */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <AlertCircle className="w-5 h-5 text-red-300" />
                  <span className="text-red-100 font-medium">Gestion des leads</span>
                </div>
                <ul className="text-white/90 space-y-3 leading-relaxed">
                  <li>• Contacter le lead dans l'heure pour éviter 25 EURO de pénalité</li>
                  <li>• Les leads sont strictement individuels - toute tentative de vol de lead entraînera une suspension immédiate</li>
                  <li>• Sanction pour vol de lead : suspension d'un trimestre de leads et pénalité financière</li>
                </ul>
              </motion.div>

              {/* Availability */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <Calendar className="w-5 h-5 text-green-300" />
                  <span className="text-green-100 font-medium">Disponibilité</span>
                </div>
                <p className="text-white/90 leading-relaxed">
                  Prévenez 24h à l'avance si vous êtes indisponible
                </p>
              </motion.div>

              {/* Commission Rules */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="w-5 h-5 text-yellow-300" />
                  <span className="text-yellow-100 font-medium">Commissions</span>
                </div>
                <ul className="text-white/90 space-y-3 leading-relaxed">
                  <li>• Les paiements sont effectués entre le 24 et 26 de chaque mois</li>
                  <li>• À partir du 1er janvier 2025, la dernière commission sera systématiquement bloquée</li>
                  <li>• Un préavis de 30 jours est requis pour quitter Palm</li>
                </ul>
              </motion.div>

              {/* Quarterly Objectives */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <FileText className="w-5 h-5 text-purple-300" />
                  <span className="text-purple-100 font-medium">Objectifs</span>
                </div>
                <p className="text-white/90 leading-relaxed">
                  Objectifs trimestriels à atteindre selon votre convention individuelle
                </p>
              </motion.div>

              {/* Instagram Requirement */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-colors duration-200"
              >
                <div className="flex items-center gap-2 mb-2">
                  <Instagram className="w-5 h-5 text-pink-300" />
                  <span className="text-pink-100 font-medium">Instagram professionnel obligatoire</span>
                </div>
                <ul className="text-white/90 space-y-3 leading-relaxed">
                  <li>• Création obligatoire d'un compte Instagram professionnel avec mention @palmdubai.fr en BIO</li>
                  <li>• Publication régulière de contenu immobilier de qualité</li>
                  <li>• Le non-respect de cette règle entraînera l'exclusion de Palm Dubai Immo</li>
                </ul>
              </motion.div>
           </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}