import React, { useState } from 'react';
import { FileText, Plus, Save, X, Edit, Trash2, Globe } from 'lucide-react';
import { motion } from 'framer-motion';
import type { Settings } from '../../types';
import { useLanguage } from '../../contexts/LanguageContext';

interface PagesTabProps {
  settings: Settings | null;
  updateSettings: (settings: Partial<Settings>) => Promise<void>;
  showToast: (type: 'success' | 'error', message: string) => void;
}

interface Section {
  id: string;
  title: string;
  content: string;
  order: number;
}

interface CustomPage {
  id: string;
  title: string;
  slug: string;
  content: string;
  sections: Section[];
}

const DEFAULT_PAGES = [
  {
    id: 'why_dubai',
    title: 'Pourquoi Dubai ?',
    slug: 'why-dubai',
    content: `
      <h2>Investir à Dubai : Une opportunité unique</h2>
      <p>Dubai offre des avantages exceptionnels pour les investisseurs immobiliers :</p>
    `,
    sections: [
      {
        id: 'tax_benefits',
        title: 'Avantages fiscaux',
        content: '0% d\'impôts sur les revenus locatifs et la plus-value',
        order: 0
      },
      {
        id: 'roi',
        title: 'Rendements locatifs',
        content: 'Des rendements parmi les plus élevés au monde (6-10% par an)',
        order: 1
      },
      {
        id: 'security',
        title: 'Sécurité',
        content: 'Un marché immobilier réglementé et transparent',
        order: 2
      }
    ]
  },
  {
    id: 'palm_dubai',
    title: 'Palm Dubai Immo',
    slug: 'palm-dubai',
    content: `
      <h2>Votre partenaire de confiance à Dubai</h2>
      <p>Une expertise reconnue dans l'immobilier de luxe à Dubai</p>
    `,
    sections: [
      {
        id: 'experience',
        title: 'Expérience',
        content: 'Plus de 10 ans d\'expérience sur le marché de Dubai',
        order: 0
      },
      {
        id: 'team',
        title: 'Équipe',
        content: 'Une équipe franco-émiratie à votre service',
        order: 1
      },
      {
        id: 'service',
        title: 'Service',
        content: 'Un accompagnement personnalisé de A à Z',
        order: 2
      }
    ]
  }
];

export default function PagesTab({ settings, updateSettings, showToast }: PagesTabProps) {
  const { currentLanguage } = useLanguage();
  const [pages, setPages] = useState<CustomPage[]>(settings?.customPages || DEFAULT_PAGES);
  const [editingPage, setEditingPage] = useState<string | null>(null);
  const [editingSection, setEditingSection] = useState<{pageId: string; sectionId: string} | null>(null);
  const [showNewPageForm, setShowNewPageForm] = useState(false);
  const [newPage, setNewPage] = useState({
    title: '',
    slug: '',
    content: '',
    sections: []
  });

  const handleSave = async () => {
    try {
      await updateSettings({
        ...settings,
        customPages: pages
      });
      showToast('success', 'Pages enregistrées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'enregistrement des pages');
    }
  };

  const handleAddPage = () => {
    const id = `page_${Date.now()}`;
    const newPageData = {
      id,
      ...newPage,
      sections: []
    };
    setPages([...pages, newPageData]);
    setShowNewPageForm(false);
    setNewPage({ title: '', slug: '', content: '', sections: [] });
  };

  const handleAddSection = (pageId: string) => {
    const updatedPages = pages.map(page => {
      if (page.id === pageId) {
        return {
          ...page,
          sections: [
            ...page.sections,
            {
              id: `section_${Date.now()}`,
              title: 'Nouvelle section',
              content: '',
              order: page.sections.length
            }
          ]
        };
      }
      return page;
    });
    setPages(updatedPages);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <FileText className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Pages personnalisées</h2>
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => setShowNewPageForm(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Nouvelle page
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2"
          >
            <Save className="w-4 h-4" />
            Enregistrer
          </button>
        </div>
      </div>

      {/* Pages List */}
      <div className="space-y-6">
        {pages.map(page => (
          <motion.div
            key={page.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl border border-gray-200 overflow-hidden"
          >
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium">{page.title}</h3>
                  <p className="text-sm text-gray-500">/{page.slug}</p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setEditingPage(page.id)}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm('Êtes-vous sûr de vouloir supprimer cette page ?')) {
                        setPages(pages.filter(p => p.id !== page.id));
                      }
                    }}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>

            {/* Sections */}
            <div className="p-6 space-y-4">
              <div className="flex items-center justify-between">
                <h4 className="font-medium">Sections</h4>
                <button
                  onClick={() => handleAddSection(page.id)}
                  className="px-3 py-1.5 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
                >
                  Ajouter une section
                </button>
              </div>

              <div className="space-y-4">
                {page.sections.map(section => (
                  <div
                    key={section.id}
                    className="p-4 bg-gray-50 rounded-lg border border-gray-200"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h5 className="font-medium">{section.title}</h5>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setEditingSection({ pageId: page.id, sectionId: section.id })}
                          className="p-1.5 text-gray-600 hover:bg-gray-200 rounded-lg"
                        >
                          <Edit className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => {
                            const updatedPages = pages.map(p => {
                              if (p.id === page.id) {
                                return {
                                  ...p,
                                  sections: p.sections.filter(s => s.id !== section.id)
                                };
                              }
                              return p;
                            });
                            setPages(updatedPages);
                          }}
                          className="p-1.5 text-red-600 hover:bg-red-50 rounded-lg"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600">
                      {section.content.substring(0, 100)}
                      {section.content.length > 100 && '...'}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* New Page Modal */}
      {showNewPageForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-2xl p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-medium">Nouvelle page</h3>
              <button
                onClick={() => setShowNewPageForm(false)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Titre
                </label>
                <input
                  type="text"
                  value={newPage.title}
                  onChange={(e) => setNewPage({ ...newPage, title: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Slug
                </label>
                <input
                  type="text"
                  value={newPage.slug}
                  onChange={(e) => setNewPage({ ...newPage, slug: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contenu
                </label>
                <textarea
                  value={newPage.content}
                  onChange={(e) => setNewPage({ ...newPage, content: e.target.value })}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowNewPageForm(false)}
                  className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                >
                  Annuler
                </button>
                <button
                  onClick={handleAddPage}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Section Modal */}
      {editingSection && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-2xl p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-medium">Modifier la section</h3>
              <button
                onClick={() => setEditingSection(null)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Titre
                </label>
                <input
                  type="text"
                  value={pages
                    .find(p => p.id === editingSection.pageId)
                    ?.sections.find(s => s.id === editingSection.sectionId)
                    ?.title || ''}
                  onChange={(e) => {
                    const updatedPages = pages.map(page => {
                      if (page.id === editingSection.pageId) {
                        return {
                          ...page,
                          sections: page.sections.map(section => {
                            if (section.id === editingSection.sectionId) {
                              return { ...section, title: e.target.value };
                            }
                            return section;
                          })
                        };
                      }
                      return page;
                    });
                    setPages(updatedPages);
                  }}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contenu
                </label>
                <textarea
                  value={pages
                    .find(p => p.id === editingSection.pageId)
                    ?.sections.find(s => s.id === editingSection.sectionId)
                    ?.content || ''}
                  onChange={(e) => {
                    const updatedPages = pages.map(page => {
                      if (page.id === editingSection.pageId) {
                        return {
                          ...page,
                          sections: page.sections.map(section => {
                            if (section.id === editingSection.sectionId) {
                              return { ...section, content: e.target.value };
                            }
                            return section;
                          })
                        };
                      }
                      return page;
                    });
                    setPages(updatedPages);
                  }}
                  rows={6}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setEditingSection(null)}
                  className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}