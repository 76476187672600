import React from 'react';
import { DollarSign, MessageSquare, Check, Clock, ChevronRight, Building, Users } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatCurrency } from '../../utils/format';
import type { Sale } from '../../types';
import BlurredNumber from '../ui/BlurredNumber';

interface PaymentStatusCardProps {
  sale: Sale;
  commission: {
    agent: number;
    agency: number;
    referrer?: number;
    bonus: {
      agent: number;
      agency: number;
      referrer: number;
    };
  };
}

export default function PaymentStatusCard({ sale, commission }: PaymentStatusCardProps) {
  const handleWhatsAppClick = () => {
    const message = `Bonjour,\n\nJe souhaite avoir un statut sur ma commission pour la vente suivante :\n\nProjet : ${sale.project}\nUnité : ${sale.unitNumber}\nCommission : ${formatCurrency(commission.agent)}\n\nMerci !`;
    window.open(`https://wa.me/971581998661?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-emerald-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <DollarSign className="w-6 h-6 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">Statut des paiements</h3>
              <p className="text-gray-400 mt-1">État des commissions</p>
            </div>
          </div>
        </div>

        {/* Status Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          {/* Commission Promoteur */}
          <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
            <div className="flex items-center gap-2 mb-3">
              <Building className="w-5 h-5 text-blue-400" />
              <h4 className="font-medium text-white">Commission promoteur</h4>
            </div>
            <p className="text-2xl font-bold text-white mb-2">
              <BlurredNumber value={formatCurrency(sale.developerCommission)} />
            </p>
            <div className={`flex items-center gap-2 px-3 py-2 rounded-lg ${
              sale.paymentStatus.developer
                ? 'bg-green-500/20 text-green-300'
                : 'bg-amber-500/20 text-amber-300'
            }`}>
              {sale.paymentStatus.developer ? (
                <>
                  <Check className="w-4 h-4" />
                  <span>Reçue</span>
                </>
              ) : (
                <>
                  <Clock className="w-4 h-4" />
                  <span>En attente</span>
                </>
              )}
            </div>
          </div>

          {/* Commission Agent */}
          <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
            <div className="flex items-center gap-2 mb-3">
              <Users className="w-5 h-5 text-green-400" />
              <h4 className="font-medium text-white">Votre commission</h4>
            </div>
            <p className="text-2xl font-bold text-white mb-2">
              <BlurredNumber value={formatCurrency(commission.agent)} />
            </p>
            <div className={`flex items-center gap-2 px-3 py-2 rounded-lg ${
              sale.paymentStatus.agent
                ? 'bg-green-500/20 text-green-300'
                : 'bg-amber-500/20 text-amber-300'
            }`}>
              {sale.paymentStatus.agent ? (
                <>
                  <Check className="w-4 h-4" />
                  <span>Payée</span>
                </>
              ) : (
                <>
                  <Clock className="w-4 h-4" />
                  <span>En attente</span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* WhatsApp Button */}
        <button
          onClick={handleWhatsAppClick}
          className="w-full flex items-center justify-center gap-3 px-6 py-4 bg-[#25D366] hover:bg-[#128C7E] text-white rounded-xl transition-all duration-200 group"
        >
          <MessageSquare className="w-5 h-5" />
          <span className="font-medium">Demander le statut</span>
          <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
        </button>
      </div>
    </motion.div>
  );
}