import React from 'react';
import { motion } from 'framer-motion';
import { Building, DollarSign, Shield, TrendingUp, MapPin, Phone, Mail, Globe, Gift, Video, Users, ChevronRight, PalmtreeIcon, Star, Briefcase, GraduationCap, FileText, Check, Laptop, MessageSquare, Image } from 'lucide-react';
import StoryPreview from '../components/marketing/StoryPreview';

export default function JoinPalm() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="relative min-h-[100svh]">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=2000&h=1200&q=80)'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
        </div>

        {/* Content */}
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center min-h-[100svh] py-20">
          {/* Logo */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8 sm:mb-12"
          >
            <div className="flex items-center gap-4">
              <div className="p-4 bg-white/10 backdrop-blur-sm rounded-2xl">
                <PalmtreeIcon className="w-12 h-12 text-white" />
              </div>
              <h1 className="text-3xl sm:text-4xl font-bold text-white">Palm Dubai Immo</h1>
            </div>
          </motion.div>

          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-4xl sm:text-6xl lg:text-7xl font-bold text-white mb-6 sm:mb-8"
          >
            Travailler depuis<br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Dubai ou de chez vous
            </span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-xl sm:text-2xl text-gray-200 mb-12 max-w-2xl"
          >
            Rejoignez Palm Dubai Immo et développez votre carrière dans l'immobilier de luxe à Dubai, en télétravail ou sur place.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex flex-col sm:flex-row gap-4 sm:gap-6 w-full sm:w-auto"
          >
            <a
              href={`https://wa.me/971581998661?text=${encodeURIComponent('Je souhaite rejoindre Palm Dubai Immo')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl hover:from-blue-700 hover:to-blue-600 transition-all duration-200 group shadow-lg shadow-blue-500/25 w-full sm:w-auto"
            >
              <span className="text-lg font-medium">Démarrer Maintenant</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </a>
            <a
              href="mailto:hello@palmdubai.fr?subject=Candidature%20Palm%20Dubai%20Immo&body=Bonjour%2C%0A%0AJe%20souhaite%20rejoindre%20Palm%20Dubai%20Immo.%0A%0AMes%20coordonnées%20%3A%0A-%20Nom%20%3A%0A-%20Téléphone%20%3A%0A-%20Pays%20de%20résidence%20%3A%0A-%20Expérience%20en%20immobilier%20%3A%0A%0AJe%20suis%20disponible%20pour%20un%20entretien%20à%20votre%20convenance.%0A%0ACordialement"
              className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-white/10 backdrop-blur-sm text-white rounded-xl hover:bg-white/20 transition-all duration-200 group border border-white/20 w-full sm:w-auto"
            >
              <Mail className="w-5 h-5" />
              <span className="text-lg font-medium">Postuler par Email</span>
            </a>
          </motion.div>
        </div>
      </div>

      {/* Rest of the code remains the same... */}
      {/* (entire previous file content after the first section) */}
    </div>
  );
}