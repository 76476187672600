import React from 'react';
import { motion } from 'framer-motion';
import { Building, DollarSign, Shield, TrendingUp, MapPin, Phone, Mail, Globe, Gift, Video, Users, ChevronRight, PalmtreeIcon, Star, Briefcase, GraduationCap, FileText, Check, Laptop, MessageSquare, Image } from 'lucide-react';
import StoryPreview from '../components/marketing/StoryPreview';

export default function JoinPalm() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="relative min-h-[100svh]">
        {/* Background Image */}
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=2000&h=1200&q=80)'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
        </div>

        {/* Content */}
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center min-h-[100svh] py-20">
          {/* Logo */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8 sm:mb-12"
          >
            <div className="flex items-center gap-4">
              <div className="p-4 bg-white/10 backdrop-blur-sm rounded-2xl">
                <PalmtreeIcon className="w-12 h-12 text-white" />
              </div>
              <h1 className="text-3xl sm:text-4xl font-bold text-white">Palm Dubai Immo</h1>
            </div>
          </motion.div>

          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-4xl sm:text-6xl lg:text-7xl font-bold text-white mb-6 sm:mb-8"
          >
            Travailler depuis<br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Dubai ou de chez vous
            </span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-xl sm:text-2xl text-gray-200 mb-12 max-w-2xl"
          >
            Rejoignez Palm Dubai Immo et développez votre carrière dans l'immobilier de luxe à Dubai, en télétravail ou sur place.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex flex-col sm:flex-row gap-4 sm:gap-6 w-full sm:w-auto"
          >
            <a
              href={`https://wa.me/971581998661?text=${encodeURIComponent('Je souhaite rejoindre Palm Dubai Immo')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl hover:from-blue-700 hover:to-blue-600 transition-all duration-200 group shadow-lg shadow-blue-500/25 w-full sm:w-auto"
            >
              <span className="text-lg font-medium">Démarrer Maintenant</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </a>
            <a
              href="mailto:hello@palmdubai.fr?subject=Candidature%20Palm%20Dubai%20Immo&body=Bonjour%2C%0A%0AJe%20souhaite%20rejoindre%20Palm%20Dubai%20Immo.%0A%0AMes%20coordonnées%20%3A%0A-%20Nom%20%3A%0A-%20Téléphone%20%3A%0A-%20Pays%20de%20résidence%20%3A%0A-%20Expérience%20en%20immobilier%20%3A%0A%0AJe%20suis%20disponible%20pour%20un%20entretien%20à%20votre%20convenance.%0A%0ACordialement"
              className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-white/10 backdrop-blur-sm text-white rounded-xl hover:bg-white/20 transition-all duration-200 group border border-white/20 w-full sm:w-auto"
            >
              <Mail className="w-5 h-5" />
              <span className="text-lg font-medium">Postuler par Email</span>
            </a>
          </motion.div>
        </div>
      </div>

      {/* Key Features Section */}
      <div className="py-16 sm:py-24 bg-gradient-to-br from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Story Preview */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mb-16 flex flex-col items-center"
          >
            <div className="flex items-center gap-3 mb-8">
              <Image className="w-6 h-6 text-blue-600" />
              <h3 className="text-xl font-semibold">Avantages de travailler avec Palm Dubai Immo</h3>
            </div>
            <StoryPreview mode="recruitment" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12 sm:mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">Pourquoi nous rejoindre ?</h2>
            <p className="text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto">
              Que vous soyez en France ou en Belgique, Palm Dubai Immo vous offre une opportunité unique de réussir dans l'immobilier de luxe à Dubai.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            {/* Remote Work */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-2xl p-8 border border-blue-200/50"
            >
              <div className="p-4 bg-blue-100 rounded-xl w-fit mb-6">
                <Briefcase className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Travail à Distance</h3>
              <p className="text-gray-600 mb-4">
                Gérez vos clients et vos ventes depuis la France ou la Belgique grâce à notre système de leads qualifiés.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <Shield className="w-4 h-4 text-blue-600" />
                  <span>Flexibilité totale</span>
                </li>
                <li className="flex items-center gap-2">
                  <TrendingUp className="w-4 h-4 text-blue-600" />
                  <span>Support 24/7</span>
                </li>
              </ul>
            </motion.div>

            {/* Earnings */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-gradient-to-br from-green-50 to-green-100/50 rounded-2xl p-8 border border-green-200/50"
            >
              <div className="p-4 bg-green-100 rounded-xl w-fit mb-6">
                <DollarSign className="w-8 h-8 text-green-600" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Revenus Exceptionnels</h3>
              <p className="text-gray-600 mb-4">
                Gagnez entre 10.000€ et 50.000€ par mois grâce à nos leads qualifiés et nos commissions attractives.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <Star className="w-4 h-4 text-green-600" />
                  <span>60% de commission</span>
                </li>
                <li className="flex items-center gap-2">
                  <Gift className="w-4 h-4 text-green-600" />
                  <span>Bonus mensuels</span>
                </li>
              </ul>
            </motion.div>

            {/* Training */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-2xl p-8 border border-purple-200/50"
            >
              <div className="p-4 bg-purple-100 rounded-xl w-fit mb-6">
                <GraduationCap className="w-8 h-8 text-purple-600" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Formation Continue</h3>
              <p className="text-gray-600 mb-4">
                Bénéficiez d'une formation complète et d'un accompagnement personnalisé pour réussir.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <Video className="w-4 h-4 text-purple-600" />
                  <span>Formations en ligne</span>
                </li>
                <li className="flex items-center gap-2">
                  <Users className="w-4 h-4 text-purple-600" />
                  <span>Mentorat individuel</span>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Remote Work Section */}
      <div className="py-16 sm:py-24 bg-gradient-to-br from-emerald-900 to-teal-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12 sm:mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
              Travaillez depuis la France ou la Belgique
            </h2>
            <p className="text-lg sm:text-xl text-blue-200 max-w-3xl mx-auto">
              Gérez votre activité à distance et profitez d'une flexibilité totale tout en bénéficiant de notre support complet.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Briefcase className="w-8 h-8 text-blue-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Visa Résident</h3>
              <p className="text-gray-300 mb-4">
                Obtenez votre visa de résident à Dubai grâce à notre partenariat avec les autorités locales.
              </p>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Visa de 2 ans renouvelable</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Processus simplifié</span>
                </li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <FileText className="w-8 h-8 text-purple-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Contrat de Travail</h3>
              <p className="text-gray-300 mb-4">
                Bénéficiez d'un contrat de travail officiel avec tous les avantages associés.
              </p>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Statut légal complet</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Protection sociale</span>
                </li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Laptop className="w-8 h-8 text-yellow-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Travail à Distance</h3>
              <p className="text-gray-300 mb-4">
                Gérez vos ventes et vos clients depuis votre pays tout en bénéficiant de notre support.
              </p>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Outils digitaux avancés</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-400" />
                  <span>Support 24/7</span>
                </li>
              </ul>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="mt-12 text-center"
          >
            <a
              href={`https://wa.me/971581998661?text=${encodeURIComponent('Bonjour, je suis intéressé(e) pour travailler avec Palm Dubai Immo depuis la France/Belgique. Pouvez-vous me donner plus d\'informations ?')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-3 px-8 py-4 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-xl hover:from-green-600 hover:to-green-700 transition-all duration-200 group shadow-lg shadow-green-500/25"
            >
              <MessageSquare className="w-6 h-6" />
              <span className="text-lg font-medium">Postuler sur WhatsApp</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </a>
            <p className="mt-4 text-blue-200">
              ou par email à{' '}
              <a href="mailto:hello@palmdubai.fr" className="text-white hover:text-blue-200 transition-colors">
                hello@palmdubai.fr
              </a>
            </p>
          </motion.div>
        </div>
      </div>

      {/* Referral Program Section */}
      <div className="py-24 bg-gradient-to-br from-purple-900 to-indigo-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-6">Développez Votre Équipe</h2>
            <p className="text-xl text-blue-200 max-w-3xl mx-auto">
              Notre programme de parrainage vous permet de construire votre équipe et de générer des revenus passifs.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Gift className="w-8 h-8 text-yellow-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Premier Bonus</h3>
              <p className="text-gray-300">
                Recevez 20% de commission sur la première vente de chaque agent que vous parrainez.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <TrendingUp className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Revenus Passifs</h3>
              <p className="text-gray-300">
                Continuez à recevoir 10% sur toutes les ventes futures de vos filleuls, à vie !
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Users className="w-8 h-8 text-purple-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Équipe Illimitée</h3>
              <p className="text-gray-300">
                Aucune limite sur le nombre d'agents que vous pouvez parrainer et sur vos gains.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Visa & Work Contract Section */}
      <div className="py-24 bg-gradient-to-br from-amber-800 to-orange-700 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-6">Visa & Installation à Dubai</h2>
            <p className="text-xl text-amber-200 max-w-3xl mx-auto">
              Palm Dubai Immo vous accompagne dans toutes les démarches pour votre installation à Dubai.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-gradient-to-br from-amber-700/50 to-amber-600/30 backdrop-blur-sm rounded-2xl p-8 border border-amber-500/20 hover:border-amber-400/30 transition-all duration-300"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Shield className="w-8 h-8 text-yellow-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Visa de Résidence</h3>
              <p className="text-gray-300">
                Obtenez votre visa de résidence aux Émirats Arabes Unis grâce à votre contrat de travail avec Palm Dubai Immo.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-gradient-to-br from-amber-700/50 to-amber-600/30 backdrop-blur-sm rounded-2xl p-8 border border-amber-500/20 hover:border-amber-400/30 transition-all duration-300"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <FileText className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Contrat de Travail</h3>
              <p className="text-gray-300">
                Contrat officiel avec Palm Dubai Immo, vous permettant d'obtenir votre visa de résidence et de travailler légalement à Dubai.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-gradient-to-br from-amber-700/50 to-amber-600/30 backdrop-blur-sm rounded-2xl p-8 border border-amber-500/20 hover:border-amber-400/30 transition-all duration-300"
            >
              <div className="p-4 bg-white/10 rounded-xl w-fit mb-6">
                <Building className="w-8 h-8 text-purple-400" />
              </div>
              <h3 className="text-2xl font-bold mb-4">Installation</h3>
              <p className="text-gray-300">
                Assistance complète pour votre installation : logement, banque, assurance et toutes les démarches administratives.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gradient-to-br from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl sm:rounded-3xl overflow-hidden">
            <div className="p-8 sm:p-12 md:p-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="flex flex-col items-center text-center"
              >
                <h2 className="text-4xl font-bold text-white mb-6">
                  Prêt à commencer votre succès ?
                </h2>
                <p className="text-lg sm:text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Rejoignez Palm Dubai Immo aujourd'hui et transformez votre carrière dans l'immobilier de luxe, où que vous soyez.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                  <a
                    href={`https://wa.me/971581998661?text=${encodeURIComponent('Je souhaite rejoindre Palm Dubai Immo')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-gradient-to-r from-green-600 to-green-500 text-white rounded-xl hover:from-green-700 hover:to-green-600 transition-all duration-200 group shadow-lg shadow-green-500/25 w-full sm:w-auto"
                  >
                    <Phone className="w-5 h-5" />
                    <span className="text-lg font-medium">Nous contacter</span>
                    <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
                  </a>
                  <a
                    href="mailto:hello@palmdubai.fr?subject=Candidature%20Palm%20Dubai%20Immo&body=Bonjour%2C%0A%0AJe%20souhaite%20rejoindre%20Palm%20Dubai%20Immo.%0A%0AMes%20coordonnées%20%3A%0A-%20Nom%20%3A%0A-%20Téléphone%20%3A%0A-%20Pays%20de%20résidence%20%3A%0A-%20Expérience%20en%20immobilier%20%3A%0A%0AJe%20suis%20disponible%20pour%20un%20entretien%20à%20votre%20convenance.%0A%0ACordialement"
                    className="flex items-center justify-center gap-3 px-6 sm:px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl hover:from-blue-700 hover:to-blue-600 transition-all duration-200 group shadow-lg shadow-blue-500/25 w-full sm:w-auto"
                  >
                    <Mail className="w-5 h-5" />
                    <span className="text-lg font-medium">Postuler par Email</span>
                    <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-br from-gray-900 to-gray-800 py-12 sm:py-24 text-white relative overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <motion.div
            className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-full blur-3xl"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          <motion.div
            className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-br from-purple-500/10 to-blue-500/10 rounded-full blur-3xl"
            animate={{
              scale: [1.2, 1, 1.2],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center gap-8 sm:gap-12">
            {/* Logo */}
            <div className="p-3 sm:p-4 bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-sm rounded-xl sm:rounded-2xl border border-white/20 hover:border-white/30 transition-all duration-300">
              <img 
                src="https://palmdubai.fr/templates/Default/img/logo_black.png"
                alt="Palm Dubai Logo"
                className="h-8 sm:h-10 w-auto invert"
              />
            </div>

            {/* Contact Info */}
            <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-8 w-full sm:w-auto">
              <a
                href="tel:+971581998661"
                className="flex items-center justify-center gap-3 px-4 sm:px-6 py-3 bg-white/10 backdrop-blur-sm rounded-lg sm:rounded-xl border border-white/20 hover:bg-white/20 hover:border-white/30 transition-all duration-300 group w-full sm:w-auto text-sm sm:text-base"
              >
                <Phone className="w-5 h-5" />
                <span className="text-lg">+971 58 199 8661</span>
              </a>
              
              <a
                href="mailto:hello@palmdubai.fr"
                className="flex items-center justify-center gap-3 px-4 sm:px-6 py-3 bg-white/10 backdrop-blur-sm rounded-lg sm:rounded-xl border border-white/20 hover:bg-white/20 hover:border-white/30 transition-all duration-300 group w-full sm:w-auto text-sm sm:text-base"
              >
                <Mail className="w-5 h-5" />
                <span className="text-lg">hello@palmdubai.fr</span>
              </a>
            </div>

            {/* Site Button */}
            <a
              href="https://palmdubai.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-3 px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-blue-600/90 to-blue-700/90 backdrop-blur-sm text-white rounded-lg sm:rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-300 group shadow-lg shadow-blue-500/20 border border-blue-500/30 hover:border-blue-400/50 w-full sm:w-auto text-sm sm:text-base"
            >
              <Globe className="w-5 h-5" />
              <span className="text-lg font-medium">Voir le site</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}