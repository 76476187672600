import { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Developer } from '../types';

export function useDevelopers() {
  const [developers, setDevelopers] = useState<Developer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'developers'),
      (snapshot) => {
        const developerData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Developer[];
        setDevelopers(developerData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching developers:', err);
        setError('Failed to fetch developers');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const addDeveloper = async (developerData: Omit<Developer, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'developers'), {
        ...developerData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding developer:', error);
      throw new Error('Failed to add developer');
    }
  };

  const updateDeveloper = async (id: string, updates: Partial<Developer>) => {
    try {
      const developerRef = doc(db, 'developers', id);
      await updateDoc(developerRef, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error updating developer:', error);
      throw new Error('Failed to update developer');
    }
  };

  const deleteDeveloper = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'developers', id));
    } catch (error) {
      console.error('Error deleting developer:', error);
      throw new Error('Failed to delete developer');
    }
  };

  return {
    developers,
    loading,
    error,
    addDeveloper,
    updateDeveloper,
    deleteDeveloper,
  };
}