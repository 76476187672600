import React from 'react';
import { Key } from 'lucide-react';

interface GeneralTabProps {
  formData: {
    name: string;
    email: string;
  };
  setFormData: (data: any) => void;
  handleResetPasswords: () => void;
  isResettingPasswords: boolean;
}

export default function GeneralTab({
  formData,
  setFormData,
  handleResetPasswords,
  isResettingPasswords
}: GeneralTabProps) {
  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Nom de l'application
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email de contact
        </label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="pt-6 border-t border-gray-200">
        <div className="p-4 bg-red-50 rounded-xl border border-red-200">
          <p className="text-sm text-red-700 mb-4">
            Cette action réinitialisera les mots de passe de tous les agents à : LovePalmImmo
          </p>
          <button
            type="button"
            onClick={handleResetPasswords}
            disabled={isResettingPasswords}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            <Key className="w-4 h-4" />
            {isResettingPasswords ? 'Réinitialisation...' : 'Réinitialiser tous les mots de passe'}
          </button>
        </div>
      </div>
    </div>
  );
}