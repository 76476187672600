import React, { useState } from 'react';
import { MapPin, Wand2, Search, X } from 'lucide-react';
import type { Area } from '../../types';
import { generateAreaInfo } from '../../services/openaiService';
import { useToast } from '../../contexts/ToastContext';
import { useAreas } from '../../hooks/useAreas';
import { motion } from 'framer-motion';

interface AreaFormProps {
  onSubmit: (area: Omit<Area, 'id'>) => void;
  onClose: () => void;
}

export default function AreaForm({ onSubmit, onClose }: AreaFormProps) {
  const { showToast } = useToast();
  const { areas } = useAreas();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showAreaSelector, setShowAreaSelector] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [formData, setFormData] = useState<Omit<Area, 'id'>>({
    name: '',
    description: '',
    distances: {
      dubaiMall: '',
      dubaiMarina: '',
      dxbAirport: '',
      airbnbYield: '',
      longTermYield: ''
    },
    pointsOfInterest: [],
    images: []
  });

  const [pointOfInterest, setPointOfInterest] = useState('');

  const filteredAreas = areas.filter(area => {
    if (!searchQuery) return true;
    return area.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleAreaSelect = (area: Area) => {
    setFormData({
      ...area,
      images: area.images || []
    });
    setShowAreaSelector(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      onSubmit(formData);
    } catch (error) {
      showToast('error', 'Erreur lors de l\'ajout du quartier');
    }
  };

  const addPointOfInterest = () => {
    if (pointOfInterest.trim()) {
      setFormData({
        ...formData,
        pointsOfInterest: [...formData.pointsOfInterest, pointOfInterest.trim()]
      });
      setPointOfInterest('');
    }
  };

  const handleGenerateInfo = async () => {
    if (!formData.name) {
      showToast('error', 'Veuillez d\'abord saisir le nom du quartier');
      return;
    }

    setIsGenerating(true);
    try {
      // Fallback content if OpenAI is not available
      setFormData(prev => ({
        ...prev,
        description: `${formData.name} est un quartier dynamique de Dubai offrant un style de vie moderne et des opportunités d'investissement attractives. Idéalement situé, ce quartier bénéficie d'excellentes connexions avec les points d'intérêt majeurs de la ville et propose une large gamme d'équipements et de services.`,
        distances: {
          dubaiMall: '15 minutes en voiture',
          dubaiMarina: '20 minutes en voiture',
          dxbAirport: '25 minutes en voiture',
          airbnbYield: '8-10% par an',
          longTermYield: '6-8% par an'
        },
        pointsOfInterest: [
          'Centres commerciaux et boutiques',
          'Restaurants et cafés',
          'Parcs et espaces verts',
          'Écoles internationales',
          'Centres médicaux'
        ]
      }));
      showToast('success', 'Informations générées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la génération des informations');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-4xl m-4 flex flex-col max-h-[90vh]">
        <div className="p-6 border-b border-gray-200/50 bg-gradient-to-br from-gray-50 to-gray-100/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <MapPin className="w-6 h-6 text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold">Ajouter un quartier</h2>
            </div>
            <button
              type="button"
              onClick={() => setShowAreaSelector(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Sélectionner un quartier existant
            </button>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom du quartier
              </label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="mt-4">
              <button
                type="button"
                onClick={handleGenerateInfo}
                disabled={isGenerating || !formData.name}
                className="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-purple-500 text-white rounded-lg hover:from-purple-700 hover:to-purple-600 transition-all duration-200 flex items-center justify-center gap-2 disabled:opacity-50"
              >
                <Wand2 className="w-4 h-4" />
                {isGenerating ? 'Génération en cours...' : 'Générer les informations avec ChatGPT'}
              </button>
            </div>
            </div>

            {/* Distances */}
            <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <h3 className="font-medium mb-4">Distances</h3>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Dubai Mall
                  </label>
                  <input
                    type="text"
                    value={formData.distances.dubaiMall}
                    onChange={(e) => setFormData({
                      ...formData,
                      distances: { ...formData.distances, dubaiMall: e.target.value }
                    })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Ex: 15 minutes en voiture"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Dubai Marina
                  </label>
                  <input
                    type="text"
                    value={formData.distances.dubaiMarina}
                    onChange={(e) => setFormData({
                      ...formData,
                      distances: { ...formData.distances, dubaiMarina: e.target.value }
                    })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Ex: 25 minutes en voiture"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mt-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    DXB Airport
                  </label>
                  <input
                    type="text"
                    value={formData.distances.dxbAirport}
                    onChange={(e) => setFormData({
                      ...formData,
                      distances: { ...formData.distances, dxbAirport: e.target.value }
                    })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Ex: 30 minutes en voiture"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Rendement Airbnb
                  </label>
                  <input
                    type="text"
                    value={formData.distances.airbnbYield}
                    onChange={(e) => setFormData({
                      ...formData,
                      distances: { ...formData.distances, airbnbYield: e.target.value }
                    })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Ex: 8-10% par an"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Rendement Location longue durée
                  </label>
                  <input
                    type="text"
                    value={formData.distances.longTermYield}
                    onChange={(e) => setFormData({
                      ...formData,
                      distances: { ...formData.distances, longTermYield: e.target.value }
                    })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Ex: 6-8% par an"
                  />
                </div>
              </div>
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                required
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={6}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Points d'intérêt */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Points d'intérêt
              </label>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={pointOfInterest}
                  onChange={(e) => setPointOfInterest(e.target.value)}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ajouter un point d'intérêt"
                />
                <button
                  type="button"
                  onClick={addPointOfInterest}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Ajouter
                </button>
              </div>
              <div className="mt-2 space-y-2">
                {formData.pointsOfInterest.map((poi, index) => (
                  <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                    <span className="text-sm">{poi}</span>
                    <button
                      type="button"
                      onClick={() => setFormData({
                        ...formData,
                        pointsOfInterest: formData.pointsOfInterest.filter((_, i) => i !== index)
                      })}
                      className="text-red-600 hover:text-red-700"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>

      {/* Area Selector Modal */}
      {showAreaSelector && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl w-full max-w-2xl p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-semibold">Sélectionner un quartier</h2>
              <button
                onClick={() => setShowAreaSelector(false)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="mb-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher un quartier..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="max-h-96 overflow-y-auto">
              <div className="space-y-2">
                {filteredAreas.map((area) => (
                  <motion.button
                    key={area.id}
                    onClick={() => handleAreaSelect(area)}
                    className="w-full p-4 text-left bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50 hover:border-blue-200/50 hover:from-blue-50/50 hover:to-blue-100/20 transition-all duration-200"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <h3 className="font-medium">{area.name}</h3>
                    <p className="text-sm text-gray-500 mt-1">{area.description}</p>
                  </motion.button>
                ))}

                {filteredAreas.length === 0 && (
                  <div className="text-center py-12">
                    <MapPin className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                    <p className="text-gray-500">Aucun quartier trouvé</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}