import React, { useState } from 'react';
import { FileText, Save } from 'lucide-react';
import EmailTemplateEditor from '../EmailTemplateEditor';

interface PdfTabProps {
  settings: any;
  updateSettings: (settings: any) => Promise<void>;
  showToast: (type: 'success' | 'error', message: string) => void;
}

const PDF_SECTIONS = {
  dubai_advantages: {
    name: 'Avantages de Dubaï',
    template: `
      <h1>Pourquoi investir à Dubaï ?</h1>
      <ul>
        <li>0% d'impôts sur les revenus locatifs</li>
        <li>Rendements locatifs parmi les plus élevés au monde</li>
        <li>Marché immobilier en pleine croissance</li>
        <li>Économie diversifiée et stable</li>
        <li>Qualité de vie exceptionnelle</li>
      </ul>
    `,
    variables: {}
  },
  returns: {
    name: 'Rendements',
    template: `
      <h1>Des rendements exceptionnels</h1>
      <p>Le marché immobilier de Dubaï offre des rendements locatifs parmi les plus élevés au monde :</p>
      <ul>
        <li>Studios : 7-9% de rendement annuel</li>
        <li>Appartements : 6-8% de rendement annuel</li>
        <li>Villas : 5-7% de rendement annuel</li>
      </ul>
    `,
    variables: {}
  },
  agency: {
    name: 'Présentation de l\'agence',
    template: `
      <h1>Palm Dubai Immo</h1>
      <p>Votre partenaire de confiance pour investir à Dubaï</p>
      <ul>
        <li>Plus de 10 ans d'expérience</li>
        <li>Une équipe franco-émiratie</li>
        <li>Un accompagnement personnalisé</li>
        <li>Les meilleurs projets sélectionnés pour vous</li>
      </ul>
    `,
    variables: {}
  },
  closing: {
    name: 'Page de clôture',
    template: `
      <div style="text-align: center;">
        <img src="https://palmdubai.fr/templates/Default/img/logo_black.png" alt="Palm Dubai Logo" />
        <h2>Contactez-nous</h2>
        <p>Email: contact@palmdubaiimmo.com</p>
        <p>Téléphone: +971 XX XXX XXXX</p>
        <p>www.palmdubai.fr</p>
      </div>
    `,
    variables: {}
  }
};

export default function PdfTab({ settings, updateSettings, showToast }: PdfTabProps) {
  const [editingSection, setEditingSection] = useState<string | null>(null);
  const [templates, setTemplates] = useState(settings?.pdfTemplates || PDF_SECTIONS);

  const handleTemplateChange = (sectionId: string, newContent: string) => {
    setTemplates({
      ...templates,
      [sectionId]: {
        ...templates[sectionId],
        template: newContent
      }
    });
  };

  const handleSave = async () => {
    try {
      await updateSettings({
        ...settings,
        pdfTemplates: templates
      });
      showToast('success', 'Templates PDF enregistrés avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'enregistrement des templates');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <FileText className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Pages PDF fixes</h2>
        </div>
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <Save className="w-4 h-4" />
          Enregistrer
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {Object.entries(templates).map(([id, section]) => (
          <div key={id} className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium">{section.name}</h3>
              <button
                onClick={() => setEditingSection(editingSection === id ? null : id)}
                className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                {editingSection === id ? 'Fermer' : 'Modifier'}
              </button>
            </div>

            {editingSection === id && (
              <EmailTemplateEditor
                template={section.template}
                onChange={(content) => handleTemplateChange(id, content)}
                onClose={() => setEditingSection(null)}
                variables={section.variables}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}