import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Agent, Sale, Area } from '../types';

async function getApiKey(): Promise<string | null> {
  try {
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();
    if (settings?.openai?.apiKey) {
      return settings.openai.apiKey;
    }
    return null;
  } catch (error) {
    console.error('Error getting API key:', error);
    return null;
  }
}

export async function generateAreaInfo(areaName: string): Promise<Partial<Area>> {
  try {
    const apiKey = await getApiKey();
    if (!apiKey) {
      throw new Error('OpenAI API key not configured');
    }

    const prompt = `Générer une description détaillée et des points d'intérêt pour le quartier "${areaName}" à Dubai. Inclure:

1. Une description générale du quartier (environ 100 mots)
2. Une description de l'intérêt pour l'investissement immobilier
3. La distance approximative avec:
   - Dubai Mall
   - Dubai Marina
   - DXB Airport
4. 5 points d'intérêt majeurs du quartier

Format de réponse souhaité en JSON:
{
  "description": "...",
  "investmentDescription": "...",
  "distances": {
    "dubaiMall": "...",
    "dubaiMarina": "...",
    "dxbAirport": "...",
    "airbnbYield": "...",
    "longTermYield": "..."
  },
  "pointsOfInterest": [
    "...",
    "..."
  ]
}`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [{
          role: 'system',
          content: 'Tu es un expert immobilier spécialisé sur Dubai.'
        }, {
          role: 'user',
          content: prompt
        }],
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    const data = await response.json();
    const result = JSON.parse(data.choices[0].message.content);

    return {
      name: areaName,
      description: `${result.description}\n\n${result.investmentDescription}`,
      distances: {
        dubaiMall: result.distances.dubaiMall,
        dubaiMarina: result.distances.dubaiMarina,
        dxbAirport: result.distances.dxbAirport,
        airbnbYield: result.distances.airbnbYield,
        longTermYield: result.distances.longTermYield
      },
      pointsOfInterest: result.pointsOfInterest
    };
  } catch (error) {
    console.error('Error generating area info:', error);
    throw error;
  }
}

export async function generateDeveloperInfo(developerName: string): Promise<Partial<Developer>> {
  try {
    const apiKey = await getApiKey();
    if (!apiKey) {
      throw new Error('OpenAI API key not configured');
    }

    const prompt = `Générer une description détaillée pour le promoteur immobilier "${developerName}" à Dubai.

Format de réponse souhaité en JSON:
{
  "description": "Description détaillée du promoteur, son historique, sa réputation et ses réalisations majeures à Dubai",
  "logoUrl": "URL du logo officiel si disponible",
  "projects": [
    "Nom du projet 1",
    "Nom du projet 2",
    "..."
  ]
}`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [{
          role: 'system',
          content: 'Tu es un expert immobilier spécialisé sur Dubai.'
        }, {
          role: 'user',
          content: prompt
        }],
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    const data = await response.json();
    const result = JSON.parse(data.choices[0].message.content);

    return {
      name: developerName,
      description: result.description,
      logo: result.logoUrl,
      projects: result.projects
    };
  } catch (error) {
    console.error('Error generating developer info:', error);
    throw error;
  }
}

async function generateMessage(prompt: string): Promise<string> {
  try {
    const apiKey = await getApiKey();
    if (!apiKey) {
      throw new Error('OpenAI API key not configured');
    }

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [{
          role: 'system',
          content: 'Tu es un assistant professionnel qui génère des messages de notification pour une agence immobilière de luxe à Dubai. Les messages doivent être courtois, professionnels et personnalisés.'
        }, {
          role: 'user',
          content: prompt
        }],
        temperature: 0.7,
        max_tokens: 200
      })
    });

    const data = await response.json();
    return data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error generating message:', error);
    return null;
  }
}

export async function generateSaleNotification(type: 'new_sale' | 'commission_received' | 'agent_paid', sale: Sale, agent: Agent): Promise<string> {
  const prompt = `Génère un message WhatsApp professionnel pour notifier un agent immobilier dans le contexte suivant:

Type: ${type === 'new_sale' ? 'Nouvelle vente enregistrée' : 
       type === 'commission_received' ? 'Commission reçue' : 
       'Commission payée'}
Agent: ${agent.name}
Projet: ${sale.project}
Unité: ${sale.unitNumber}
Prix: ${sale.propertyPrice} AED
Commission: ${sale.developerCommission} AED

Le message doit être:
- Professionnel et courtois
- Personnalisé avec les détails fournis
- Concis mais informatif
- Avec des emojis appropriés
- En français`;

  const message = await generateMessage(prompt);
  return message || getDefaultMessage(type, sale, agent);
}

export async function generateWelcomeMessage(agent: Agent, password: string): Promise<string> {
  const prompt = `Génère un message de bienvenue WhatsApp pour un nouvel agent immobilier:

Agent: ${agent.name}
Email: ${agent.email}
Mot de passe: ${password}

Le message doit:
- Souhaiter la bienvenue de manière chaleureuse
- Inclure les identifiants de connexion
- Être professionnel et encourageant
- Utiliser des emojis appropriés
- Être en français`;

  const message = await generateMessage(prompt);
  return message || getDefaultWelcomeMessage(agent, password);
}

function getDefaultMessage(type: 'new_sale' | 'commission_received' | 'agent_paid', sale: Sale, agent: Agent): string {
  switch (type) {
    case 'new_sale':
      return `👋 Bonjour ${agent.name},\n\n` +
             `🎉 Une nouvelle vente a été enregistrée:\n\n` +
             `📍 Projet: ${sale.project}\n` +
             `🏢 Unité: ${sale.unitNumber}\n` +
             `💰 Prix: ${sale.propertyPrice} AED\n` +
             `💎 Commission: ${sale.developerCommission} AED\n\n` +
             `Félicitations! 🌟`;
    
    case 'commission_received':
      return `👋 Bonjour ${agent.name},\n\n` +
             `💫 La commission pour votre vente a été reçue:\n\n` +
             `📍 Projet: ${sale.project}\n` +
             `🏢 Unité: ${sale.unitNumber}\n` +
             `💎 Commission: ${sale.developerCommission} AED\n\n` +
             `Le paiement sera traité prochainement. 🙌`;
    
    case 'agent_paid':
      return `👋 Bonjour ${agent.name},\n\n` +
             `✨ Votre commission a été versée:\n\n` +
             `📍 Projet: ${sale.project}\n` +
             `🏢 Unité: ${sale.unitNumber}\n` +
             `💎 Commission: ${sale.developerCommission} AED\n\n` +
             `Le paiement a été effectué sur votre compte. 🎉`;
  }
}

function getDefaultWelcomeMessage(agent: Agent, password: string): string {
  return `👋 Bienvenue ${agent.name} !\n\n` +
         `🌟 Nous sommes ravis de vous accueillir chez Palm Dubai Immo.\n\n` +
         `🔑 Voici vos identifiants de connexion:\n` +
         `📧 Email: ${agent.email}\n` +
         `🔒 Mot de passe: ${password}\n\n` +
         `🌐 Connectez-vous sur: https://palmdubaicrm.com\n\n` +
         `✨ Bienvenue dans l'équipe!`;
}

export async function generatePropertyDescription(projectName: string): Promise<{
  shortDescription: string;
  description: string;
}> {
  try {
    const apiKey = await getApiKey();
    if (!apiKey) {
      return {
        shortDescription: `${projectName} - Nouveau projet résidentiel de luxe à Dubai`,
        description: `${projectName} est un développement résidentiel de luxe situé dans un emplacement privilégié à Dubai. Ce projet offre des appartements modernes avec des finitions haut de gamme et des vues imprenables sur la ville. Les résidents bénéficieront d'équipements de première classe et d'un style de vie luxueux caractéristique de Dubai.`
      };
    }

    const prompt = `Générer une description courte et une description détaillée pour le projet immobilier "${projectName}" à Dubai.

Format de réponse souhaité en JSON:
{
  "shortDescription": "Description courte de 1-2 phrases mettant en avant les points clés",
  "description": "Description détaillée de 3-4 paragraphes incluant les avantages, l'emplacement et le potentiel d'investissement"
}`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [{
          role: 'system',
          content: 'Tu es un expert immobilier spécialisé sur Dubai.'
        }, {
          role: 'user',
          content: prompt
        }],
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    const data = await response.json();
    if (data.error) {
      throw new Error(data.error.message || 'OpenAI API error');
    }
    
    if (!data.choices?.[0]?.message?.content) {
      return {
        shortDescription: `${projectName} - Nouveau projet résidentiel de luxe à Dubai`,
        description: `${projectName} est un développement résidentiel de luxe situé dans un emplacement privilégié à Dubai. Ce projet offre des appartements modernes avec des finitions haut de gamme et des vues imprenables sur la ville. Les résidents bénéficieront d'équipements de première classe et d'un style de vie luxueux caractéristique de Dubai.`
      };
    }

    const result = JSON.parse(data.choices[0].message.content);
    return {
      shortDescription: result.shortDescription,
      description: result.description
    };
  } catch (error) {
    console.error('Error generating property description:', error);
    return {
      shortDescription: `${projectName} - Nouveau projet résidentiel de luxe à Dubai`,
      description: `${projectName} est un développement résidentiel de luxe situé dans un emplacement privilégié à Dubai. Ce projet offre des appartements modernes avec des finitions haut de gamme et des vues imprenables sur la ville. Les résidents bénéficieront d'équipements de première classe et d'un style de vie luxueux caractéristique de Dubai.`
    };
  }
}