import React, { useState } from 'react';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { useAgents } from '../../hooks/useAgents';
import ConventionAlert from '../../components/agent-dashboard/ConventionAlert';
import QuarterlyGoalCard from '../../components/agent-dashboard/QuarterlyGoalCard';
import AgentStats from './components/AgentStats';
import AgentSalesChart from './components/AgentSalesChart';
import AgentCommissionChart from './components/AgentCommissionChart';
import TopProjects from '../../components/agent-dashboard/TopProjects';
import InformationCards from '../../components/agent-dashboard/InformationCards';
import MonthlyGoalCard from './components/MonthlyGoalCard';
import LastSaleCard from './components/LastSaleCard';
import ImportantRulesBanner from '../../components/agent-dashboard/ImportantRulesBanner';
import TopAgents from './components/TopAgents';
import UnderperformingAlert from './components/UnderperformingAlert';
import InactivityAlert from './components/InactivityAlert';
import { Building, TrendingUp, Edit } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import AdminGoalsButton from '../../components/agent-dashboard/AdminGoalsButton';
import AgentEditPopup from '../../components/agent-dashboard/AgentEditPopup';
import { useSettings } from '../../hooks/useSettings';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export default function AgentDashboard() {
  const { agent } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const { settings } = useSettings();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const quarterlyGoal = settings?.quarterlyGoals?.[agent?.id];
  const conventionSigned = quarterlyGoal?.conventionSigned || false;

  if (!agent) return null;

  const agentSales = sales.filter(sale => 
    sale.agentId === agent.id || sale.referrerId === agent.id
  ).sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Get the most recent and first sale
  const lastSale = agentSales[0];
  const firstSale = agentSales[agentSales.length - 1];

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  // Calculate monthly average
  const calculateMonthlyAverage = () => {
    if (!firstSale || !lastSale) return 0;

    const firstDate = new Date(firstSale.saleDate);
    const lastDate = new Date(lastSale.saleDate);
    const monthsDiff = (lastDate.getFullYear() - firstDate.getFullYear()) * 12 + 
                      (lastDate.getMonth() - firstDate.getMonth()) + 1;

    return agentSales.length / Math.max(1, monthsDiff);
  };

  const monthlyAverage = calculateMonthlyAverage();

  const commission = lastSale ? calculateCommission(lastSale) : null;
  
  // Round monthly average to nearest whole number
  const roundedMonthlyAverage = Math.round(monthlyAverage);

  const agentCommission = lastSale ? (
    lastSale.agentId === agent.id ? commission?.agent : commission?.referrer
  ) : 0;

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-4">
            <div className="flex items-start sm:items-center gap-3">
              <Building className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-2xl font-bold">Bienvenue, {agent.name}</h1>
                <p className="text-gray-500 mt-0.5 sm:mt-1 text-sm sm:text-base">
                  Dernière vente : {daysSinceLastSale}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm sm:text-base ml-9 sm:ml-0">
              <TrendingUp className="w-5 h-5 text-green-600" />
              <span className="text-sm font-medium">
                Moyenne : {roundedMonthlyAverage} ventes/mois
              </span>
              <div className="flex gap-2">
              <button
                onClick={() => setShowEditPopup(true)}
                className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
              >
                <Edit className="w-4 h-4" />
                Modifier mes informations
              </button>
              <AdminGoalsButton 
                agentId={agent.id}
                quarterlyGoal={quarterlyGoal}
              />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Information Cards */}
      <InformationCards />

      {/* Alerts */}
      <InactivityAlert lastSaleDate={lastSale?.saleDate} />
      <UnderperformingAlert monthlyAverage={roundedMonthlyAverage} />
      {!conventionSigned && <ConventionAlert />}

      {/* Quarterly Goal Card */}
      {quarterlyGoal && (
        <MonthlyGoalCard
          quarterlyGoal={quarterlyGoal}
          sales={agentSales}
        />
      )}
      
      {lastSale && agentCommission && (
        <LastSaleCard 
          sale={lastSale}
          commission={agentCommission}
        />
      )}

      {/* Stats Grid */}
      <AgentStats agent={agent} sales={agentSales} />

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AgentSalesChart sales={agentSales} />
        <AgentCommissionChart sales={agentSales} />
      </div>

      {/* Top Projects & Agents */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <TopProjects />
        <TopAgents currentAgentId={agent.id} />
      </div>


      {/* Edit Popup */}
      {showEditPopup && (
        <AgentEditPopup
          agent={agent}
          onClose={() => setShowEditPopup(false)}
          onUpdate={(updatedAgent) => {
            // Handle agent update if needed
          }}
        />
      )}
    </div>
  );
}