import React, { useState } from 'react';
import { Building, Plus, Search } from 'lucide-react';
import { useDevelopers } from '../hooks/useDevelopers';
import { useToast } from '../contexts/ToastContext';
import DeveloperForm from '../components/property/DeveloperForm';
import { motion } from 'framer-motion';
import CustomOfferNavbar from '../components/property/CustomOfferNavbar';

export default function Developers() {
  const { developers, loading, error, deleteDeveloper, addDeveloper } = useDevelopers();
  const { showToast } = useToast();
  const [showAddDeveloper, setShowAddDeveloper] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredDevelopers = developers.filter(developer => {
    if (!searchQuery) return true;
    return developer.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDelete = async (id: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce promoteur ?')) return;
    
    try {
      await deleteDeveloper(id);
      showToast('success', 'Promoteur supprimé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la suppression du promoteur');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      <CustomOfferNavbar />
      <div className="mt-16">
        {/* Header */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
          <div className="p-6 border-b border-gray-200/50">
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <div className="flex-1">
                <div className="flex items-center gap-3">
                  <Building className="w-6 h-6 text-blue-600" />
                  <div>
                    <h1 className="text-xl font-semibold">Promoteurs</h1>
                    <p className="text-sm text-gray-500 mt-1">
                      {developers.length} promoteurs enregistrés
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setShowAddDeveloper(true)}
                className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Nouveau promoteur
              </button>
            </div>
          </div>

          <div className="p-6">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher un promoteur..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Developers Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredDevelopers.map((developer) => (
            <motion.div
              key={developer.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
            >
              <div className="p-6">
                <div className="flex items-center gap-4 mb-4">
                  {developer.logo && (
                    <img
                      src={developer.logo}
                      alt={developer.name}
                      className="w-16 h-16 object-contain rounded-lg bg-gray-50"
                    />
                  )}
                  <div>
                    <h3 className="text-xl font-semibold">{developer.name}</h3>
                    {developer.contact && (
                      <p className="text-sm text-gray-500">{developer.contact.email}</p>
                    )}
                  </div>
                </div>

                <p className="text-gray-600 mb-4">{developer.description}</p>

                {developer.projects && developer.projects.length > 0 && (
                  <div>
                    <h4 className="font-medium mb-2">Projets</h4>
                    <div className="flex flex-wrap gap-2">
                      {developer.projects.map((project, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                        >
                          {project}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="px-6 py-4 bg-gray-50 border-t border-gray-200/50">
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => handleDelete(developer.id)}
                    className="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Add Developer Modal */}
        {showAddDeveloper && (
          <DeveloperForm
            onSubmit={async (developer) => {
              try {
                await addDeveloper(developer);
                showToast('success', 'Promoteur ajouté avec succès');
                setShowAddDeveloper(false);
              } catch (error) {
                showToast('error', 'Erreur lors de l\'ajout du promoteur');
              }
            }}
            onClose={() => setShowAddDeveloper(false)}
          />
        )}
      </div>
    </div>
  );
}