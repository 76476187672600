import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Building, Calendar, DollarSign, ArrowLeft, Edit, 
  Trash2, FileText, User, Mail, Phone, Gift 
} from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { useAuth } from '../hooks/useAuth';
import SaleForm from '../components/SaleForm';
import { generateSaleReport } from '../services/pdfService';
import Avatar from '../components/ui/Avatar';
import PaymentToggle from '../components/ui/PaymentToggle';
import { getCommissionTypes } from '../utils/commission';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';

interface SaleDetailsProps {
  isEditing?: boolean;
}

export default function SaleDetails({ isEditing = false }: SaleDetailsProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { sales, calculateCommission, updateSale, deleteSale } = useSales();
  const { agents } = useAgents();
  const { user } = useAuth();
  const [showEditForm, setShowEditForm] = useState(isEditing);

  const sale = sales.find((s) => s.id === id);
  if (!sale) return <div>Vente non trouvée</div>;

  const agent = agents.find((a) => a.id === sale.agentId);
  const referrer = sale.referrerId ? agents.find((a) => a.id === sale.referrerId) : null;
  const secondBonusAgent = sale.bonus?.secondAgentId ? agents.find((a) => a.id === sale.bonus?.secondAgentId) : null;
  const commission = calculateCommission(sale);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette vente ?')) {
      await deleteSale(sale.id);
      navigate('/sales');
    }
  };

  const handleGeneratePDF = () => {
    const doc = generateSaleReport(sale, agent, referrer);
    const fileName = `vente-${sale.project.toLowerCase()}-${sale.unitNumber}-${
      new Date().toISOString().split('T')[0]
    }.pdf`;
    doc.save(fileName);
  };

  const handlePaymentStatusChange = async (
    type: 'developer' | 'agent' | 'referrer' | 'bonus',
    status: boolean
  ) => {
    const updatedPaymentStatus = {
      ...sale.paymentStatus,
      [type]: status,
    };

    await updateSale(sale.id, {
      paymentStatus: updatedPaymentStatus,
    });
  };

  const handleClientPaymentChange = async (
    type: 'eoi' | 'downPayment' | 'spa',
    status: boolean
  ) => {
    const updatedClient = {
      ...sale.client,
      payments: {
        ...sale.client.payments,
        [type]: {
          status,
          date: status ? new Date().toISOString() : undefined
        }
      }
    };

    await updateSale(sale.id, {
      client: updatedClient
    });
  };

  const getCommissionTypeLabel = (type: string) => {
    const commissionType = getCommissionTypes().find(t => t.value === type);
    return commissionType?.label || type;
  };

  const getClientProfileUrl = () => {
    if (sale.client.email) {
      return `/clients/${encodeURIComponent(sale.client.email)}`;
    }
    if (sale.client.phone) {
      return `/clients/${encodeURIComponent(sale.client.phone)}`;
    }
    return null;
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6 pb-20 lg:pb-0">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6 border-b border-gray-200/50">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate(-1)}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold">{sale.project}</h1>
                <p className="text-gray-500">Unité {sale.unitNumber}</p>
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <button
                onClick={handleGeneratePDF}
                className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
              >
                <FileText className="w-4 h-4" />
                Générer PDF
              </button>
              <button
                onClick={() => setShowEditForm(true)}
                className="w-full sm:w-auto p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <Edit className="w-5 h-5" />
              </button>
              <button
                onClick={handleDelete}
                className="w-full sm:w-auto p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Property Info */}
        <div className="p-4 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <Building className="w-5 h-5 text-blue-600" />
                  <h3 className="font-medium">Détails du bien</h3>
                </div>
                <div className="text-sm text-gray-500">
                  Livraison {sale.expectedDeliveryYear}
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-600 mb-1">Prix du bien</p>
                  <p className="text-2xl font-bold text-blue-900">{formatCurrency(sale.propertyPrice)}</p>
                  <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-800 bg-white border border-blue-200 rounded-full">
                    Estimation EUR : {formatEuro(sale.propertyPrice)}
                  </span>
                </div>
                <div className="pt-4 border-t border-blue-200/50">
                  <p className="text-sm text-gray-600 mb-1">Date de vente</p>
                  <p className="font-medium">{formatDateDisplay(sale.saleDate)}</p>
                </div>
              </div>
            </div>

            {/* Client Info */}
            <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
              <div className="flex items-center gap-3 mb-4">
                <User className="w-5 h-5 text-gray-600" />
                <h3 className="font-medium">Client</h3>
              </div>
              <div className="space-y-3">
                {getClientProfileUrl() ? (
                  <Link 
                    to={getClientProfileUrl()!}
                    className="text-lg font-medium text-blue-600 hover:text-blue-700"
                  >
                    {sale.client.name || 'Client non renseigné'}
                  </Link>
                ) : (
                  <h4 className="text-lg font-medium">{sale.client.name || 'Client non renseigné'}</h4>
                )}
                {sale.client.email && (
                  <a href={`mailto:${sale.client.email}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                    <Mail className="w-4 h-4" />
                    {sale.client.email}
                  </a>
                )}
                {sale.client.phone && (
                  <a href={`tel:${sale.client.phone}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                    <Phone className="w-4 h-4" />
                    {sale.client.phone}
                  </a>
                )}
                <div className="inline-block mt-2 px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                  Source: {sale.leadSource}
                </div>
              </div>
            </div>

            {/* Client Payments */}
            <div className="grid grid-cols-3 gap-4">
              <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <DollarSign className="w-4 h-4 text-gray-600" />
                    <span className="text-sm font-medium">EOI</span>
                  </div>
                  <button
                    role="switch"
                    aria-checked={sale.client.payments?.eoi?.status || false}
                    onClick={() => handleClientPaymentChange('eoi', !sale.client.payments?.eoi?.status)}
                    className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    style={{
                      backgroundColor: sale.client.payments?.eoi?.status ? '#3B82F6' : '#E5E7EB'
                    }}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                        sale.client.payments?.eoi?.status ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
                {sale.client.payments?.eoi?.date && (
                  <p className="text-xs text-gray-500">
                    {formatDateDisplay(sale.client.payments.eoi.date)}
                  </p>
                )}
              </div>

              <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <DollarSign className="w-4 h-4 text-gray-600" />
                    <span className="text-sm font-medium">DP</span>
                  </div>
                  <button
                    role="switch"
                    aria-checked={sale.client.payments?.downPayment?.status || false}
                    onClick={() => handleClientPaymentChange('downPayment', !sale.client.payments?.downPayment?.status)}
                    className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    style={{
                      backgroundColor: sale.client.payments?.downPayment?.status ? '#3B82F6' : '#E5E7EB'
                    }}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                        sale.client.payments?.downPayment?.status ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
                {sale.client.payments?.downPayment?.date && (
                  <p className="text-xs text-gray-500">
                    {formatDateDisplay(sale.client.payments.downPayment.date)}
                  </p>
                )}
              </div>

              <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <FileText className="w-4 h-4 text-gray-600" />
                    <span className="text-sm font-medium">SPA</span>
                  </div>
                  <button
                    role="switch"
                    aria-checked={sale.client.payments?.spa?.status || false}
                    onClick={() => handleClientPaymentChange('spa', !sale.client.payments?.spa?.status)}
                    className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    style={{
                      backgroundColor: sale.client.payments?.spa?.status ? '#3B82F6' : '#E5E7EB'
                    }}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                        sale.client.payments?.spa?.status ? 'translate-x-6' : 'translate-x-1'
                      }`}
                    />
                  </button>
                </div>
                {sale.client.payments?.spa?.date && (
                  <p className="text-xs text-gray-500">
                    {formatDateDisplay(sale.client.payments.spa.date)}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Commission Info */}
          <div className="space-y-6">
            <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <DollarSign className="w-5 h-5 text-purple-600" />
                  <div>
                    <h3 className="font-medium">Commission</h3>
                    {sale.commissionType === 'manual' && (
                      <p className="text-sm text-purple-600">Répartition manuelle</p>
                    )}
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  {getCommissionTypeLabel(sale.commissionType)}
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <div className="flex items-center justify-between mb-1">
                    <p className="text-sm text-gray-600">Commission promoteur</p>
                    <span className="text-sm font-medium text-purple-900">{sale.developerCommissionPercentage}%</span>
                  </div>
                  <p className="text-2xl font-bold text-purple-900">
                    {formatCurrency(sale.developerCommission)}
                  </p>
                  <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-purple-800 bg-white border border-purple-200 rounded-full">
                    Estimation EUR : {formatEuro(sale.developerCommission)}
                  </span>
                </div>
                <div className="pt-4 border-t border-purple-200/50">
                  <PaymentToggle
                    label="Commission reçue"
                    checked={sale.paymentStatus?.developer || false}
                    onChange={(status) => handlePaymentStatusChange('developer', status)}
                  />
                </div>
              </div>
            </div>

            {/* Bonus Info */}
            {sale.bonus && (
              <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
                <div className="flex items-center gap-3 mb-4">
                  <Gift className="w-5 h-5 text-purple-600" />
                  <div>
                    <h3 className="font-medium">Bonus</h3>
                    {sale.bonus?.isManual && (
                      <p className="text-sm text-purple-600">Répartition manuelle</p>
                    )}
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <p className="text-2xl font-bold text-purple-900">
                      {formatCurrency(sale.bonus.amount)}
                    </p>
                    <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-purple-800 bg-white border border-purple-200 rounded-full">
                      Estimation EUR : {formatEuro(sale.bonus.amount)}
                    </span>
                  </div>
                  <div className="pt-4 border-t border-purple-200/50">
                    <PaymentToggle
                      label="Bonus payé"
                      checked={sale.paymentStatus?.bonus || false}
                      onChange={(status) => handlePaymentStatusChange('bonus', status)}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Agents */}
            <div className="space-y-4">
              {/* Primary Agent */}
              <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-3">
                    <Avatar name={agent?.name || ''} size="md" />
                    <div>
                      <Link
                        to={`/agents/${agent?.id}`}
                        className="font-medium text-blue-600 hover:text-blue-700"
                      >
                        {agent?.name}
                      </Link>
                      <p className="text-sm text-gray-500">Agent principal</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <PaymentToggle
                      label="Payé"
                      checked={sale.paymentStatus?.agent || false}
                      onChange={(status) => handlePaymentStatusChange('agent', status)}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-2xl font-bold text-blue-900">{formatCurrency(commission.agent)}</p>
                  {sale.bonus && (
                    <div className="mt-2 flex items-center gap-2">
                      <Gift className="w-4 h-4 text-purple-600" />
                      <span className="text-purple-600">+{formatCurrency(commission.bonus.agent)}</span>
                    </div>
                  )}
                  <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-800 bg-white border border-blue-200 rounded-full">
                    Estimation EUR : {formatEuro(commission.agent)}
                  </span>
                </div>
              </div>

              {/* Referrer */}
              {referrer && (
                <div className="p-4 bg-gradient-to-br from-amber-50 to-amber-100/50 rounded-xl border border-amber-200/50">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-3">
                      <Avatar name={referrer.name} size="md" />
                      <div>
                        <Link
                          to={`/agents/${referrer.id}`}
                          className="font-medium text-amber-600 hover:text-amber-700"
                        >
                          {referrer.name}
                        </Link>
                        <p className="text-sm text-gray-500">Parrain</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <PaymentToggle
                        label="Payé"
                        checked={sale.paymentStatus?.referrer || false}
                        onChange={(status) => handlePaymentStatusChange('referrer', status)}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-2xl font-bold text-amber-900">{formatCurrency(commission.referrer || 0)}</p>
                    {sale.bonus?.secondAgentId === referrer.id && (
                      <div className="mt-2 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-purple-600" />
                        <span className="text-purple-600">+{formatCurrency(commission.bonus.referrer)}</span>
                      </div>
                    )}
                    <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-amber-800 bg-white border border-amber-200 rounded-full">
                      Estimation EUR : {formatEuro(commission.referrer || 0)}
                    </span>
                  </div>
                </div>
              )}

              {/* Second Bonus Agent (if different from referrer) */}
              {secondBonusAgent && secondBonusAgent.id !== referrer?.id && (
                <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
                  <div className="flex items-center gap-3 mb-4">
                    <Avatar name={secondBonusAgent.name} size="md" />
                    <div>
                      <Link
                        to={`/agents/${secondBonusAgent.id}`}
                        className="font-medium text-purple-600 hover:text-purple-700"
                      >
                        {secondBonusAgent.name}
                      </Link>
                      <p className="text-sm text-gray-500">Agent bonus</p>
                    </div>
                  </div>
                  <div>
                    <p className="text-2xl font-bold text-purple-900">{formatCurrency(commission.bonus.referrer)}</p>
                    <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-purple-800 bg-white border border-purple-200 rounded-full">
                      Estimation EUR : {formatEuro(commission.bonus.referrer)}
                    </span>
                  </div>
                </div>
              )}

              {/* Agency Commission */}
              <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
                <div className="flex items-center gap-3 mb-4">
                  <DollarSign className="w-5 h-5 text-green-600" />
                  <h3 className="font-medium">Commission perçue par l'agence</h3>
                </div>
                <div>
                  <p className="text-2xl font-bold text-green-900">{formatCurrency(commission.agency)}</p>
                  {sale.bonus && (
                    <div className="mt-2 flex items-center gap-2">
                      <Gift className="w-4 h-4 text-purple-600" />
                      <span className="text-purple-600">+{formatCurrency(commission.bonus.agency)}</span>
                    </div>
                  )}
                  <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-green-800 bg-white border border-green-200 rounded-full">
                    Estimation EUR : {formatEuro(commission.agency)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditForm && (
        <SaleForm
          initialData={sale}
          onSubmit={async (updatedSale) => {
            await updateSale(sale.id, updatedSale);
            setShowEditForm(false);
          }}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </div>
  );
}