import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, FileText, Lock } from 'lucide-react';

export default function ConventionAlert() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-red-600 to-red-500 rounded-xl shadow-lg shadow-red-500/20 overflow-hidden relative p-6"
    >
      {/* Animated gradient background */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/10 to-white/5"
        animate={{
          opacity: [0.1, 0.2, 0.1],
          scale: [1, 1.02, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative flex flex-col sm:flex-row items-start gap-6">
        {/* Icon */}
        <motion.div 
          className="flex-shrink-0"
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 5, 0, -5, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <div className="p-4 bg-white/10 rounded-xl backdrop-blur-sm border border-white/20">
            <AlertTriangle className="w-8 h-8 text-white" />
          </div>
        </motion.div>

        {/* Content */}
        <div className="flex-1">
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h3 className="text-2xl font-bold text-white mb-4">
              Action Requise : Signature de la Convention
            </h3>
            <div className="space-y-4">
              <div className="flex items-center gap-3 bg-white/10 backdrop-blur-sm rounded-lg p-3 border border-white/20">
                <FileText className="w-5 h-5 text-yellow-300" />
                <p className="text-white font-medium">
                  Signez votre convention avec les objectifs sur 3 mois
                </p>
              </div>
              <div className="flex items-center gap-3 bg-white/10 backdrop-blur-sm rounded-lg p-3 border border-white/20">
                <Lock className="w-5 h-5 text-yellow-300" />
                <p className="text-white font-medium">
                  ⚠️ Sans signature, aucun lead ne sera attribué
                </p>
              </div>
            </div>
          </motion.div>

          {/* Action Button */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-6"
          >
            <a
              href={`https://wa.me/971581998661?text=${encodeURIComponent('Je souhaite signer ma convention')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-6 py-3 bg-white text-red-600 rounded-lg hover:bg-red-50 transition-colors font-medium group"
            >
              <FileText className="w-5 h-5" />
              Signer ma convention
              <motion.div
                initial={{ opacity: 0.8 }}
                animate={{ opacity: [0.8, 1, 0.8] }}
                transition={{ duration: 2, repeat: Infinity }}
                className="ml-2 px-2 py-0.5 bg-red-100 rounded text-xs font-bold"
              >
                URGENT
              </motion.div>
            </a>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}