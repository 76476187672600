import React from 'react';
import { motion } from 'framer-motion';
import { Building, Crown } from 'lucide-react';
import { useSales } from '../../hooks/useSales';

export default function TopProjects() {
  const { sales } = useSales();

  // Get current month's sales
  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Group projects by sales count
  const projectStats = sales
    .filter(sale => new Date(sale.saleDate) >= startOfMonth)
    .reduce((acc, sale) => {
      if (!acc[sale.project]) {
        acc[sale.project] = {
          name: sale.project,
          salesCount: 0
        };
      }
      acc[sale.project].salesCount += 1;
      return acc;
    }, {} as Record<string, { name: string; salesCount: number; }>);

  // Convert to array and sort by sales count
  const topProjects = Object.values(projectStats)
    .sort((a, b) => b.salesCount - a.salesCount)
    .slice(0, 3);

  if (topProjects.length === 0) return null;

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <Building className="w-6 h-6 text-blue-600" />
          <h2 className="text-lg font-semibold">Top 3 Projets du Mois</h2>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x divide-gray-200/50">
        {topProjects.map((project, index) => (
          <motion.div
            key={project.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className={`p-6 ${index === 0 ? 'bg-gradient-to-br from-yellow-50 to-yellow-100/50' : ''}`}
          >
            <div className="flex flex-col items-center text-center">
              <div className="relative mb-3">
                <div className={`p-3 rounded-xl ${
                  index === 0 ? 'bg-yellow-100' :
                  index === 1 ? 'bg-gray-100' :
                  'bg-amber-100'
                }`}>
                  {index === 0 ? (
                    <Crown className={`w-8 h-8 ${
                      index === 0 ? 'text-yellow-600' :
                      index === 1 ? 'text-gray-600' :
                      'text-amber-600'
                    }`} />
                  ) : (
                    <Building className={`w-8 h-8 ${
                      index === 1 ? 'text-gray-600' :
                      'text-amber-600'
                    }`} />
                  )}
                </div>
              </div>

              <h3 className="font-medium text-gray-900">{project.name}</h3>
              
              <div className="mt-2">
                <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                  index === 0 ? 'bg-yellow-100 text-yellow-800 border border-yellow-200' :
                  index === 1 ? 'bg-gray-100 text-gray-800 border border-gray-200' :
                  'bg-amber-100 text-amber-800 border border-amber-200'
                }`}>
                  #{index + 1}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}