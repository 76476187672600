import React from 'react';
import EmailTemplateEditor from '../EmailTemplateEditor';

interface TemplatesTabProps {
  formData: {
    templates: {
      [key: string]: {
        name: string;
        template: string;
        variables: Record<string, string>;
      };
    };
  };
  handleTemplateChange: (templateId: string, newContent: string) => void;
  editingTemplate: string | null;
  setEditingTemplate: (templateId: string | null) => void;
}

export default function TemplatesTab({
  formData,
  handleTemplateChange,
  editingTemplate,
  setEditingTemplate
}: TemplatesTabProps) {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6">
        {Object.entries(formData.templates).map(([id, template]) => (
          <div key={id} className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium">{template.name}</h3>
              <button
                type="button"
                onClick={() => setEditingTemplate(editingTemplate === id ? null : id)}
                className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                {editingTemplate === id ? 'Fermer' : 'Modifier'}
              </button>
            </div>

            {editingTemplate === id && (
              <EmailTemplateEditor
                template={template.template}
                onChange={(content) => handleTemplateChange(id, content)}
                onClose={() => setEditingTemplate(null)}
                variables={template.variables}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}