import React, { useEffect, useRef } from 'react';
import { DollarSign, AlertTriangle, MessageSquare, Clock, Calendar, Gift, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatCurrency } from '../../utils/format';
import type { Sale } from '../../types';
import BlurredNumber from '../../components/ui/BlurredNumber';

interface AdvanceCommissionCardProps {
  sale: Sale;
  commission: {
    agent: number;
    agency: number;
    referrer?: number;
    bonus: {
      agent: number;
      agency: number;
      referrer: number;
    };
  };
}

export default function AdvanceCommissionCard({ sale, commission }: AdvanceCommissionCardProps) {
  // Calculate advance amount (90% of agent commission)
  const advanceAmount = Math.floor(commission.agent * 0.9);
  const reductionAmount = commission.agent - advanceAmount;

  const handleWhatsAppClick = () => {
    const message = `Bonjour,\n\nJe souhaite demander une avance sur ma commission pour la vente suivante :\n\nProjet : ${sale.project}\nUnité : ${sale.unitNumber}\nCommission totale : ${formatCurrency(commission.agent)}\nMontant demandé (-10%) : ${formatCurrency(advanceAmount)}\n\nLe client a payé le DP et signé le SPA.\n\nMerci !`;
    window.open(`https://wa.me/971581998661?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-amber-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative p-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-white/10 rounded-xl backdrop-blur-sm border border-white/20">
              <DollarSign className="w-6 h-6 text-white" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white">Besoin d'argent rapidement ?</h3>
              <p className="text-indigo-200 mt-1">Recevez votre commission aujourd'hui</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          {/* Left Column - Advance Details */}
          <div className="space-y-6">
            <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <div className="flex items-center gap-2 mb-3">
                <DollarSign className="w-5 h-5 text-green-300" />
                <h4 className="font-medium text-white">Montant disponible immédiatement</h4>
              </div>
              <p className="text-3xl font-bold text-white mb-2">
                <BlurredNumber value={formatCurrency(advanceAmount)} />
              </p>
              <div className="flex items-center gap-2 text-red-300 bg-red-500/10 px-3 py-2 rounded-lg">
                <AlertTriangle className="w-4 h-4" />
                <span className="text-sm">Réduction : <BlurredNumber value={formatCurrency(reductionAmount)} /> (-10%)</span>
              </div>
            </div>

            <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <div className="flex items-center gap-2 mb-3">
                <Clock className="w-5 h-5 text-blue-300" />
                <h4 className="font-medium text-white">Pourquoi demander une avance ?</h4>
              </div>
              <ul className="space-y-3 text-white/90">
                <li className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-blue-400" />
                  <span>Paiement le jour même</span>
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-blue-400" />
                  <span>Pas d'attente du paiement promoteur</span>
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-blue-400" />
                  <span>Idéal pour les besoins urgents</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column - Requirements */}
          <div className="space-y-6">
            <div className="p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
              <div className="flex items-center gap-2 mb-3">
                <Calendar className="w-5 h-5 text-yellow-300" />
                <h4 className="font-medium text-white">Conditions requises</h4>
              </div>
              <ul className="space-y-3">
                <li className="flex items-center gap-2 text-white/90">
                  <div className="w-2 h-2 rounded-full bg-green-400" />
                  <span>Down Payment payé ✓</span>
                </li>
                <li className="flex items-center gap-2 text-white/90">
                  <div className="w-2 h-2 rounded-full bg-green-400" />
                  <span>SPA signé ✓</span>
                </li>
              </ul>
            </div>

            <button
              onClick={handleWhatsAppClick}
              className="w-full px-6 py-4 bg-[#25D366] hover:bg-[#128C7E] text-white rounded-xl transition-all duration-200 flex items-center justify-center gap-3 group"
            >
              <MessageSquare className="w-5 h-5" />
              <span className="font-medium">Demander</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </button>
          </div>
        </div>

        {/* Info Banner */}
        <div className="p-4 bg-blue-500/10 border border-blue-500/20 rounded-xl">
          <p className="text-sm text-blue-200 leading-relaxed">
            💡 En acceptant une avance, vous recevrez immédiatement 90% de votre commission. 
            Les 10% restants seront retenus en échange de ce service. Cette option est idéale si vous avez 
            besoin de liquidités rapidement sans attendre le paiement du promoteur.
          </p>
        </div>
      </div>
    </motion.div>
  );
}