import React, { useState } from 'react';
import { Building, Plus, Search, DollarSign, MapPin, Edit, Trash2, Image as ImageIcon } from 'lucide-react';
import { useProperties } from '../hooks/useProperties';
import { useToast } from '../contexts/ToastContext';
import PropertyForm from '../components/property/PropertyForm';
import { motion } from 'framer-motion';
import CustomOfferNavbar from '../components/property/CustomOfferNavbar';
import { useDevelopers } from '../hooks/useDevelopers';
import { useAreas } from '../hooks/useAreas';
import PropertyCard from '../components/property/PropertyCard';

interface PropertiesProps {
  isAdmin?: boolean;
}

export default function Properties({ isAdmin = false }: PropertiesProps) {
  const { properties, loading, error, deleteProperty, addProperty } = useProperties();
  const { developers } = useDevelopers();
  const { areas } = useAreas();
  const { showToast } = useToast();
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [editingProperty, setEditingProperty] = useState<Property | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showImageSearch, setShowImageSearch] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

  const filteredProperties = properties.filter(property => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      property.name.toLowerCase().includes(query) ||
      property.developer.toLowerCase().includes(query) ||
      property.area.toLowerCase().includes(query)
    );
  });

  const handleDelete = async (id: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce bien ?')) return;
    
    try {
      await deleteProperty(id);
      showToast('success', 'Bien supprimé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la suppression du bien');
    }
  };

  const handleEdit = (property: Property) => {
    setEditingProperty(property);
    setShowAddProperty(true);
  };

  const handleImageSearch = (property: Property) => {
    setSelectedProperty(property);
    setShowImageSearch(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {isAdmin && <CustomOfferNavbar />}
      
      <div className={isAdmin ? "mt-16" : ""}>
        {/* Header */}
        <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
          <div className="p-6 border-b border-gray-200/50">
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <div className="flex-1">
                <div className="flex items-center gap-3">
                  <Building className="w-6 h-6 text-blue-600" />
                  <div>
                    <h1 className="text-xl font-semibold">Biens</h1>
                    <p className="text-sm text-gray-500 mt-1">
                      {properties.length} biens enregistrés
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setShowAddProperty(true)}
                className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Nouveau bien
              </button>
            </div>
          </div>

          <div className="p-6">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher un bien..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Properties Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredProperties.map(property => (
            <PropertyCard 
              key={property.id} 
              property={property} 
              onDelete={handleDelete} 
            />
          ))}

          {filteredProperties.length === 0 && (
            <div className="col-span-2 text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
              <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">Aucun bien trouvé</p>
            </div>
          )}
        </div>

        {/* Add Property Modal */}
        {showAddProperty && (
          <PropertyForm
            onSubmit={async (property) => {
              try {
                if (editingProperty) {
                  await updateProperty(editingProperty.id, property);
                  showToast('success', 'Bien modifié avec succès');
                } else {
                  await addProperty(property);
                  showToast('success', 'Bien ajouté avec succès');
                }
                setShowAddProperty(false);
                setEditingProperty(null);
              } catch (error) {
                showToast('error', editingProperty ? 'Erreur lors de la modification du bien' : 'Erreur lors de l\'ajout du bien');
              }
            }}
            onClose={() => {
              setShowAddProperty(false);
              setEditingProperty(null);
            }}
            developers={developers}
            areas={areas}
            initialData={editingProperty}
          />
        )}

        {/* Image Search Modal */}
        {showImageSearch && selectedProperty && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-semibold">Ajouter des images pour {selectedProperty.name}</h3>
                <button
                  onClick={() => {
                    setShowImageSearch(false);
                    setSelectedProperty(null);
                  }}
                  className="p-2 hover:bg-gray-100 rounded-lg"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-6">
                {/* Upload Section */}
                <div>
                  <h4 className="font-medium mb-4">Télécharger des images</h4>
                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 hover:bg-blue-100">
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <Upload className="w-8 h-8 mb-3 text-blue-600" />
                        <p className="mb-2 text-sm text-blue-600">
                          <span className="font-medium">Cliquez pour ajouter</span> ou glissez des photos
                        </p>
                        <p className="text-xs text-blue-500">PNG, JPG jusqu'à 10MB</p>
                      </div>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          const files = Array.from(e.target.files || []);
                          // Handle file upload
                        }}
                      />
                    </label>
                  </div>
                </div>

                {/* Google Images Search */}
                <div>
                  <h4 className="font-medium mb-4">Rechercher sur Google Images</h4>
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Rechercher des images..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  
                  {/* Image Results Grid */}
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    {/* Add image results here */}
                  </div>
                </div>

                {/* Current Images */}
                <div>
                  <h4 className="font-medium mb-4">Images actuelles</h4>
                  <div className="grid grid-cols-4 gap-4">
                    {selectedProperty.images?.map((image, index) => (
                      <div key={index} className="relative group">
                        <img
                          src={image}
                          alt={`${selectedProperty.name} ${index + 1}`}
                          className="w-full aspect-square object-cover rounded-lg"
                        />
                        <button
                          onClick={() => {
                            // Handle image removal
                          }}
                          className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}