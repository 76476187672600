import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Bot, Clock, Users, Zap, Brain } from 'lucide-react';

export default function AIWorkforce() {
  return (
    <div className="min-h-screen bg-[#0A0A0B] text-white overflow-hidden">
      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center justify-center px-4">
        {/* Animated background gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 to-purple-900/20" />
        <motion.div
          className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-500/10 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div className="relative max-w-7xl mx-auto text-center">
          {/* Badge */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-block px-6 py-2 rounded-full bg-[#1A1B1E] border border-gray-800 mb-8"
          >
            JOIN THE FUTURE
          </motion.div>

          {/* Main Title */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-4 mb-12"
          >
            <h1 className="text-5xl sm:text-7xl font-bold tracking-tight">
              AI Workforce
            </h1>
            <h2 className="text-5xl sm:text-7xl font-bold tracking-tight text-gray-500">
              Coming Soon.
            </h2>
          </motion.div>

          {/* Description */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-xl text-gray-400 max-w-3xl mx-auto mb-12"
          >
            Your brokerage, on auto pilot. Introducing AI Agents — the always-on workforce that handles everything from cold calling and lead qualification to follow-ups, property management, and client updates.
          </motion.p>

          {/* CTA Button */}
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="px-8 py-4 bg-blue-600 hover:bg-blue-700 rounded-xl text-lg font-medium transition-all duration-200"
          >
            Join The Waitlist
          </motion.button>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative py-24 px-4 bg-[#0A0A0B]">
        <div className="max-w-7xl mx-auto">
          {/* Section Title */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <div className="inline-block px-6 py-2 rounded-full bg-[#1A1B1E] border border-gray-800 mb-8">
              FOCUS ON WHAT MATTERS
            </div>
            <h2 className="text-5xl font-bold mb-8">
              Designed to give<br />agents their<br />time back.
            </h2>
          </motion.div>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
            <StatsCard
              value="80%"
              label="Less Workload"
              delay={0.2}
            />
            <StatsCard
              value="24/7"
              label="Lead Engagement"
              delay={0.3}
            />
            <StatsCard
              value="50%"
              label="Faster Deals"
              delay={0.4}
            />
            <StatsCard
              value="10x"
              label="Productivity Boost"
              delay={0.5}
            />
          </div>

          {/* Metrics Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-16">
            <MetricsCard
              value="50K+"
              label="Leads Qualified Overall"
              delay={0.6}
            />
            <MetricsCard
              value="4.7"
              label="Ratings out of 5"
              showStars
              delay={0.7}
            />
          </div>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
            className="text-center text-gray-400"
          >
            Transform how you manage leads, clients, and deals with smarter, automated processes.
          </motion.p>
        </div>
      </div>
    </div>
  );
}

interface StatsCardProps {
  value: string;
  label: string;
  delay: number;
}

function StatsCard({ value, label, delay }: StatsCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="p-8 bg-[#1A1B1E] rounded-2xl border border-gray-800"
    >
      <div className="text-4xl font-bold mb-2">{value}</div>
      <div className="text-gray-400">{label}</div>
    </motion.div>
  );
}

interface MetricsCardProps {
  value: string;
  label: string;
  showStars?: boolean;
  delay: number;
}

function MetricsCard({ value, label, showStars, delay }: MetricsCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="p-8 bg-gray-100/5 backdrop-blur-sm rounded-3xl border border-gray-800"
    >
      <div className="flex items-center gap-2">
        <span className="text-4xl font-bold text-blue-500">{value}</span>
        {showStars && (
          <div className="text-blue-500 text-3xl">
            ★<span className="opacity-50">★★★</span>
          </div>
        )}
      </div>
      <div className="text-gray-400 mt-2">{label}</div>
    </motion.div>
  );
}