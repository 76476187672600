import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Target, Calendar, Gift, TrendingUp, AlertTriangle } from 'lucide-react';
import confetti from 'canvas-confetti';
import type { Sale } from '../../../types';

interface MonthlyGoalCardProps {
  quarterlyGoal: {
    salesTarget: number;
    bonusDescription?: string;
    conventionSigned: boolean;
    startDate: string;
    endDate: string;
    leadsTarget: number;
    leadCost: number;
  };
  sales: Sale[];
}

export default function MonthlyGoalCard({ quarterlyGoal, sales }: MonthlyGoalCardProps) {
  const confettiRef = useRef<HTMLDivElement>(null);

  // Calculate sales within the quarterly period
  const quarterSales = sales.filter(sale => {
    const saleDate = new Date(sale.saleDate);
    return saleDate >= new Date(quarterlyGoal.startDate) && 
           saleDate <= new Date(quarterlyGoal.endDate);
  }).length;

  const hasReachedGoal = quarterSales > quarterlyGoal.salesTarget;
  const progressPercentage = Math.min((quarterSales / quarterlyGoal.salesTarget) * 100, 100);

  // Calculate remaining days
  const remainingDays = Math.ceil(
    (new Date(quarterlyGoal.endDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  );

  // Calculate potential cost based on sales target
  // For 1 sale target, cost is 1250€ (100 leads * 25€ * 0.5)
  const leadsPerSale = 100; // 100 leads per expected sale
  const potentialCost = quarterlyGoal.salesTarget * leadsPerSale * quarterlyGoal.leadCost * 0.5;

  // Trigger confetti if goal is reached
  useEffect(() => {
    if (hasReachedGoal && confettiRef.current) {
      const rect = confettiRef.current.getBoundingClientRect();
      const x = (rect.left + rect.right) / 2;
      const y = (rect.top + rect.bottom) / 2;

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { 
          x: x / window.innerWidth,
          y: y / window.innerHeight
        },
        colors: ['#60A5FA', '#34D399', '#A78BFA'],
        zIndex: 999
      });
    }
  }, [hasReachedGoal]);

  if (!quarterlyGoal.conventionSigned) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-purple-900 to-indigo-900 rounded-xl border border-purple-700/50 p-6 text-white relative overflow-hidden"
    >
      {/* Confetti container */}
      <div ref={confettiRef} className="absolute inset-0" />
      
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute bottom-0 left-0 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      {/* Content */}
      <div className="relative">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <motion.div
              className="flex-shrink-0"
              animate={{
                rotate: [0, 10, 0, -10, 0],
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="p-3 bg-gradient-to-br from-yellow-400 to-yellow-500 rounded-xl shadow-lg"
            >
              <Target className="w-8 h-8 text-white" />
            </motion.div>
            <div className="min-w-0">
              <h3 className="text-xl font-semibold">Objectif Trimestriel</h3>
              <p className="text-purple-200 truncate">
                {remainingDays} jours restants
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <p className="text-purple-200 mb-2">Objectif à atteindre</p>
            <p className="text-3xl font-bold">{quarterlyGoal.salesTarget} ventes</p>
            <div className="mt-4 p-3 bg-white/10 backdrop-blur-sm rounded-lg border border-white/20">
              <div className="flex items-center gap-2 mb-2">
                <Gift className="w-5 h-5 text-yellow-400" />
                <h4 className="font-semibold text-yellow-300">BONUS</h4>
              </div>
              <p className="text-white/90 font-medium">
                {quarterlyGoal.bonusDescription || 'Accès au BONUS après objectif atteint'}
              </p>
            </div>
          </div>

          <div>
            <p className="text-purple-200 mb-2">Ventes actuelles</p>
            <p className="text-3xl font-bold">{quarterSales} ventes</p>
            <div className="flex items-center gap-2 mt-2">
              <TrendingUp className="w-5 h-5 text-purple-300" />
              <p className="text-purple-200">
                {progressPercentage.toFixed(1)}% de l'objectif
              </p>
            </div>
          </div>
        </div>

        {/* Progress info */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between text-sm text-purple-200 mb-3">
          <p>Progression vers le BONUS</p>
          <p className="font-medium">{progressPercentage.toFixed(1)}%</p>
        </div>

        {/* Progress bar */}
        <div className="h-3 bg-white/10 rounded-full overflow-hidden">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progressPercentage}%` }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="h-full bg-gradient-to-r from-blue-500 to-purple-500 rounded-full shadow-lg"
          />
        </div>

        {/* Lead Cost Warning */}
        {!hasReachedGoal && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-6 p-4 bg-red-500/20 border border-red-500/30 rounded-xl"
          >
            <div className="flex items-center gap-2 mb-2">
              <AlertTriangle className="w-5 h-5 text-red-300" />
              <h4 className="font-semibold text-red-300">Participation aux coûts des leads</h4>
            </div>
            <p className="text-red-200">
              Si l'objectif n'est pas atteint, vous devrez participer à 50% du coût des leads ({quarterlyGoal.leadCost}€/lead sur {quarterlyGoal.salesTarget * leadsPerSale} leads).
              <br />
              Coût total estimé : {potentialCost.toFixed(2)}€
            </p>
          </motion.div>
        )}

        {/* Bonus Unlocked Message */}
        {hasReachedGoal && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-4 bg-green-500/20 border border-green-500/30 rounded-xl text-center"
          >
            <h4 className="text-lg font-bold text-green-300">🎉 Félicitations ! BONUS débloqué !</h4>
            <p className="text-green-200">Vous avez dépassé votre objectif trimestriel</p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
}