import React, { useState } from 'react';
import { Settings, X } from 'lucide-react';
import { useToast } from '../../contexts/ToastContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface LandingPageSettingsProps {
  onClose: () => void;
}

export default function LandingPageSettings({ onClose }: LandingPageSettingsProps) {
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    seo: {
      titleTemplate: '{{title}} | Palm Dubai Immo',
      descriptionTemplate: 'Découvrez {{title}}, un projet immobilier exceptionnel à {{area}}. Investissez dans l\'immobilier de luxe à Dubai avec Palm Dubai Immo.',
      keywords: 'dubai, immobilier, investissement, luxe, appartement, villa'
    },
    openai: {
      enabled: false,
      apiKey: '',
      model: 'gpt-4'
    },
    analytics: {
      googleAnalytics: '',
      facebookPixel: ''
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Ensure we're updating the correct document
      const settingsRef = doc(db, 'settings', 'general');
      await updateDoc(settingsRef, {
        landingPages: {
          ...formData,
          updatedAt: new Date().toISOString()
        }
      });
      showToast('success', 'Paramètres enregistrés avec succès');
      onClose();
    } catch (error) {
      console.error('Error saving settings:', error);
      showToast('error', 'Erreur lors de l\'enregistrement des paramètres');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Settings className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Paramètres des Landing Pages</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* SEO Settings */}
          <div>
            <h3 className="text-lg font-medium mb-4">SEO</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Template du titre
                </label>
                <input
                  type="text"
                  value={formData.seo.titleTemplate}
                  onChange={(e) => setFormData({
                    ...formData,
                    seo: { ...formData.seo, titleTemplate: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Template de la description
                </label>
                <textarea
                  value={formData.seo.descriptionTemplate}
                  onChange={(e) => setFormData({
                    ...formData,
                    seo: { ...formData.seo, descriptionTemplate: e.target.value }
                  })}
                  rows={3}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Mots-clés par défaut
                </label>
                <input
                  type="text"
                  value={formData.seo.keywords}
                  onChange={(e) => setFormData({
                    ...formData,
                    seo: { ...formData.seo, keywords: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* OpenAI Settings */}
          <div>
            <h3 className="text-lg font-medium mb-4">OpenAI</h3>
            <div className="space-y-4">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={formData.openai.enabled}
                  onChange={(e) => setFormData({
                    ...formData,
                    openai: { ...formData.openai, enabled: e.target.checked }
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm">Activer la traduction automatique</span>
              </label>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Clé API OpenAI
                </label>
                <input
                  type="password"
                  value={formData.openai.apiKey}
                  onChange={(e) => setFormData({
                    ...formData,
                    openai: { ...formData.openai, apiKey: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="sk-..."
                />
                <p className="mt-1 text-xs text-gray-500">
                  Votre clé API OpenAI commençant par "sk-"
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Modèle
                </label>
                <select
                  value={formData.openai.model}
                  onChange={(e) => setFormData({
                    ...formData,
                    openai: { ...formData.openai, model: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="gpt-4">GPT-4 (Recommandé)</option>
                  <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                </select>
              </div>
            </div>
          </div>

          {/* Analytics Settings */}
          <div>
            <h3 className="text-lg font-medium mb-4">Analytics</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  ID Google Analytics
                </label>
                <input
                  type="text"
                  value={formData.analytics.googleAnalytics}
                  onChange={(e) => setFormData({
                    ...formData,
                    analytics: { ...formData.analytics, googleAnalytics: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="G-XXXXXXXXXX"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  ID Facebook Pixel
                </label>
                <input
                  type="text"
                  value={formData.analytics.facebookPixel}
                  onChange={(e) => setFormData({
                    ...formData,
                    analytics: { ...formData.analytics, facebookPixel: e.target.value }
                  })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="XXXXXXXXXX"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}