import React from 'react';
import { Calendar, Plus, Trash2, Calculator, ImageIcon } from 'lucide-react';
import { formatCurrency } from '../../utils/format';

interface PaymentPlanSectionProps {
  formData: {
    propertyPrice: number;
    discount?: number;
    propertyType?: string;
    paymentPlanType?: 'manual' | 'image' | 'installments';
    paymentPlanImage?: string;
    paymentPlan: Array<{
      month: number;
      year: number;
      percentage: number;
      amount?: number;
    }>;
  };
  onChange: (data: Partial<PaymentPlanSectionProps['formData']>) => void;
}

export default function PaymentPlanSection({ formData, onChange }: PaymentPlanSectionProps) {
  const calculateAmount = (percentage: number) => {
    const discountedPrice = formData.discount 
      ? formData.propertyPrice * (1 - formData.discount / 100)
      : formData.propertyPrice;
    return (discountedPrice * percentage) / 100;
  };

  const addInstallment = () => {
    const newInstallment = {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      percentage: 0,
      amount: 0
    };
    
    onChange({
      paymentPlan: [...formData.paymentPlan, newInstallment]
    });
  };

  const removeInstallment = (index: number) => {
    onChange({
      paymentPlan: formData.paymentPlan.filter((_, i) => i !== index)
    });
  };

  const updateInstallment = (index: number, updates: Partial<typeof formData.paymentPlan[0]>) => {
    const newPlan = [...formData.paymentPlan];
    newPlan[index] = {
      ...newPlan[index],
      ...updates,
      amount: calculateAmount(updates.percentage || newPlan[index].percentage)
    };
    onChange({ paymentPlan: newPlan });
  };

  const totalPercentage = formData.paymentPlan.reduce((sum, inst) => sum + inst.percentage, 0);
  const remaining = 100 - totalPercentage;

  return (
    <div className="space-y-6">
      {/* Property Price */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Prix du bien
        </label>
        <div className="relative">
          <input
            type="number"
            min="0"
            value={formData.propertyPrice || ''}
            onChange={(e) => onChange({ propertyPrice: Number(e.target.value) })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Prix du bien"
          />
        </div>
      </div>

      {/* Property Type Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Type de bien
        </label>
        <select
          value={formData.propertyType || ''}
          onChange={(e) => onChange({ propertyType: e.target.value })}
          className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
        >
          <option value="">Sélectionner un type</option>
          <option value="studio">Studio</option>
          <option value="1br">1 Chambre</option>
          <option value="2br">2 Chambres</option>
          <option value="3br">3 Chambres</option>
          <option value="4br">4 Chambres</option>
          <option value="5br">5 Chambres</option>
          <option value="penthouse">Penthouse</option>
          <option value="villa">Villa</option>
          <option value="manual">Manuel</option>
        </select>
        {formData.propertyType === 'manual' && (
          <input
            type="text"
            value={formData.manualPropertyType || ''}
            onChange={(e) => onChange({ manualPropertyType: e.target.value })}
            className="mt-2 w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Type de bien personnalisé"
          />
        )}
      </div>

      {/* Discount */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Remise (%)
        </label>
        <input
          type="number"
          min="0"
          max="100"
          value={formData.discount || ''}
          onChange={(e) => {
            const discount = Number(e.target.value);
            onChange({ 
              discount,
              paymentPlan: formData.paymentPlan.map(inst => ({
                ...inst,
                amount: calculateAmount(inst.percentage)
              }))
            });
          }}
          className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="0"
        />
      </div>

      {/* Price Summary */}
      <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
        <div className="flex items-center gap-2 mb-3">
          <Calculator className="w-5 h-5 text-blue-600" />
          <h3 className="font-medium">Résumé des prix</h3>
        </div>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span>Prix initial</span>
            <span className="font-medium">{formatCurrency(formData.propertyPrice)}</span>
          </div>
          {formData.discount && (
            <>
              <div className="flex justify-between text-red-600">
                <span>Remise ({formData.discount}%)</span>
                <span>-{formatCurrency(formData.propertyPrice * (formData.discount / 100))}</span>
              </div>
              <div className="flex justify-between font-bold pt-2 border-t border-blue-200/50">
                <span>Prix final</span>
                <span>{formatCurrency(formData.propertyPrice * (1 - formData.discount / 100))}</span>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Payment Plan */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Type de plan de paiement
        </label>
        <div className="grid grid-cols-3 gap-4 mb-6">
          <button
            type="button"
            onClick={() => onChange({ paymentPlanType: 'installments' })}
            className={`p-4 rounded-lg border text-center transition-colors ${
              formData.paymentPlanType === 'installments' || !formData.paymentPlanType
                ? 'bg-blue-50 border-blue-200 text-blue-700'
                : 'border-gray-200 hover:bg-gray-50'
            }`}
          >
            <Calendar className="w-5 h-5 mx-auto mb-2" />
            <span className="text-sm font-medium">Versements</span>
          </button>
          
          <button
            type="button"
            onClick={() => onChange({ paymentPlanType: 'manual' })}
            className={`p-4 rounded-lg border text-center transition-colors ${
              formData.paymentPlanType === 'manual'
                ? 'bg-blue-50 border-blue-200 text-blue-700'
                : 'border-gray-200 hover:bg-gray-50'
            }`}
          >
            <Calculator className="w-5 h-5 mx-auto mb-2" />
            <span className="text-sm font-medium">Manuel</span>
          </button>
          
          <button
            type="button"
            onClick={() => onChange({ paymentPlanType: 'image' })}
            className={`p-4 rounded-lg border text-center transition-colors ${
              formData.paymentPlanType === 'image'
                ? 'bg-blue-50 border-blue-200 text-blue-700'
                : 'border-gray-200 hover:bg-gray-50'
            }`}
          >
            <ImageIcon className="w-5 h-5 mx-auto mb-2" />
            <span className="text-sm font-medium">Image</span>
          </button>
        </div>
      </div>

      {/* Image Upload */}
      {formData.paymentPlanType === 'image' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Image du plan de paiement
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors">
            <div className="space-y-1 text-center">
              <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                  <span>Télécharger une image</span>
                  <input
                    type="file"
                    accept="image/*"
                    className="sr-only"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          onChange({ paymentPlanImage: reader.result as string });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG jusqu'à 10MB</p>
            </div>
          </div>
          {formData.paymentPlanImage && (
            <div className="mt-4">
              <img
                src={formData.paymentPlanImage}
                alt="Plan de paiement"
                className="max-h-96 rounded-lg mx-auto"
              />
            </div>
          )}
        </div>
      )}

      <div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Plan de paiement</h3>
          </div>
          {formData.paymentPlanType !== 'image' && (
            <div className="text-sm">
              <span className={remaining === 0 ? 'text-green-600' : 'text-amber-600'}>
                {remaining}% restant
              </span>
            </div>
          )}
        </div>

        {formData.paymentPlanType === 'installments' && (
          <div className="space-y-4">
          {formData.paymentPlan.map((installment, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex-1">
                <select
                  value={installment.month}
                  onChange={(e) => updateInstallment(index, { month: Number(e.target.value) })}
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {new Date(2000, i).toLocaleString('fr-FR', { month: 'long' })}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex-1">
                <input
                  type="number"
                  value={installment.year}
                  onChange={(e) => updateInstallment(index, { year: Number(e.target.value) })}
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  min={new Date().getFullYear()}
                  max={new Date().getFullYear() + 10}
                />
              </div>

              <div className="flex-1">
                <div className="relative">
                  <input
                    type="number"
                    value={installment.percentage}
                    onChange={(e) => updateInstallment(index, { percentage: Number(e.target.value) })}
                    className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    min="0"
                    max={100 - (totalPercentage - installment.percentage)}
                    step="0.1"
                  />
                  <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">%</span>
                </div>
              </div>

              <div className="flex-1">
                <input
                  type="text"
                  value={formatCurrency(installment.amount || 0)}
                  readOnly
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-lg bg-gray-50"
                />
              </div>

              <button
                type="button"
                onClick={() => removeInstallment(index)}
                className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          ))}

          <button
            type="button"
            onClick={addInstallment}
            className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors flex items-center justify-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Ajouter un versement
          </button>
        </div>
        )}

        {formData.paymentPlanType === 'manual' && (
          <div className="space-y-4">
            <textarea
              value={formData.manualPaymentPlan || ''}
              onChange={(e) => onChange({ manualPaymentPlan: e.target.value })}
              rows={10}
              className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Entrez le plan de paiement manuellement..."
            />
          </div>
        )}
      </div>
    </div>
  );
}