import React, { useState } from 'react';
import { Building, Search, DollarSign, MapPin } from 'lucide-react';
import { useProperties } from '../../hooks/useProperties';
import { useAreas } from '../../hooks/useAreas';
import { useDevelopers } from '../../hooks/useDevelopers';
import { motion } from 'framer-motion';
import { formatCurrency } from '../../utils/format';
import type { Property } from '../../types';

interface PropertySelectorProps {
  onSelect: (property: Property) => void;
  onClose: () => void;
  selectedIds?: string[];
  setFormData?: (data: any) => void;
}

export default function PropertySelector({ onSelect, onClose, selectedIds = [], setFormData }: PropertySelectorProps) {
  const { properties, loading } = useProperties();
  const { areas } = useAreas();
  const { developers } = useDevelopers();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredProperties = properties.filter(property => {
    if (selectedIds.includes(property.id)) return false;
    
    if (!searchQuery) return true;
    
    const query = searchQuery.toLowerCase();
    return (
      property.name.toLowerCase().includes(query) ||
      property.developer.toLowerCase().includes(query) ||
      property.area.toLowerCase().includes(query)
    );
  });

  const handleSelect = (property: Property) => {
    // Trouver le promoteur et le quartier correspondants
    if (setFormData) {
      const developer = developers.find(d => d.name === property.developer);
      const area = areas.find(a => a.name === property.area);

      // Ajouter les informations du promoteur et du quartier aux données du formulaire
      if (developer) {
        setFormData(prev => ({
          ...prev,
          developer: developer.name,
          developerDescription: developer.description,
          developerLogo: developer.logo,
          developerProjects: developer.projects
        }));
      }

      if (area) {
        setFormData(prev => ({
          ...prev,
          area: area.name,
          areaDescription: area.description,
          areaDistances: area.distances,
          areaPointsOfInterest: area.pointsOfInterest
        }));
      }
    }

    onSelect(property);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl p-6 max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Building className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Sélectionner un bien</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            ×
          </button>
        </div>

        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher un bien..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="space-y-4">
            {loading ? (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                <p className="mt-4 text-gray-500">Chargement des biens...</p>
              </div>
            ) : filteredProperties.length === 0 ? (
              <div className="text-center py-12">
                <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-500">Aucun bien trouvé</p>
              </div>
            ) : (
              filteredProperties.map((property) => (
                <motion.button
                  key={property.id}
                  onClick={() => handleSelect(property)}
                  className="w-full p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50 hover:border-blue-200/50 hover:from-blue-50/50 hover:to-blue-100/20 transition-all duration-200 text-left"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <h3 className="font-medium">{property.name}</h3>
                  <p className="text-sm text-gray-500 mt-1">{property.shortDescription}</p>
                  <div className="flex items-center gap-4 mt-2">
                    <div className="flex items-center gap-1 text-sm text-gray-500">
                      <Building className="w-4 h-4" />
                      <span>{property.developer}</span>
                    </div>
                    <div className="flex items-center gap-1 text-sm text-gray-500">
                      <MapPin className="w-4 h-4" />
                      <span>{property.area}</span>
                    </div>
                    <div className="flex items-center gap-1 text-sm text-gray-500">
                      <DollarSign className="w-4 h-4" />
                      <span>{formatCurrency(property.price)}</span>
                    </div>
                  </div>
                </motion.button>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}