import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Settings } from '../types';

const SETTINGS_DOC_ID = 'general';

const defaultSettings: Settings = {
  name: 'PalmCRM',
  email: 'contact@palmdubaiimmo.com',
  notifications: {
    newSale: true,
    commissionReceived: true,
    agentCreated: true,
    loginAttempt: true
  },
  emailjs: {
    serviceId: 'service_sqg5icr',
    templateId: 'template_sale_status',
    publicKey: 'Dk34hxmb9NM4X2lte'
  }
};

export function useSettings() {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsRef = doc(db, 'settings', SETTINGS_DOC_ID);
        const settingsDoc = await getDoc(settingsRef);
        
        if (!settingsDoc.exists()) {
          // Initialize with default settings
          await setDoc(settingsRef, {
            ...defaultSettings,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          });
          setSettings(defaultSettings);
          document.title = defaultSettings.name;
        } else {
          const settingsData = settingsDoc.data() as Settings;
          setSettings(settingsData);
          if (settingsData.name) {
            document.title = settingsData.name;
          }
        }
      } catch (err) {
        console.error('Error fetching settings:', err);
        setError('Failed to fetch settings');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const updateSettings = async (updates: Partial<Settings>) => {
    try {
      const settingsRef = doc(db, 'settings', SETTINGS_DOC_ID);

      // Update document with merge option to preserve existing fields
      await setDoc(settingsRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      }, { merge: true });
      
      // Update local state
      setSettings(prev => {
        const newSettings = prev ? { ...prev, ...updates } : updates as Settings;
        if (updates.name) {
          document.title = updates.name;
        }
        return newSettings;
      });

      return true;
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  };

  return {
    settings,
    loading,
    error,
    updateSettings
  };
}