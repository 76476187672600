import React from 'react';
import { Gift, Users, Building, DollarSign } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';
import type { Sale } from '../../types';

interface CommissionBreakdownProps {
  sale: Sale;
  commission: {
    agent: number;
    agency: number;
    referrer?: number;
    bonus: {
      agent: number;
      agency: number;
      referrer: number;
    };
  };
}

export default function CommissionBreakdown({ sale, commission }: CommissionBreakdownProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-indigo-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute bottom-0 left-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative p-8">
        {/* Header */}
        <div className="flex items-center gap-3 mb-8">
          <div className="p-3 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
            <DollarSign className="w-6 h-6 text-white" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white">Répartition des commissions</h3>
            <p className="text-gray-400 mt-1">Commission totale : <BlurredNumber value={formatCurrency(sale.developerCommission)} /></p>
          </div>
        </div>

        {/* Commission Grid */}
        <div className={`grid grid-cols-1 ${commission.referrer ? 'md:grid-cols-3' : 'md:grid-cols-2'} gap-6 mb-6`}>
          {/* Agent Commission */}
          <div className="p-6 bg-gradient-to-br from-blue-500/10 to-blue-600/5 rounded-xl border border-blue-500/20 backdrop-blur-sm">
            <div className="flex items-center gap-3 mb-4">
              <Users className="w-5 h-5 text-blue-400" />
              <h4 className="font-medium text-white">Commission agent</h4>
            </div>
            <p className="text-3xl font-bold text-white mb-2">
              <BlurredNumber value={formatCurrency(commission.agent)} />
            </p>
            <p className="text-sm text-blue-300">
              <BlurredNumber value={formatEuro(commission.agent)} />
            </p>
            {commission.bonus.agent > 0 && (
              <div className="mt-3 flex items-center gap-2 text-purple-300 bg-purple-500/10 px-3 py-2 rounded-lg">
                <Gift className="w-4 h-4" />
                <span className="text-sm">
                  +<BlurredNumber value={formatCurrency(commission.bonus.agent)} /> bonus
                </span>
              </div>
            )}
            <div className={`mt-3 flex items-center gap-2 px-3 py-2 rounded-lg ${
              sale.paymentStatus.agent
                ? 'bg-green-500/10 text-green-300'
                : 'bg-amber-500/10 text-amber-300'
            }`}>
              <div className={`w-2 h-2 rounded-full ${
                sale.paymentStatus.agent ? 'bg-green-400' : 'bg-amber-400'
              }`} />
              <span className="text-sm">
                {sale.paymentStatus.agent ? 'Payée' : 'En attente'}
              </span>
            </div>
          </div>

          {/* Referrer Commission */}
          {commission.referrer > 0 && (
            <div className="p-6 bg-gradient-to-br from-amber-500/10 to-amber-600/5 rounded-xl border border-amber-500/20 backdrop-blur-sm">
              <div className="flex items-center gap-3 mb-4">
                <Users className="w-5 h-5 text-amber-400" />
                <h4 className="font-medium text-white">Commission parrain</h4>
              </div>
              <p className="text-3xl font-bold text-white mb-2">
                <BlurredNumber value={formatCurrency(commission.referrer)} />
              </p>
              <p className="text-sm text-amber-300">
                <BlurredNumber value={formatEuro(commission.referrer)} />
              </p>
              {commission.bonus.referrer > 0 && (
                <div className="mt-3 flex items-center gap-2 text-purple-300 bg-purple-500/10 px-3 py-2 rounded-lg">
                  <Gift className="w-4 h-4" />
                  <span className="text-sm">
                    +<BlurredNumber value={formatCurrency(commission.bonus.referrer)} /> bonus
                  </span>
                </div>
              )}
              <div className={`mt-3 flex items-center gap-2 px-3 py-2 rounded-lg ${
                sale.paymentStatus.referrer
                  ? 'bg-green-500/10 text-green-300'
                  : 'bg-amber-500/10 text-amber-300'
              }`}>
                <div className={`w-2 h-2 rounded-full ${
                  sale.paymentStatus.referrer ? 'bg-green-400' : 'bg-amber-400'
                }`} />
                <span className="text-sm">
                  {sale.paymentStatus.referrer ? 'Payée' : 'En attente'}
                </span>
              </div>
            </div>
          )}

          {/* Agency Commission */}
          <div className="p-6 bg-gradient-to-br from-purple-500/10 to-purple-600/5 rounded-xl border border-purple-500/20 backdrop-blur-sm">
            <div className="flex items-center gap-3 mb-4">
              <Building className="w-5 h-5 text-purple-400" />
              <h4 className="font-medium text-white">Commission agence</h4>
            </div>
            <p className="text-3xl font-bold text-white mb-2">
              <BlurredNumber value={formatCurrency(commission.agency)} />
            </p>
            <p className="text-sm text-purple-300">
              <BlurredNumber value={formatEuro(commission.agency)} />
            </p>
            {commission.bonus.agency > 0 && (
              <div className="mt-3 flex items-center gap-2 text-purple-300 bg-purple-500/10 px-3 py-2 rounded-lg">
                <Gift className="w-4 h-4" />
                <span className="text-sm">
                  +<BlurredNumber value={formatCurrency(commission.bonus.agency)} /> bonus
                </span>
              </div>
            )}
            <div className={`mt-3 flex items-center gap-2 px-3 py-2 rounded-lg ${
              sale.paymentStatus.developer
                ? 'bg-green-500/10 text-green-300'
                : 'bg-amber-500/10 text-amber-300'
            }`}>
              <div className={`w-2 h-2 rounded-full ${
                sale.paymentStatus.developer ? 'bg-green-400' : 'bg-amber-400'
              }`} />
              <span className="text-sm">
                {sale.paymentStatus.developer ? 'Reçue' : 'En attente'}
              </span>
            </div>
          </div>
        </div>

        {/* Bonus Info */}
        {(commission.bonus.agent > 0 || commission.bonus.referrer > 0 || commission.bonus.agency > 0) && (
          <div className="p-4 bg-gradient-to-br from-purple-500/10 to-purple-600/5 rounded-xl border border-purple-500/20 backdrop-blur-sm">
            <div className="flex items-center gap-2 mb-2">
              <Gift className="w-5 h-5 text-purple-400" />
              <h4 className="font-medium text-white">Bonus total</h4>
            </div>
            <p className="text-2xl font-bold text-white">
              <BlurredNumber value={formatCurrency(
                commission.bonus.agent + 
                commission.bonus.referrer + 
                commission.bonus.agency
              )} />
            </p>
            <div className={`mt-3 flex items-center gap-2 px-3 py-2 rounded-lg ${
              sale.paymentStatus.bonus
                ? 'bg-green-500/10 text-green-300'
                : 'bg-amber-500/10 text-amber-300'
            }`}>
              <div className={`w-2 h-2 rounded-full ${
                sale.paymentStatus.bonus ? 'bg-green-400' : 'bg-amber-400'
              }`} />
              <span className="text-sm">
                {sale.paymentStatus.bonus ? 'Payé' : 'En attente'}
              </span>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}