import React from 'react';
import { ImageIcon, Webhook } from 'lucide-react';

interface InstagramTabProps {
  formData: {
    apis: {
      zapier?: {
        webhookUrl: string;
        defaultHashtags?: string;
      };
    };
  };
  setFormData: (data: any) => void;
}

export default function InstagramTab({ formData, setFormData }: InstagramTabProps) {
  return (
    <div className="space-y-6">
      <div className="p-4 bg-gradient-to-br from-pink-50 to-purple-100/50 rounded-xl border border-pink-200/50">
        <div className="flex items-center gap-3 mb-4">
          <ImageIcon className="w-5 h-5 text-pink-600" />
          <h3 className="font-medium">Configuration Instagram via Zapier</h3>
        </div>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              URL du Webhook Zapier
            </label>
            <input
              type="text"
              value={formData.apis.zapier?.webhookUrl || ''}
              onChange={(e) => setFormData({
                ...formData,
                apis: {
                  ...formData.apis,
                  zapier: {
                    ...formData.apis.zapier,
                    webhookUrl: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              placeholder="https://hooks.zapier.com/hooks/catch/..."
            />
            <p className="mt-1 text-sm text-gray-500">
              URL du webhook Zapier pour la publication Instagram
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Hashtags par défaut
            </label>
            <input
              type="text"
              value={formData.apis.zapier?.defaultHashtags || '#PalmDubai #Dubai #RealEstate'}
              onChange={(e) => setFormData({
                ...formData,
                apis: {
                  ...formData.apis,
                  zapier: {
                    ...formData.apis.zapier,
                    defaultHashtags: e.target.value
                  }
                }
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              placeholder="#PalmDubai #Dubai #RealEstate"
            />
            <p className="mt-1 text-sm text-gray-500">
              Ces hashtags seront automatiquement ajoutés à chaque publication
            </p>
          </div>

          <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-lg border border-blue-200/50 mt-6">
            <div className="flex items-center gap-2">
              <Webhook className="w-4 h-4 text-blue-600" />
              <p className="text-sm text-blue-900">
                Les publications seront envoyées à Zapier qui les transmettra à Instagram
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}