import React, { useState } from 'react';
import { Building, Plus } from 'lucide-react';
import type { Settings } from '../../types';
import { useProperties } from '../../hooks/useProperties';
import DeveloperForm from '../property/DeveloperForm';
import AreaForm from '../property/AreaForm';
import PropertyForm from '../property/PropertyForm';

interface PropertiesTabProps {
  settings: Settings | null;
  updateSettings: (settings: Partial<Settings>) => Promise<void>;
  showToast: (type: 'success' | 'error', message: string) => void;
}

export default function PropertiesTab({ settings, updateSettings, showToast }: PropertiesTabProps) {
  const [showDeveloperForm, setShowDeveloperForm] = useState(false);
  const [showAreaForm, setShowAreaForm] = useState(false);
  const [showPropertyForm, setShowPropertyForm] = useState(false);
  const { addProperty, loading, error } = useProperties();

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <button
          onClick={() => setShowPropertyForm(true)}
          className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50 hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3 mb-2">
            <Building className="w-5 h-5 text-blue-600" />
            <h3 className="font-medium">Ajouter un bien</h3>
          </div>
          <p className="text-sm text-gray-600">
            Créer une nouvelle propriété
          </p>
        </button>

        <button
          onClick={() => setShowDeveloperForm(true)}
          className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50 hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3 mb-2">
            <Building className="w-5 h-5 text-purple-600" />
            <h3 className="font-medium">Ajouter un promoteur</h3>
          </div>
          <p className="text-sm text-gray-600">
            Créer un nouveau promoteur
          </p>
        </button>

        <button
          onClick={() => setShowAreaForm(true)}
          className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50 hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3 mb-2">
            <Building className="w-5 h-5 text-green-600" />
            <h3 className="font-medium">Ajouter un quartier</h3>
          </div>
          <p className="text-sm text-gray-600">
            Créer un nouveau quartier
          </p>
        </button>
      </div>

      {showPropertyForm && (
        <PropertyForm
          onSubmit={async (property) => {
            try {
              await addProperty(property);
              showToast('success', 'Bien ajouté avec succès');
              setShowPropertyForm(false);
            } catch (error) {
              showToast('error', 'Erreur lors de l\'ajout du bien');
            }
          }}
          onClose={() => setShowPropertyForm(false)}
          developers={settings?.developers || []}
          areas={settings?.areas || []}
        />
      )}

      {showDeveloperForm && (
        <DeveloperForm
          onSubmit={async (developer) => {
            try {
              // Add developer logic here
              showToast('success', 'Promoteur ajouté avec succès');
              setShowDeveloperForm(false);
            } catch (error) {
              showToast('error', 'Erreur lors de l\'ajout du promoteur');
            }
          }}
          onClose={() => setShowDeveloperForm(false)}
        />
      )}

      {showAreaForm && (
        <AreaForm
          onSubmit={async (area) => {
            try {
              // Add area logic here
              showToast('success', 'Quartier ajouté avec succès');
              setShowAreaForm(false);
            } catch (error) {
              showToast('error', 'Erreur lors de l\'ajout du quartier');
            }
          }}
          onClose={() => setShowAreaForm(false)}
        />
      )}
    </div>
  );
}