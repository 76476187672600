import React from 'react';
import { motion } from 'framer-motion';
import { Building, DollarSign, Shield, TrendingUp, MapPin, Phone, Mail, Globe, Gift, Video, Users, ChevronRight, PalmtreeIcon, Star, Briefcase, GraduationCap, FileText, Check, Laptop, MessageSquare, Image, Newspaper } from 'lucide-react';
import StoryPreview from '../components/marketing/StoryPreview';
import AdGenerator from '../components/marketing/AdGenerator';
import NewsStoryGenerator from '../components/marketing/NewsStoryGenerator';
import FacebookPostPreview from '../components/marketing/FacebookPostPreview';

export default function JoinPalm() {
  const [activeTab, setActiveTab] = React.useState('ads');

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
          <div className="flex items-center gap-4 mb-6">
            <Newspaper className="w-6 h-6 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-800">Marketing Tools</h1>
          </div>

          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setActiveTab('ads')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'ads' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Ad Generator
            </button>
            <button
              onClick={() => setActiveTab('news')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'news' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              News Story
            </button>
            <button
              onClick={() => setActiveTab('story')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'story' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Story Preview
            </button>
            <button
              onClick={() => setActiveTab('facebook')}
              className={`px-4 py-2 rounded-lg ${activeTab === 'facebook' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Facebook Post
            </button>
          </div>

          {/* Content Container */}
          <div className="flex justify-center">
            {activeTab === 'ads' && <AdGenerator />}
            {activeTab === 'news' && <NewsStoryGenerator />}
            {activeTab === 'story' && (
              <div className="w-[360px]">
                <StoryPreview mode="recruitment" />
              </div>
            )}
            {activeTab === 'facebook' && <FacebookPostPreview />}
          </div>
        </div>
      </div>
    </div>
  );
}